/*
NAME: ErrorCard
DESC: Componente responsavel por montar um card de erro
props:
- title: titulo do card
-message: mensagem de erro a ser exibida
-page: Pagina para onde o usuario será redirecionado
- - - - Default

NOTES:

*/
import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import BasicCard from "./BasicCard";
import { Stack } from '@mui/material';
import MessageShow from './MessageShow'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ErrorCard({title, message, page}) {

    const history = useHistory();

   return (    
    <Stack spacing={2} style={{minWidth: 500, height: 500, display: 'flex', justifyContent: 'center'}}>
        <BasicCard>
            <MessageShow title={title} message={message} type='error'/>
        </BasicCard>        
        <Button size="large" style={{alignSelf: 'center', }} onClick={()=>{history.push(`/${page}`)}} startIcon={<ArrowBackIcon/>}> Voltar </Button>  
    </Stack>    
   );
}

export default ErrorCard;



/*
style={{flex:1,whiteSpace: 'nowrap', width:500,}} //list

projectTable
<Stack spacing={3} style={{...props.style}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>


*/