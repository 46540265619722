import { Alert } from "@mui/material";
import APIConstants from "../connection/APIConstants";
import APIManager from "../connection/APIManager";
import CacheLoadCredentials from "./CacheLoadCredentials";

/*
USE CASE: AuthCheckLogin
Desc: It checks on the API if this (USER / TOKEN) are valid and authorized 
params: NONE
returns:
    - A promise that resolves if the userData stored in cache exists, 
    and its values are valid and authorized. If not it will be rejected
*/

const AuthCheckLogin = async () => {
    try {
        const credentials = await CacheLoadCredentials();
        console.log('Credenciais carregadas', credentials);
        if (!credentials) {
            throw new Error('Credenciais não encontradas');
        }
        const headers = {
            [APIConstants.header_auth_login]: credentials.email,
            [APIConstants.header_auth_token]: credentials.token,
        };
        const body = {
            email: credentials.email,
            token: credentials.token,
            tipo: credentials.tipo,
        };
        const response = await APIManager.send("/auth", "POST", headers, body);
        if (!response.ok) {
            return Alert('Usuario não autorizado')
            // throw new Error('Erro na autenticação');
        }
        const json = await response.json();
        console.log('Usuário autenticado com sucesso', json);
        return json;
    } catch (error) {
        console.log('Erro ao tentar autenticar usuário', error);
        throw error;
    }
};

export default AuthCheckLogin;