import React, { useState } from 'react';
import { Stack, IconButton, Popover, Box } from '@mui/material';
import { useJsApiLoader, GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import values from '../../values/values';
import SettingsIcon from '@mui/icons-material/Settings';
import MapConfigPanel from '../specific/MapConfigPanel';
import IconFinal from '../../assets/images/mk_fim.png';
import IconInicial from '../../assets/images/mk_inicio.png';
import IconTrator from '../../assets/images/mk_tractor.png';
import IconTratorRed from '../../assets/images/mk_tractor_red.png'

const MapPanel = (props) => {
  const [lineColor, setLineColor] = useState("#19c119");
  const [strokeWeight, setStrokeWeight] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleColorSelect = (color) => {
    setLineColor(color);
    setAnchorEl(null);
  };

  const handleSliderChange = (newValue) => {
    setStrokeWeight(newValue);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openMenu = Boolean(anchorEl);

  const renderLines = () => {
    if (props.linhas && props.linhas.length) {
      return props.linhas.map((linha, index) => {
        return (
          <Polyline
            key={index}
            path={linha.path}
            options={{
              strokeColor: linha.color ? linha.color : lineColor,
              strokeOpacity: 1.0,
              strokeWeight: strokeWeight,
            }}
          />
        );
      });
    } else {
      return null;
    }
  };


  const renderMarkers = () => {
    if (props.markers && props.markers.length) {
      return props.markers.map((item, i) => {
        let icon = IconTrator;

        if (item.status) {
          if (item.status !== "INICIADO") {
            icon = IconTratorRed;
          } else {
            icon = IconTrator;
          }
        }

        if (item.coordinateType === 'last') {
          icon = IconFinal;
        } else if (item.coordinateType === 'first') {
          icon = IconInicial;
        }

        return (
          <Marker
            key={i}
            position={{
              lat: item.latitude,
              lng: item.longitude,
            }}
            icon={{
              url: icon,
              scaledSize: new window.google.maps.Size(35, 45)
            }}
          />
        );
      });
    } else {
      return null;
    }
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC6uetza23vFsq_B3yKwAlb4DqSNKPsLDI',
    language: 'pt-BR',
  });

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  return (
    <Stack spacing={values.spacing[0]}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            height: props.height ? props.height : 300,
            width: props.width ? props.width : '100%',
          }}
          zoom={props.zoomMap}
          center={props.centerMap}
          mapTypeId="satellite"
        >
          <div style={{ position: 'absolute', top: '60px', right: '15px' }}>
            <IconButton onClick={handleMenuOpen} size="small">
              <SettingsIcon />
            </IconButton>

          </div>
          {renderLines()}
          {renderMarkers()}
        </GoogleMap>
      )}
      <Popover
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <MapConfigPanel
            lineColor={lineColor}
            onLineColorChange={handleColorSelect}
            strokeWeight={strokeWeight}
            onStrokeWeightChange={handleSliderChange}
          />
        </Box>
      </Popover>
    </Stack>
  );
};
export default MapPanel;