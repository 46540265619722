import React from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';
import User from '../components/pagescomponents/User';

function Users(props) {
    return (
        <DashboardFrame title={strings.getString('screen_users_title')}>
            <User />
        </DashboardFrame>
    );
}

export default Users;