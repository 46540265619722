import { Info, Lock, LockOpen, Email } from '@mui/icons-material';
import { Button, Drawer, Grid, Icon, IconButton, Paper, Typography, Stack } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import ProjectTable from '../components/basic/ProjectTable';
import colors from '../values/colors';
import InfoCard from '../components/basic/InfoCard';
import UserGetData from '../usecases/UserGetData';
import ProjectTable2 from '../components/basic/ProjectTable2';
import orderby from 'lodash/orderBy';
import MenuList2 from '../components/basic/MenuList2';
import values from '../values/values';
import RowContainer from '../components/basic/RowContainer';
import RowContainerItem from '../components/basic/RowContainerItem';
import ProjectChart from '../components/basic/ProjectChart';
import ChartPeriodDataBinder from '../usecases/ChartPeriodDataBinder';
import EquipmentsDashboardList from '../components/specific/EquipmentsDashboardList'
import AlertConfirm from '../usecases/AlertConfirm';

function TestComponentShow({title, onFilterChange, style}) {
    
    const [chartData, setchartData] = useState([])
    const [showdialog, setshowdialog] = useState(false)
    useEffect(() => {
        //getData()
        bindData(getData(), values.TIME_FILTERS[0], 10, )
 
    }, [])

    const getData = () => {
        let data = []

        data.push({data_cad_server: "2021-03-17T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-17T14:29:46.000Z",}) // CONTOU
        data.push({data_cad_server: "2021-03-16T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-15T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-14T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-14T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-13T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-13T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-12T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-12T14:29:46.000Z",})
        data.push({data_cad_server: "2019-03-10T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-07T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-06T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-05T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-04T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-03T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-02T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-01T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-12T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-13T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-14T14:29:46.000Z",})


        data.push({data_cad_server: "2021-03-17T14:29:46.000Z",}) // NÃO CONTOU
        data.push({data_cad_server: "2021-03-16T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-15T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-14T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-14T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-13T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-13T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-12T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-12T14:29:46.000Z",})
        data.push({data_cad_server: "2019-03-10T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-07T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-06T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-05T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-04T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-03T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-02T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-01T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-12T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-13T14:29:46.000Z",})
        data.push({data_cad_server: "2021-03-14T14:29:46.000Z",})

        return data;
    }

    const bindData = (data, filter, limit, ) => {
        ChartPeriodDataBinder("data_cad_server",data, filter, limit, true).then(res => {            
            setchartData(res)
        })
    }
    
   
    function teste() {
     showdialog ? setshowdialog(false) : setshowdialog(true)   
    }
    return (
        <DashboardFrame title="Component Show Case">
           <RowContainer>
            
        <Button variant="contained" color="primary" onClick= {teste}> ok </Button>

            <AlertConfirm 
            title={'Cancelar'} 
            text={'Deseja mesmo cancelar ?'} 
            show={showdialog} 
            onDismiss={() => setshowdialog(false)} 
            btncanceltext={"Cancelar"} 
            btnconfirmtext={"Confimar"}
            onCancel={() => console.log("Dialog cancelado")}  
            onConfirm={() => console.log("Dialog confirmado")}                    
          />

        
        </RowContainer>

        </DashboardFrame>
    );
}

export default TestComponentShow;
