/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/*
NAME: EquipamentList
DESC: Componente Responsavel por renderizar a lista de equipamentos
props:
- items: Array de objetos que representa a lista de equipamentos
- usuarioLogado: Tipo de ususario logado
- loading: Boolean que indica se a lista esta carregando.
- RowsPerPage: Quantidade de linhas por pagina da tabela que será exibida
-refresh: Função que vem de um componente pai para atualizar a lista de usuarios através de um get

- - - - Default
- style: Object, style object to overwrite this component style
- children: Component, renders component childs
NOTES:

*/

import React, { useEffect, useState } from "react";
import ProjectTable from "../basic/ProjectTable";
import DateFormat from "../../usecases/DateFormat";
import CircleIcon from '@mui/icons-material/Circle';
import { Button, IconButton } from '@mui/material';
import UserTypes from "../../models/UserTypes";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from "react-router-dom";
import Pages from "../../pages/Pages";
import strings from "../../values/strings/strings";
import EquipamentLock from "../../usecases/EquipamentLock";
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@mui/styles";
import Modal from '@mui/material/Modal';
import { Agriculture } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        border: '1px solid #000',
        padding: '20px',
        minWidth: '500px',
        minHeight: '400px',
        background: 'rgba(25, 25, 25, 1.5)',
    },
    modalTitle: {
        fontSize: '24px',
        textAlign: 'center',
        marginBottom: '20px',
    },
    button: {
        width: '100px',
        height: '30px',
        alignSelf: 'flex-end',
    },
}));

function EquipamentList({ items, usuarioLogado, loading, refresh, RowsPerPage, onViewMore }) {

    const [headers, setHeaders] = useState([]);
    const [tableItems, setTableItems] = useState([null]);
    const [usuarioLogado1, setUsuarioLogado] = useState(usuarioLogado);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [equiInfo, setEquipInfo] = useState([]);
    const classes = useStyles();
    const history = useHistory()

    const bindData = (data) => {
        let binded = data.map((item, i) => {
            let obj = {}
            //obj.info = <IconButton color="info" onClick={() => redirect(item)}><Info/></IconButton>
            obj.lupa = <Tooltip title={'Ver mais detalhes'}>
                <IconButton color='success' onClick={() => item && monitorEquipament(item)}>
                    <SearchIcon />
                </IconButton></Tooltip>
            obj.lock = mudaStatusAutorizacao(item, i)
            obj.status_connection = statusConnect(item)
            if (usuarioLogado !== UserTypes.CLI) obj.usuario = <Button onClick={() => redirectUsers(item)}>{item.Usuario} </Button>
            //#TODO - ADICIONNAR O NOME DO USUARIO 
            obj.nome = item.nome
            obj.cadastro = DateFormat(new Date(item.data_cad_server))
            obj.modelo = item.device_modelo
            obj.versaoApp = item.app_version
            obj.id = item.device_id
            //obj.app = app(item) //#TODO - API DEVE RETORNAR QUAL O MODELO (EX: SM4, GPS, ...)

            return obj

        })
        return binded
    }
    useEffect(() => {
        if (items) {
            let items1 = items
            let userTabela = bindData(items)
            setTableItems(userTabela)
            console.log('equipamentos', JSON.stringify(items))
        }

    }, [items]);

    useEffect(() => {
        const header = []
        if (usuarioLogado === UserTypes.CLI) {
            header.push('Ação', 'Permitido', 'Status', `${strings.getString('info_name')}`, `${strings.getString('info_register')}`,
                `${strings.getString('info_model ')}`, `${strings.getString('unity_version_app')}`, 'Id');
        }
        else if (usuarioLogado === UserTypes.DEV) {
            header.push('Ação', 'Permitido', 'Status', `Nome do Cliente`, `${strings.getString('info_name')}`, `${strings.getString('info_register')}`,
                `${strings.getString('info_model ')}`, `${strings.getString('unity_version_app')}`, 'Id');
        }
        setHeaders(header)
    }, [usuarioLogado])

    //Função responsável por exibir o quadro sobre os equipamentos
    //Futuramente obter dados mais especificos sobre o equipamento
    function monitorEquipament(item) {
        console.log('item', item)
        setEquipInfo({
            nome: item.nome || '- - -',
            status: (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '50px',
                        backgroundColor: item.autorizado ? 'green' : 'red',
                        color: 'white',
                        fontSize: '24px',
                    }}
                >
                    {item.autorizado ? 'Autorizado' : 'Bloqueado'}
                </div>
            ),
            modelo: item.device_modelo || '- - -',
            sistema: item.device_sistema || '- - -',
            macId: item.device_id,
        });
        setIsModalOpen(true);
    }

    function app(item) {

        if (item.app) {
            return item.app
        } else {
            return '- - -'
        }
    }


    function redirectUsers(item) {
        history.push(Pages.routes.Customer + '/' + item.usuario_id)
    }

    function statusConnect(item) {
        //#TODO - ADICOINAR NO JSON O STATUS DO EQUIPAMENTO
        item.status = 'CONECTADO'

        let iconeStatus, tooltipMessage = ''

        if (item.status === 'DESCONECTADO') {
            iconeStatus = (<CircleIcon color={"error"} />)
            tooltipMessage = 'DESCONECTADO'
        }

        if (item.status === 'CONECTADO') {
            iconeStatus = (<CircleIcon color={"success"} />)
            tooltipMessage = 'CONECTADO'
        }

        return (
            <Tooltip title={tooltipMessage}>
                {iconeStatus}
            </Tooltip>
        )

    }



    function mudaStatusAutorizacao(item, i) {


        let iconeAutorizacao, tooltipMessage = ''

        if (item.autorizado === 1) {
            iconeAutorizacao = (<IconButton color="success" onClick={() => statusItem(item, i)} ><LockOpenIcon /></IconButton>)
            tooltipMessage = 'AUTORIZADO'
        }

        if (item.autorizado !== 1) {
            iconeAutorizacao = (<IconButton color="error" onClick={() => statusItem(item, i)} ><LockOpenIcon /></IconButton>)
            tooltipMessage = 'BLOQUEADO'
        }

        return (
            <Tooltip title={tooltipMessage}>
                {iconeAutorizacao}
            </Tooltip>
        )

    }

    function statusItem(item, i) {
        loading = true
        EquipamentLock(item).then(() => {
            let newItem = { ...item }
            newItem.autorizado === 1 ? newItem.autorizado = 0 : newItem.autorizado = 1
            items[i] = newItem
            let binded = bindData(items)
            setTableItems(binded)
            refresh()
        }).finally(() => {
            loading = false
        })
    }


    return (
        <>
            <ProjectTable
                loading={loading}
                rowsPerPage={RowsPerPage}
                style={{ flex: 1, whiteSpace: 'nowrap', width: 500 }}
                title={strings.getString('screen_list_equip')}
                headers={headers}
                items={tableItems}
                onRefresh={refresh}
                icon={<Agriculture style={{ marginRight: '8px', alignSelf: 'center' }} sx={{ fontSize: 35, color: "#ffd600" }} />}
            />
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                className={classes.modal}
            >
                <div className={classes.modalContent}>
                    <h2 className={classes.modalTitle}>{equiInfo.nome}</h2>
                    <p>Status: {equiInfo.status}</p>
                    <p>Modelo: {equiInfo.modelo}</p>
                    <p>Sistema: {equiInfo.sistema}</p>
                    <p>MAC Id: {equiInfo.macId}</p>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setIsModalOpen(false)}
                    >
                        Fechar
                    </Button>
                </div>
            </Modal>
        </>
    );
}

export default EquipamentList;