let Utilities = {
    waitMills: (milliseconds) => {
        return new Promise((res) => {            
            setTimeout(() => {
                res()
            }, milliseconds)
        })
    },
    
    primeiraMaiuscula: (string) => {
        if(!string || string === '') return ""
        return string.charAt(0).toUpperCase() + string.slice(1)
    },

    primeirasMaiusculasPalavras: (string) => {
        if(!string || string === '') return ""
        let words = string.split(" ");
        words = words.map((item, i) => {
            return Utilities.primeiraMaiuscula(item)
        })
        return words.join(" ")
    },

    todasMaiusculas: (string) => {
        if(!string || string === '') return ""
        return string.toUpperCase();
    },

    checkKeyIsEnter: (e) => {
        return ( e.charCode === 13)
    }
}

export default Utilities;