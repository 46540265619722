/*
USE CASE: ChartPeriodDataBinder
Desc: Processa um array de objetos para o padrão do gráfico ChartPeriod
params: 
   - dateProperty: Propriedade que contém a data
   - items: Array de objetos de qualquer forma, porém cada objeto deve conter ao menos o atributo dateProperty
   - filter: O filtro selecionado para montar o gráfico
   - limit: Limite de itens do array de objetos que serão processados.
   - isAscent: Se true, o array de objetos será ordenado por ordem ascentente
returns:
  - Uma promise que Retorna um array de objetos no padrão esperado para o montador de gráfico ChartPeriod
  ex obj no array de reposta: {
      date: Objeto tipo Date,
      value: Quantidade de itens nesta data
  }
*/
import values from '../values/values'
const ChartPeriodDataBinder = ( dateProperty, items, filter, limit, isAscent) => {

    return new Promise((resolve) => {
       
        if(!items || items.length === 0) resolve([])

        let sorted = items.sort((a, b) => {
            let dateA = new Date(a[dateProperty])
            let dateB = new Date(b[dateProperty])
            //console.log('date a',JSON.stringify(dateA))
            //console.log('date b',JSON.stringify(dateB))
            if(isAscent) return dateA - dateB
            else return dateB - dateA
            
      
        })
        
        let newChartValues = new Array(limit)
        const dataInicial = new Date(sorted[0][dateProperty]) 
        //ZERANDO VALORES DE HORAS, DIAS E MESES DE ACORDO COM O FILTRO
        dataInicial.setHours(0,0,0,0)
        if(filter === values.TIME_FILTERS[1]){ //FILTRANDO POR MES, ZERAR DIA
            dataInicial.setDate(1)
        }        
        if(filter === values.TIME_FILTERS[2]){ //FILTRANDO POR ANO, ZERAR MES E DIA
            dataInicial.setDate(1)
            dataInicial.setMonth(0)
        }
       
        //Algoritimo de bind data
        //- A data do item sempre será item[dateProperty]        
        for(let i=0; i < newChartValues.length; i++){            
            let newDate = new Date(dataInicial.getTime())            
            newDate.setTime(dataInicial.getTime() - (i*filter.value))      

            newDate.setHours(0,0,0,0)               
            if(filter === values.TIME_FILTERS[1]){ //FILTRANDO POR MES, ZERAR DIA
                newDate.setDate(1)
            }            
            if(filter === values.TIME_FILTERS[2]){ //FILTRANDO POR ANO, ZERAR MES E DIA
                newDate.setDate(1)
                newDate.setMonth(0)
            }

            newChartValues[i] = {date: newDate, value: 0}
        }
        console.log("Data Inicial",dataInicial)

        for(let item of sorted){ 
            let dataItem = new Date(item[dateProperty])  
            //ZERANDO VALORES DE HORAS, DIAS, e MESES DE ACORDO COM O FILTRO
            dataItem.setHours(0,0,0,0)
            if(filter === values.TIME_FILTERS[1]){ //FILTRANDO POR MES, ZERAR DIA
                dataItem.setDate(1)
            }            
            if(filter === values.TIME_FILTERS[2]){ //FILTRANDO POR ANO, ZERAR MES E DIA
                dataItem.setDate(1)
                dataItem.setMonth(0)
            }        
            let difTotalDiv = (isAscent ? dataItem - dataInicial : dataInicial - dataItem) / filter.value
            let diferenca = Math.floor(difTotalDiv)
            if(diferenca >= limit) break
            if (newChartValues != null )newChartValues[diferenca].value++  
        }

        resolve(newChartValues)
         
    })
}
export default ChartPeriodDataBinder;
