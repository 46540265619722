import { amber, blue, green, grey, lightGreen, orange, pink, purple, red, yellow } from "@mui/material/colors"

let style = 'dark'

const light = {
    palette: {
        mode: 'light',
        primary: {
            main: amber[800]
        },
        secondary: {
            main: lightGreen["A700"]
        },
        background: {
            paper: "#fafbfd",
            default: "#e5e5e5"
        }
    },
}

const dark = {
    palette: {
        mode: 'dark',
        primary: {
            main: amber[400]
        },
        secondary: {
            main: lightGreen["A700"]
        },
        background: {
            paper: grey[900],
            default: "#10151b"
        },
    },
}

const colors = {
    principal: '#ffca28'
}

export default {

    getTheme: () => {
        if (style === 'light') return light
        return dark
    },

    test: 'rgba(255,0,0,0.0)',

    setStyle: (style) => {
        style = style
    },

    getStyle: () => {
        return style
    },

    getColors: () => {
        return colors
    }
}