import APIManager from '../connection/APIManager'
import APIConstants from '../connection/APIConstants'
import strings from '../values/strings/strings'
/*
USE CASE: UserCreate
Desc: Envia um POST para a API de criar usuário 
params: 
   - objeto, referente ao usuário a ser criado
returns:
  - A promise that, Chama o POST da api para criar usuário, e caso criado com sucesso, resolve()
  caso ocorra algum erro reject()
*/
const UserCreate = (obj, adm) => {
  return new Promise((resolve, reject) => {

    if (!obj) return

    let header = {}
    if (adm) {
      //TODO - Trocar pelos headers padrão, necessário trocar também na API
      header["email"] = adm.email
      header["token"] = adm.token
    }

    let body = {
      obj: obj
    }

    console.log("Dados do usuário", body)
    APIManager.send("/usuarios", "POST", header, body, 201).then((res) => {
      return resolve()
    }).catch(async (e) => {
      let message = await e.text()

      if (message === APIConstants.EXISTS_EMAIL) return reject(strings.getString('screen_singup_error_emailexists'))

      return reject(strings.getString('screen_singup_error_internal'))
    })
  })
}
export default UserCreate;