import { Stack, Typography } from '@mui/material';
import React from 'react';
import values from '../../values/values'

function InfoContainer(props) {
    return (        
        <div style={{     
            flex: 1,       
            padding: values.padding[0], 
            ...props.style
        }}>
            <Stack spacing={values.spacing[0]}>
                <Typography align='center'>{props.title}</Typography>
                <Typography variant="h4" color="primary" align="center">{props.value}</Typography>
            </Stack>
            {props.children}
        </div>        
    );
}

export default InfoContainer;