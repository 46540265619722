import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import values from '../../values/values';
import CircularProgress from '@mui/material/CircularProgress';
import Refresh from '../basic/Refresh';

function ProjectTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 5);
  const [sortConfig, setSortConfig] = useState({ field: null, direction: null });

  useEffect(() => {
    console.log('sortConfig:', sortConfig);
  }, [sortConfig]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (field) => {
    let direction = 'asc';

    if (sortConfig.field === field) {
      direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    }
    setSortConfig({ field, direction });
  };

  const getSortedItems = () => {
    let sortedItems = props.items ? [...props.items] : [];

    if (sortConfig.field) {
      sortedItems.sort((a, b) => {
        const aValue = a[sortConfig.field];
        const bValue = b[sortConfig.field];

        if (aValue == null || bValue == null) return 0;

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        } else if (aValue instanceof Date && bValue instanceof Date) {
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        } else {
          return sortConfig.direction === 'asc' ? aValue.toString().localeCompare(bValue.toString()) : bValue.toString().localeCompare(aValue.toString());
        }
      });
    }

    return sortedItems;
  };

  const sortedItems = getSortedItems();

  return (
    <Stack spacing={3} style={{ ...props.style }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        {props.title && (
          <>
            {props.icon && props.icon}
            <Typography style={{ flex: 1, alignSelf: 'center', marginLeft: values.margin[2], marginRight: values.margin[2], color: "#F8C102", fontSize: 25 }}>
              {props.title}
            </Typography>
            <Refresh onClick={props.onRefresh} />
          </>
        )}
      </div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {props.rownumber && <TableCell>Nº</TableCell>}
              {props.headers &&
                props.headers.map((v) => (
                  <TableCell key={v}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleSort(v)}>
                      {v}
                      {sortConfig.field === v && (
                        sortConfig.direction === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                      )}
                    </div>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.loading && sortedItems.length > 0 && sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              const keys = Object.keys(row);
              return (
                <TableRow key={index}>
                  {props.rownumber && (
                    <TableCell style={{ textAlign: 'center' }}>
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                  )}
                  {keys.map((v, i) => (
                    <TableCell key={i}>
                      {row[v]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {!props.loading && (!sortedItems || sortedItems.length === 0) &&
              <TableRow>
                <TableCell colSpan={props.headers.length}>
                  <Typography style={{ textAlign: 'center' }}>
                    Sem dados
                  </Typography>
                </TableCell>
              </TableRow>
            }
            {props.loading &&
              <TableRow>
                <TableCell colSpan={props.headers.length}>
                  <Typography style={{ textAlign: 'center' }}>
                    <CircularProgress disableShrink />
                  </Typography>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={props.items && props.items.length > 0 ? props.items.length : 0}
        rowsPerPageOptions={[5, 10, 25, 50]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
}

export default ProjectTable;






/* EXEMPLO DE USO Tudo esta no componente ColumnGroupingTable e funcionou sem nenhum erro 

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export default function ColumnGroupingTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                Country
              </TableCell>
              <TableCell align="center" colSpan={3}>
                Details
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Paper>
  );
}
export default ProjectTable;

 */