/*
  Página de perfil do usuário
  Desc: Página de perfil do usuário, onde ele pode ver e editar seus dados
  a senha solicita apenas o usuário digitar a senha antiga e a nova uma vez
*/
import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, Snackbar, Alert, Paper } from '@mui/material';
import AuthBuildHeaderUserObject from '../../usecases/AuthBuildHeaderUserObject';
import APIManager from '../../connection/APIManager';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '85%',
    margin: '0 auto',
    marginTop: theme.spacing(5),
    color: '#fff',
  },
  profileTitle: {
    marginBottom: theme.spacing(3),
    color: '#fff',
  },
  card: {
    padding: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  editButton: {
    marginLeft: theme.spacing(2),
    color: '#FFC107',
    borderColor: '#FFC107',
  },
  dialog: {
    backgroundColor: '#424242',
    color: '#fff',
  },
  dialogTitle: {
    backgroundColor: '#333',
    color: '#fff',
  },
  dialogContent: {
    backgroundColor: '#424242',
  },
  dialogActions: {
    backgroundColor: '#333',
  },
  snackbar: {
    width: '100%',
  },
}));

function ProfilePage() {
  const classes = useStyles();
  const [userData, setUserData] = useState({
    cpf_cnpj: '',
    nome: '',
    email: '',
    telefone: '',
  });

  const [editField, setEditField] = useState('');
  const [editValue, setEditValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  useEffect(() => {
    const loadDados = async () => {
      try {
        const email = await AuthBuildHeaderUserObject();

        if (!email) {
          console.log('Erro ao carregar os dados do usuário');
          return;
        }

        const headers = { ...email };
        const response = await APIManager.send(`/usuarios`, 'GET', headers);
        const json = await response.json();
        setUserData(json[0]);
        console.log('Dados do usuário', json[0]);

      } catch (error) {
        console.log('Erro ao carregar os dados do usuário', error);
      }
    };
    loadDados();
  }, []);

  const handleEditClick = (field) => {
    setEditField(field);
    setEditValue(userData[field]);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSaveClick = async () => {
    let header = await AuthBuildHeaderUserObject().catch(e => { return null });
    if (!header) {
      setSnackbarMessage('Falha na autenticação do usuário.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    let headers = { ...header };

    let body = { obj: { ...userData, [editField]: editValue } };

    try {
      await APIManager.send('/usuarios', 'PUT', headers, body);
      setUserData(prevState => ({ ...prevState, [editField]: editValue }));
      setSnackbarMessage('Dados atualizados com sucesso!');
      setSnackbarSeverity('success');
      setIsDialogOpen(false);
    } catch (e) {
      console.log(e);
      setSnackbarMessage('Erro ao atualizar os dados.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleCancelClick = () => {
    setIsDialogOpen(false);
  };

  const allowedFields = [
    'cpf_cnpj',
    'nome',
    'email',
    'telefone',
  ];

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.profileTitle}>
        Minha Conta
      </Typography>
      <Grid container spacing={3}>
        {allowedFields.map((field) => (
          <Grid item xs={12} key={field}>
            <Paper className={classes.card}>
              <TextField
                className={classes.textField}
                label={field === 'cpf_cnpj' ? 'CPF/CNPJ' : field}
                value={typeof userData[field] === 'object' ? JSON.stringify(userData[field]) : userData[field]}
                disabled
                InputLabelProps={{ shrink: true }}
              />
              {field !== 'cpf_cnpj' && (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.editButton}
                  onClick={() => {
                    handleEditClick(field)
                  }}
                >
                  Editar
                </Button>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Dialog open={isDialogOpen} onClose={handleDialogClose} classes={{ paper: classes.dialog }}>
        <DialogTitle className={classes.dialogTitle}>Editar {editField}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            fullWidth
            label={editField === 'cpf' ? 'CPF' : editField}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCancelClick} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSaveClick} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} className={classes.snackbar}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProfilePage;
