/*
NAME: ChartBarsLimits
DESC: Gráfico que exibe barras e linhas
props:
- title: string, titulo do grafico
- items: Array de objetos, contendo text, val
- limits: Array de objetos, contendo text, val
- - - - Default
- style: Object, style object to overwrite this component style
NOTES:
*/

import React, { useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Typography } from '@mui/material';
import values from '../../values/values';

function ChartBarsLimits({ title, items, style }) {
    const [copiaItems, setCopiaItems] = useState([]);
    const [maxValue, setMaxValue] = useState(0);
    const [minValue, setMinValue] = useState(0);

    useEffect(() => {
        setCopiaItems([...items]);
        const calculatedMaxValue = Math.max(...items.map(item => Math.max(item.value, item.max)));
        const calculatedMinValue = Math.min(...items.map(item => Math.min(item.value, item.min)));
        setMaxValue(calculatedMaxValue + 1);
        setMinValue(calculatedMinValue > 0 ? calculatedMinValue - 1 : 0);
    }, [items]);

    return (
        <div style={{ width: '100%', overflow: 'auto', ...style, paddingRight: values.margin[1], marginRight: values.margin[1] }}>
            <Typography style={{ marginBottom: values.margin[1] }} color='primary'>{title}</Typography>
            <div style={{ width: '98%', minWidth: '800px', margin: values.margin[2], alignContent: 'center', justifyItems: 'right' }}>
                <ResponsiveContainer width="100%" height={300} style={{ justifyContent: 'center' }}>
                    <ComposedChart data={copiaItems}>
                        <XAxis dataKey="text" />
                        <YAxis domain={[minValue, maxValue]} allowDecimals={false} />
                        <Legend />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Bar dataKey="value" name='Sem/M' barSize={20} fill="#4f8" />
                        <Line type="monotone" dataKey="max" name='Max' stroke="#ff0000" />
                        <Line type="monotone" dataKey="min" name='Min' stroke="#ff0000" />
                        <Line type="monotone" dataKey="target" name='Alvo' stroke="#ffff00" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
export default ChartBarsLimits;


