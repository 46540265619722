/*
USE CASE: WorkGetErrorVelocidade
Desc: Compara os valores do objeto para determinar se a velocidade está com alerta
params: 
   - item: objeto no padrão da API que representa um trabalho
returns:
  - True caso tenha erro de velocidade, False caso esteja tudo OK ou os parametros forem inválidos
*/

import strings from '../values/strings/strings'

const WorkGetErrorVelocidade = (item) => {

    if(!item || 
        !item.plantio || 
        !item.plantio.config_vel_kmh_max ||
        !item.coordenada ||
        !item.coordenada.velocidade_antena_kmh
    ) return null
    
    let message = null

    if(item.coordenada.velocidade_antena_kmh >= item.plantio.config_vel_kmh_max)
        return message = `${strings.getString('WorkGetErrorVelocidade_seepd_max_exeeded')} `

    if(item.coordenada.velocidade_antena_kmh === 0)
        return message =   `${strings.getString('WorkGetErrorVelocidade_work_stopped')} `
    
    return message
}
export default WorkGetErrorVelocidade;