/*
USE CASE: DateFormat
Desc: Converte Data e horario para o formato  HH:mm - dd/mm/yyyy
params: 
   - date: Data a ser formatada
returns:
    - Uma string com a data formatada
  
*/
const DateFormat = (date) => {
  //console.log('validando data',date)
  if (isNaN(date.getTime())) return ' - - ' 
  if(!date) return ' - - '
  if(date === "") return ' - - '
  
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let day =  date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  
  if (hours < 10) {
    hours = '0' + hours
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (day < 10) {
    day = '0' + day
  }
  if (month < 10) {
    month = '0' + month
  }
  
    return(`${day}/${month}/${year} - ${hours}:${minutes}`)
}
export default DateFormat;