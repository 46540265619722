import APIConstants from "../connection/APIConstants";
import APIManager from "../connection/APIManager";
import AuthBuildHeaderUserObject from "./AuthBuildHeaderUserObject";
import CacheLoadCredentials from "./CacheLoadCredentials";

/*
USE CASE: EquipamentLock
Desc: Caso de uso responsavel por bloquear o equipamento ou autorizar no sistema
params: 
   - item: objeto, equipamento com para ser bloqueado/desbloqueado
returns:
  - Uma promise que solicita troca de status para a API e caso troque com sucesso retorna resolve()
  caso aconteça algum erro retorna reject()
*/
const EquipamentLock = (item) => {
    return new Promise(async (resolve, reject) => {

      let headers = await AuthBuildHeaderUserObject().catch(e => {return null})
      if(!headers) return reject()

      let body = {}

      item.autorizado = !item.autorizado      
      body.obj = item

      APIManager.send("/equipamento","PUT",headers,body).then(res => {
        resolve()
      }).catch(e => {
        reject(e)
      })
    })
}
export default EquipamentLock;