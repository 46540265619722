/*
NAME: DashBoardDev
DESC: Tela de DashBoard para desenvolvedores, com acesso a todas as funcionalidades do sistema, 
props:
- 
- - - - Default
- style: Object, style object to overwrite this component style
NOTES:

*/
import React, { useEffect, useState } from 'react';
import BasicCard from '../basic/BasicCard';
import InfoCard from '../basic/InfoCard';
import RowContainer from '../basic/RowContainer';
import RowContainerItem from '../basic/RowContainerItem';
import EquipmentsDashboardList from '../specific/EquipmentsDashboardList';
import EquipmentsMap from '../specific/EquipmentsMap';
import strings from '../../values/strings/strings';
import values from '../../values/values'
import { Divider, CircularProgress } from '@mui/material';
import EquipamentGetData from '../../usecases/EquipamentGetData';
import UserGetData from '../../usecases/UserGetData';
import UserDashBoardList from '../specific/UserDashBoardList';
import { useHistory } from 'react-router';
import CacheLoadCredentials from '../../usecases/CacheLoadCredentials';
import AuthBuildHeaderUserObject from '../../usecases/AuthBuildHeaderUserObject';
import APIManager from '../../connection/APIManager';
import WorksGetMonitoring from '../../usecases/WorksGetMonitoring'

function DashBoardDev({ }) {


    const [equipamentos, setequipamentos] = useState([])
    const [loading, setloading] = useState(null)
    const [trabalhos, settrabalhos] = useState([])
    const [usuarios, setusuarios] = useState([])
    const [usuarioLogado, setUsuarioLogado] = useState(null)
    const [equipamentosConectados, setEquipamentosConectados] = useState([])
    const [equipamentosBloqueados, setequipamentosBloqueados] = useState([]);
    const [equipamentosRegistrados, setequipamentosRegistrados] = useState([]);
    const [trabalhosIniciados, setTrabalhosIniciados] = useState(0)
    const [usuariosConectados, setUsuariosConectados] = useState(0)
    const [novosUsuarios, setNovosUsuarios] = useState(0)
    const [trabalhosPendentes, setTrabalhosPendentes] = useState(0)
    const [sincronismoLastMonth, setsincronismoLastMonth] = useState(0)
    const [saframaxAjuda, setsaframaxAjuda] = useState(0)
    const [totalTrabalhos, settotalTrabalhos] = useState(0)
    const [position, setPosition] = useState(null)

    const getUsuarioLogado = async () => {
        let user = await CacheLoadCredentials()
        setUsuarioLogado(user)
    }
    useEffect(() => {
        getLocalizacao()
        getEquipamentos()
        getUsers()
        getTrabalhos()
        getUsuarioLogado()
    }, [])

    useEffect(() => {
        let trabalhostotal = 0
        let newtrabalhos = 0
        let pendentes = 0

        trabalhos.forEach((item, index) => {
            if (item.trabalho_status === 'INICIADO')
                newtrabalhos++
            if (item.trabalho_status === 'PARADO')
                pendentes++
            if (item)
                trabalhostotal++
        })
        setTrabalhosPendentes(pendentes)
        setTrabalhosIniciados(newtrabalhos)
        settotalTrabalhos(trabalhostotal)
    }, [trabalhos])

    useEffect(() => {
        let qtdusuarios = usuarios;
        let data = new Date();
        let lastDate = new Date();
        lastDate.setDate(data.getDate() - 30);
        let novosusuarios = 0;

        console.log('Lista de usuarios:', usuarios);

        usuarios.forEach(item => {
            const tableData = new Date(item.data_cad_server);
            if (tableData >= lastDate && tableData <= data) {
                novosusuarios++;
            }
        });

        console.log('Datas:', data, lastDate);

        setUsuariosConectados(qtdusuarios.length);
        setNovosUsuarios(novosusuarios);
    }, [usuarios]);

    useEffect(() => {

        let qtdequipamentos = equipamentos;
        setEquipamentosConectados(qtdequipamentos.length)
    }, [equipamentos])

    //Chamadas de equipametos, usuarios e trabalhos  
    useEffect(() => {
        let bloqueados = 0
        let conectados = 0

        equipamentos.forEach((item, index) => {

            if (item.autorizado !== 1) bloqueados++
            if (item.status === 'CONECTADO') conectados++
            setequipamentosBloqueados(bloqueados)
            setequipamentosRegistrados(equipamentos.length)
        })
    }, [equipamentos])

    const history = useHistory();

    useEffect(() => {
        //#TODO - Conferir se está certo essa função - Seu objetivo é retornar a quantidade de sincronismo do mes
        let date = new Date()
        let lastDate = new Date()
        lastDate.setDate(date.getDate() - 30)


        let sincronismo = 0;
        let trabalhospendentes = 0;
        trabalhos.forEach(item => {
            const tableData = new Date(item.data_inicio)
            if (item.trabalho_status !== 'ERROR' && tableData >= lastDate) {
                sincronismo++
            }
            if (item.sincronismo_id === '' || item.sincronismo_id === null) {
                trabalhospendentes++
            }
        })
        // setTrabalhosPendentes(trabalhospendentes)
        setsincronismoLastMonth(sincronismo)
        console.log('Sincronismo do mês:', sincronismo)
    }, [trabalhos])

    {/*Chamadas de equipametos, usuarios e trabalhos  */ }
    const getEquipamentos = () => {

        setloading(true)
        EquipamentGetData().then(items => {
            setequipamentos(items)
            // console.log('Lista de equipamentos', items)
        }).catch(err => {
            console.log('Erro ao contar a quantidade de equipamentos ' + err)
        }).finally(() => {
            setloading(false)
        })
    }

    const getUsers = () => {

        setloading(true)
        UserGetData().then(items => {
            setusuarios(items)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setloading(false)
        })
    }

    const getLocalizacao = async () => {
        try {
            setloading(true);
            const obj = await AuthBuildHeaderUserObject();

            if (!obj) {
                throw new Error("Dados de sessão incompletos.");
            }

            let headers = {
                ...obj
            };

            const response = await APIManager.send("/trabalho/basic/equipamentos", "GET", headers);
            const json = await response.json();
            setPosition(json);
            return json;
        } catch (error) {
            console.error("Erro na função getLocalizacao:", error);
            throw error;
        } finally {
            setloading(false);
        }
    };

    const getTrabalhos = () => {
        setloading(true)
        WorksGetMonitoring().then(items => {
            settrabalhos(items)
            console.log('Lista de trabalhos', items)
        }).catch(err => {
            console.log('Erro ao contar a quantidade de trabalhos ' + err)
        }).finally(() => {
            setloading(false)
        })
    }

    {/*Fim das chamadas de equipamentos, usuarios e trabalhos  */ }
    function editChart() {
        alert('Editar gráfico')
    }

    return (
        <div>

            <RowContainer>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title={strings.getString('unity_synchronisms_lastMonth')}
                        value={sincronismoLastMonth !== null ? sincronismoLastMonth : <CircularProgress />}
                    />
                </RowContainerItem>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title={strings.getString('works_total_monitoring')}
                        value={totalTrabalhos !== 0 ? totalTrabalhos : <CircularProgress />}
                    />
                </RowContainerItem>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title={strings.getString('unity_work_pendent_title')}
                        value={trabalhosPendentes !== 0 ? trabalhosPendentes : <CircularProgress />}
                    />
                </RowContainerItem>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title={strings.getString('default_works_start')}
                        value={trabalhosIniciados !== 0 ? trabalhosIniciados : <CircularProgress />}
                    />
                </RowContainerItem>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title={strings.getString('equips_connecteds')}
                        value={equipamentosConectados !== 0 ? equipamentosConectados : <CircularProgress />}
                    />
                </RowContainerItem>
            </RowContainer>

            <RowContainer style={{ display: 'flex', width: '50vh', flexDirection: 'column' }}>
                <RowContainerItem style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '55%' }}>
                    <EquipmentsMap trabalhos={position} height={650} />
                    <Divider oritentation="horizontal" flexItem style={{ margin: values.margin[1] }} />
                    <BasicCard >
                        <EquipmentsDashboardList
                            usuarioLogado={usuarioLogado}
                            RowsPerPage={10}
                            items={equipamentos}
                            usuario={usuarios}
                            refresh={getEquipamentos}
                            loading={loading} />
                    </BasicCard>
                </RowContainerItem>
                <RowContainerItem style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '45%' }}>
                    <div style={{ display: 'flex', marginBottom: values.margin[1] }}>
                        <InfoCard flex title={strings.getString('users_connecteds')}
                            value={usuariosConectados !== 0 ? usuariosConectados : <CircularProgress />} />
                        <div style={{ width: values.margin[2] }} />
                        <InfoCard flex title={strings.getString('users_new_users')}
                            value={novosUsuarios !== 0 ? novosUsuarios : <CircularProgress />} />
                    </div>
                    <Divider oritentation="vertical" flexItem style={{ margin: values.margin[1] }} />

                    <BasicCard >
                        <UserDashBoardList
                            usuarioLogado={usuarioLogado}
                            rowsPerPage={25}
                            items={usuarios}
                            refresh={getUsers}
                            loading={loading} />
                    </BasicCard>
                </RowContainerItem>
            </RowContainer>

            <RowContainer spacing={4} >
                <RowContainerItem minWidth={800}>     {/*VER MELHOR SOLUÇÃO */}

                </RowContainerItem>
            </RowContainer>
        </div>
    );
}

export default DashBoardDev;

{/* 
            <RowContainer>
                  /// Implementações precisam de revisão, verificar melhor forma de apresentação  ///
                <RowContainerItem>
                    <UsersChart items={usuarios} />
                </RowContainerItem>
                </RowContainerItem>
            </RowContainer>
                
                /// Implementações precisam de revisão, verificar melhor forma de apresentação  ///
            <RowContainerItem>
                <WorksChartPeriodSinc items={trabalhos} />
                <WorksChartPeriod items={trabalhos} />
            </RowContainerItem>
            <RowContainerItem>
            */}
{/*
            /// Implementações precisam de revisão, verificar melhor forma de apresentação  ///
            RowContainer spacing={4}> 
                <RowContainerItem>
                <BasicCard style={{ flex:1, textAlign: 'left', color:"#F8C102", fontWeight: 'bold'}}>
                <ProjectChartEquip  
                    items = {{equipamentosBloqueados , equipamentosConectados, equipamentosRegistrados}}
                    total = {equipamentos.length + equipamentosBloqueados}/>
                </BasicCard>
                </RowContainerItem>
            </RowContainer>*/}