/* eslint-disable no-unused-vars */
/*
NAME: Equipaments
DESC: Componente responsavel por exibir a lista de eequipamentos além ter uma renderização 
condicional para que se o usuario for diferente de cliente ele exiba o grafico de equipamentos por modelo,

props:
- 
- - - - Default
- style: Object, style object to overwrite this component style

NOTES:
    - #TODO - Criar gráfico de quipamentos segundo o modelo
    - #TODO - Checar API, verificar se os dados são compativéis com o gráfico, se não, implementar
*/

import { useEffect, useState } from "react";
import InfoCard from "../basic/InfoCard";
import RowContainer from "../basic/RowContainer";
import RowContainerItem from "../basic/RowContainerItem";
import EquipamentGetData from '../../usecases/EquipamentGetData';
import EquipamentList from "../specific/EquipamentList";
import BasicCard from "../basic/BasicCard";
import ProjectChartEquip from "../basic/ProjectChartEquip";
import EquipamentChartModels from '../specific/EquipamentChartModels'
import strings from "../../values/strings/strings";
import CacheLoadCredentials from "../../usecases/CacheLoadCredentials"
import APIConstants from "../../connection/APIConstants";


function Equipament({ style }) {

    const [equipamentos, setequipamentos] = useState([]);
    const [loading, setloading] = useState([]);
    const [usuarioLogado, setusuarioLogado] = useState([]);
    const [equipamentosBloqueados, setequipamentosBloqueados] = useState([]);
    const [equipamentosRegistrados, setequipamentosRegistrados] = useState([]);
    const [equipamentosConectados, setequipamentosConectados] = useState([]);

    useEffect(() => {
        CacheLoadCredentials().then(res => {
            if (res && res.tipo) setusuarioLogado(res.tipo)
        })
        getEquipamentos()

    }, [])


    useEffect(() => {

        let data = new Date()

        let bloqueados = 0
        let conectados = 0

        equipamentos.forEach((item, index) => {

            if (item.autorizado !== 1) bloqueados++
            if (item.status === 'CONECTADO') conectados++ //#TODO - JSON DEVE RETORNAR O STATUS

            setequipamentosBloqueados(bloqueados)
            setequipamentosConectados(conectados)
            setequipamentosRegistrados(equipamentos.length)
        })

    }, [equipamentos])

    const getEquipamentos = () => {
        setloading(true)
        EquipamentGetData().then(items => {
            setloading(true)
            setequipamentos(items)
            console.log('Lista de equipamentos: ', items)
        }).catch(err => {
            console.log('Erro ao contar a quantidade de equipamentos ' + err)
        }).finally(() => {
            setloading(false)
        })
    }
    return (

        <div>
            <RowContainer>
                <RowContainerItem >
                    <InfoCard flex title={strings.getString('screen_equipament_registred')} value={equipamentosRegistrados} />
                </RowContainerItem>

                <RowContainerItem >
                    <InfoCard flex title={strings.getString('screen_equipament_connected')} value={equipamentosConectados} />
                </RowContainerItem>

                <RowContainerItem >
                    <InfoCard flex title={strings.getString('screen_equipament_blocked')} value={equipamentosBloqueados} />
                </RowContainerItem>
            </RowContainer>

            <RowContainer spacing={4}>
                <RowContainerItem style={{ flex: 2 }} minWidth={500}>   {/*VER MELHOR SOLUÇÃO */}
                    <BasicCard  >
                        <EquipamentList
                            items={equipamentos}
                            usuarioLogado={usuarioLogado}
                            loading={loading}
                            refresh={getEquipamentos}
                            RowsPerPage={10}
                        //onViewMore={onViewMore}
                        />
                    </BasicCard>
                </RowContainerItem>
                {/* ///Gráfico de equipamento será implementado futuramente, com uma melhor funcionalidade
                 { usuarioLogado !== APIConstants.user_type_cli && 
                <RowContainerItem style={{flex:1}} minWidth={300} >
                    <BasicCard height={700}>
                  <EquipamentChartModels 
                   items={equipamentos}
                   title={strings.getString('screen_equipament_chart_title')}/>
                   <ProjectChartEquip
                   items = {{equipamentosBloqueados , equipamentosConectados, equipamentosRegistrados}}
                   total = {equipamentos.length}/>
                   </BasicCard>
                </RowContainerItem>
                }*/}
            </RowContainer>
        </div>
    );
}

export default Equipament;


