import { Button, Card, CardContent, CssBaseline, LinearProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import MessageShow from '../components/basic/MessageShow';
import SaframaxLogo from '../components/basic/SaframaxLogo';
import AuthNewUserEmailToken from '../usecases/AuthNewUserEmailToken';
import Utilities from '../util/Utilities';
import strings from '../values/strings/strings';
import values from '../values/values'
import Pages from './Pages';

function EmailConfirmation(props) {
    const history = useHistory()

    const [loading, setloading] = useState(true)
    const [errormessage, seterrormessage] = useState(null)
    const [successmessage, setsuccessmessage] = useState(null)
    const [token, settoken] = useState(null)

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const param = queryParams.get('token');
        settoken(token)
        if(!param){
            setloading(false)
            seterrormessage(true)
        }else{
            validateToken(param)
        }        
    }, [])

    const validateToken = async (token) => {        
        AuthNewUserEmailToken(token).then(() =>{
            setsuccessmessage(true)
        }).catch(e => {
            seterrormessage(true)
        }).finally(() =>{
            setloading(false)
        })        
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <CssBaseline />
            <Stack>
                <SaframaxLogo />                
                <Card style={{padding: values.padding[1], width: values.card_default_width}}>
                    <CardContent>
                        {loading &&
                            <Stack spacing={2}>
                                <MessageShow title={strings.getString('screen_emailconfirmation_title')} message={strings.getString('screen_emailconfirmation_loading')}/>                                                        
                                <LinearProgress />
                            </Stack>
                        }
                        {!loading && successmessage &&
                            <Stack spacing={2}>
                                <MessageShow type={'secondary'} title={strings.getString('screen_emailconfirmation_success_title')} message={strings.getString('screen_emailconfirmation_success_message')}/>                                                                                        
                                <Button variant="contained" onClick={() => {history.push(Pages.routes.HomePage)}}>Ok</Button>
                            </Stack>
                        }
                        {!loading && errormessage &&
                            <Stack spacing={2}>
                                <MessageShow type={'error'} title={strings.getString('screen_emailconfirmation_error_title')} message={strings.getString('screen_emailconfirmation_error_message')}/>                                                                                        
                                <Button variant="contained" onClick={() => {history.push(Pages.routes.HomePage)}}>{strings.getString('info_close')}</Button>
                            </Stack>
                        }
                    </CardContent>
                </Card>
            </Stack>
        </div>
    );
}

export default EmailConfirmation;