import React from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import CustomerDetails from '../components/pagescomponents/CustomerDetails';

function CustomerInfo(props) {
    return (
        <DashboardFrame>
            <CustomerDetails />
        </DashboardFrame>
    );
}

export default CustomerInfo;