import React, { useEffect, useState, useCallback } from 'react';
import UserGetData from '../../usecases/UserGetData';
import RowContainer from '../basic/RowContainer';
import RowContainerItem from '../basic/RowContainerItem';
import CustomersList from '../specific/CustomersList';
import BasicCard from '../basic/BasicCard';
import InfoCard from '../basic/InfoCard';

const Customer = () => {
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [newUsers, setNewUsers] = useState(0);
    const [userBloquados, setUserBloquados] = useState(0);

    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);
            const items = await UserGetData();
            const clientes = items.filter(item => item.tipo === 'CLI');
            setClientes(clientes);
            calculateStats(clientes);
        } catch (err) {
            console.error('Erro: ', err);
            setError('Falha ao carregar os dados dos clientes.');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const getInfoCustomer = async () => {
            try {
                setLoading(true);
            } catch (error) {

            }
        }
    })

    const calculateStats = useCallback((clientes) => {
        let newUsersCount = 0;
        let bloqueadosCount = 0;
        const last30Days = new Date(new Date().setDate(new Date().getDate() - 30));

        clientes.forEach(item => {
            if (new Date(item.data_cad_server) >= last30Days) {
                newUsersCount++;
            }
            if (item.autorizado === 0 || item.autorizado === 'false') {
                bloqueadosCount++;
            }
        });

        setNewUsers(newUsersCount);
        setUserBloquados(bloqueadosCount);
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <div style={{ padding: '5px' }}>
            <RowContainer style={{ justifyContent: 'space-around', marginBottom: '5px' }}>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title="Total de Usuarios"
                        value={clientes.length}
                        height={100}
                        style={{ margin: '5px' }}
                    />
                </RowContainerItem>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title="Novos Usuarios no último mês"
                        value={newUsers}
                        height={100}
                        style={{ margin: '5px' }}
                    />
                </RowContainerItem>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title="Usuarios Bloqueados"
                        value={userBloquados}
                        height={100}
                        style={{ margin: '5px' }}
                    />
                </RowContainerItem>
            </RowContainer>
            <RowContainer style={{ flex: 2 }}>
                <RowContainerItem style={{ width: '100%' }}>
                    <BasicCard style={{ padding: '20px' }}>
                        <CustomersList
                            style={{ whiteSpace: 'nowrap' }}
                            items={clientes}
                            rowsPerPage={25}
                            refresh={fetchUsers}
                            loading={loading}
                        />
                    </BasicCard>
                </RowContainerItem>
            </RowContainer>
            {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}>{error}</p>}
        </div>
    );
};

export default Customer;



{/*<RowContainerItem height={700}>
                    <BasicCard>
                        <UsersChart
                            style={{ flex:1, 'nowrap': true}}
                            items={clientes} />
                    </BasicCard>
                </RowContainerItem>
                <RowContainerItem height={700}>
                    <BasicCard>
                        <UsersChart 
                            style={{flex:1, 'nowrap':true}}
                            items={users}/>
                    </BasicCard>
                </RowContainerItem>
                <RowContainerItem>
                    
                </RowContainerItem>*/}