import strings from "../values/strings/strings";
import values from "../values/values";

/*
USE CASE: ValidateFieldEmail
Desc: Recebera o e email e será responsavel por fazer as validações nos campos de email
params: 
   - email: string
returns:
  - A promise that always resolve, but will resolve empty if it is valid, if not returns the message error
*/
const ValidateFieldEmail = (email) => {
  return new Promise((resolve) => {
    if (!email || email === '') return resolve(strings.getString('ValidateFieldEmail_warn_email_empty'))

    if (email.split(".").length < 2) return resolve(strings.getString('ValidateFieldEmail_warn_email_invalid'))

    if (email.split('@').length !== 2) return resolve(strings.getString('ValidateFieldEmail_warn_email_invalid'))

    if (email.split(' ').length !== 1) return resolve(strings.getString('ValidateFieldEmail_warn_email_empty'))

    if (email.length > values.string_limit) return resolve(strings.getString('ValidateFieldEmail_warn_email_long'))
    return resolve()
  })
}
export default ValidateFieldEmail;