/*
NAME: Refresh
DESC: 
props:
- 
- - - - Default
- style: Object, style object to overwrite this component style
- children: Component, renders component childs
NOTES:
--Ainda está em fase de testes
*/
import React from "react";
import { Button, Fab } from '@mui/material';
import { Cached } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';

function Refresh(props) {

   function atualizar(){
      return <Fab variant='contained' size='medium' color='primary' onClick={props.onClick}><Cached/></Fab>
   }

   return ( 
   <div>
       <Tooltip title='Atualizar'>{atualizar()}</Tooltip>
   </div>
   );
}

export default Refresh;