import APIManager from "../connection/APIManager";
import AuthBuildHeaderUserObject from "./AuthBuildHeaderUserObject";
/*
USE CASE: EquipamentGetData
Desc: Retorna uma array de objetos de equipamentos da API
params: 
    - device_id: Id do equipamento para obter as informações
returns:
  - Uma promise que se bem sucedida retorna um array de objetos (equipamentos), ou se device_id for válido retorna os dados do equipamento específico,
    caso contrario rejeita
*/

const EquipamentGetData = (device_id) => {
    return new Promise(async (resolve, reject) => {
        let obj = await AuthBuildHeaderUserObject().catch(e => { return null })
        if (!obj) reject()

        let headers = {
            ...obj
        }

        if (device_id) headers.device_id = device_id

        console.log('Headers do equipamento:', headers)
        APIManager.send('/equipamento', 'GET', headers).then(res => {
            return res.json()
        }).then(json => {
            resolve(json)
        }).catch(e => {
            reject(e)
        })
    })
}
export default EquipamentGetData;