const MapLegend = ({ legends, onLegendChange }) => {
  const handleLegendClick = (legendId) => {
    onLegendChange(legendId);
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      overflowX: 'auto',
      paddingTop: '20px',
      paddingBottom: '10px',
      width: '100%',
    }}>
      {legends.map((legend, index) => (
        <div
          key={index}
          onClick={() => handleLegendClick(legend.id)}
          style={{
            width: '100px',
            height: '100px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 5px',
            marginBottom: '10px',
            backgroundColor: legend.show ? legend.color : '#444',
            borderRadius: '5px',
            cursor: 'pointer',
            color: 'black',
            fontWeight: 'bold',
            fontSize: 'large',
            textAlign: 'center',
          }}
        >
          {!legend.min && legend.max && (
            <>
              <div>{`<`}</div>
              <div>{legend.max.toFixed(1)}</div>
            </>
          )}

          {legend.min && legend.max && (
            <>
              <div>{legend.min.toFixed(1)} /</div>
              <div>{legend.max.toFixed(1)} </div>
            </>
          )}

          {legend.min && !legend.max && (
            <>
              <div>{legend.min.toFixed(1)}</div>
              <div>{`>`}</div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default MapLegend;
