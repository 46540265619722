/*
NAME: Pages
DESC: Objeto Pages centralizando as strings contendo as rotas para os componentes
props: NONE
NOTES:
Este componente objeto, possui o objeto routes contendo as rotas das paginas do sistema, quando necessário
chamar o componente Pages.routes.NOME_COMPONENTE_ROTA para utilizar, e quando criadas novas paginas, adiciona-las aqui
*/

let Pages = {
    routes: {
        HomePage: '/login',
        Login: '/login',
        SignUp: '/signup',
        Customers: '/customers',
        Customer: '/customer/:id',
        Dashboard: '/dashboard',
        EmailConfirmation: '/confirmacaoemail',
        Equipments: '/equipments',
        Monitor: '/monitor',
        Profile: '/profile',
        ResetPassword: '/changepass',
        Settings: '/settings',
        System: '/system',
        Tools: '/updates',
        Users: '/users',
        Works: '/works',
    }
}
export default Pages