/*
USE CASE: ValidateString
Desc: Check if the string is valid
params: 
   - string: value
returns:
  - A promise that resolve() if everything is ok, and resolve(true) if the value is empty or null
*/
const ValidateString = (value) => {
    return new Promise((resolve, reject) => {
        if(!value || value === '') return resolve(true) 
        return resolve()
    })
}

export default ValidateString;

