import CacheKeys from "../models/CacheKeys"

/*
USE CASE: CacheLoadCredentials
Desc: Load the user data from localStorage
params: 
   - NONE
returns:
  - A promise that: load the data then resolve with an object of the data
*/

const CacheLoadCredentials = () => {
  return new Promise((resolve, reject) => {
    let data = localStorage.getItem(CacheKeys.session)
    data = JSON.parse(data)
    //console.log('Credenciais carregadas com sucesso', data);
    resolve(data)
  })
}
export default CacheLoadCredentials;