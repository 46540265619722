/*
NAME: CliWorkdetails
DESC: Componente resposavel por exibir um trabalho da lista de trabalhos concluídos por meio do ID
props:
- item: Array de objetos, de um determinado equipamento
- - - - Default
- style: Object, style object to overwrite this component style

NOTES:
*/

import BasicCard from "../basic/BasicCard";
import values from '../../values/values'
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ArrowBackRounded } from "@mui/icons-material";
import DateFormat from "../../usecases/DateFormat";
import strings from "../../values/strings/strings";
import MonitorChartLimits from "../specific/MonitorChartLimits";
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PersonIcon from '@mui/icons-material/Person';
import MapIcon from '@mui/icons-material/Map';
import TimelineIcon from '@mui/icons-material/Timeline';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import RoomIcon from '@mui/icons-material/Room';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ExpandIcon from '@mui/icons-material/Expand';
import ReportIcon from '@mui/icons-material/Report';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import WorkGetErrorVelocidade from "../../usecases/WorkGetErrorVelocidade";
import WorkGetErrorTemperature from "../../usecases/WorkGetErrorTemperature";
import WorkGetErrorSeedsMeter from "../../usecases/WorkGetErrorSeedsMeter";
import WorkGetErrotCurrentPressure from "../../usecases/WorkGetErrotCurrentPressure";
import WorksPulvSectionDisplay from "../specific/WorksPulvSectionDisplay";
import ErrorCard from "../basic/ErrorCard";
import AuthBuildHeaderUserObject from "../../usecases/AuthBuildHeaderUserObject";
import APIManager from "../../connection/APIManager";
import ReportsGraph from "../../usecases/ReportsGraphs";
import ReportsMaps from "../../usecases/ReportsMaps";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IconButton, Typography, TextField, InputAdornment, LinearProgress, Box } from '@mui/material';


function CliWorkdetails({ item }) {
    console.log('item selecionado de trabalho', item)

    const [trabalho, settrabalho] = useState(null)  //#TODO - Chamar esta variavel no lugar de item
    const [horasTrabalhadas, sethorasTrabalhadas] = useState(null)
    const [distanciaPercorrida, setdistanciaPercorrida] = useState(null)
    const [operador, setoperador] = useState(null)
    const [talhao, settalhao] = useState(null)
    const [velocidadeMedia, setvelocidadeMedia] = useState("- -")
    const [areaPlantada, setareaPlantada] = useState(null)
    const [sementesMetro, setsementesMetro] = useState(null)
    const [sementesHectare, setsementesHectare] = useState(null)
    const [populacaoTotal, setpopulacaoTotal] = useState(null)
    const [populacaoMedia, setpopulacaoMedia] = useState(null)
    const [linhasPlantadeiraMedia, setlinhasPlantadeira] = useState(null)
    const [areaPulverizada, setareaPulverizada] = useState(null)
    const [pressaoAtual, setpressaoAtual] = useState(null)
    const [vazaoAlvo, setvazaoAlvo] = useState(null)
    const [vazaoAtual, setvazaoAtual] = useState(null)
    const [vazaoAtualMin, setvazaoAtualMin] = useState(null)
    const [litrosConsumidos, setlitrosConsumidos] = useState(null)
    const [temperaturaAtual, settemperaturaAtual] = useState(null)
    const [populacaoMedialinha, setpopulacaoMedialinha] = useState(null)
    const [configVelMax, setconfigVelMax] = useState(null)
    const [configTemperaturaMax, setconfigTemperaturaMax] = useState(0)
    const [pressaoMinPsi, setpressaoMinPsi] = useState(0)
    const [pressaoMaxPsi, setpressaoMaxPsi] = useState(0)
    const [taxaSementesMetroMax, settaxaSementesMetroMax] = useState(0)
    const [taxaSementesMetroMin, settaxaSementesMetroMin] = useState(0)
    const [taxaSementesMetro, settaxaSementesMetro] = useState(0)
    const [antemaAlturaM, setantemaAlturaM] = useState(0)
    const [especamentoLinhas, setespecamentoLinhas] = useState(0)
    const [velocidadeMaxPermitida, setvelocidadeMaxPermitida] = useState(0)
    const [erroTemperaturaAtual, seterroTemperaturaAtual] = useState(false)
    const [validaVelocidade, setvalidaVelocidade] = useState(false)
    const [erroSementeMetro, seterroSementeMetro] = useState(false)
    const [erroPressaoAtual, seterroPressaoAtual] = useState(false)
    const [diftime, setdiftime] = useState(null)
    const [marker, setMarker] = useState([])
    const [linhas, setLinhas] = useState([])
    const [centerMap, setcenterMap] = useState(null)
    const [progress, setProgress] = useState(0);
    const [histLinha, setHistLinha] = useState([])
    const [histPulv, setHistPulv] = useState([])
    const [histPlantio, setHistPlantio] = useState([])
    const [histCoords, setHistCoords] = useState([]);
    const [histPlantioInfo, setHistPlantioInfo] = useState([]);
    const [histPulvInfo, setHistPulvInfo] = useState([]);
    const [histCoordsInfo, setHistCoordsInfo] = useState([]);
    const [geralLinhasInfo, setGeralLinhasInfo] = useState([]);
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const limitRows = 2000;
    const reportData = [
        { histLinha: histLinha },
        { histPulv: histPulv },
        { histPlantio: histPlantio },
        { histCoords: histCoords },
    ]

    useEffect(() => {
        if (item) {
            settrabalho(item);
        } else {
            settrabalho(null)
        }
    }, [item])

    useEffect(() => {
        if (trabalho) {
            console.log('trabalho', JSON.stringify(trabalho));
            bindData(trabalho);
            getHistCoords(trabalho);
            getHistPlantio(trabalho);
            getLastPonto(trabalho);
            getHistlinhaInfo(trabalho);
            getHistLinha(trabalho);
            getHistPulv(trabalho);
            getDataInfo(trabalho);
        } else {
            console.log('Sem trabalho');
        }
    }, [trabalho]);

    const getDataInfo = async (trabalho) => {
        const obj = await AuthBuildHeaderUserObject();
        try {
            // HistPlantioInfo
            const histPlantioHeaders = {
                ...obj,
                trabalho_id: trabalho.id,
            };
            const histPlantioResponse = await APIManager.send('/trabalho/histplantio/info', 'GET', histPlantioHeaders);
            const histPlantioJson = await histPlantioResponse.json();
            console.log('Dados Histórico de plantio/info: ', histPlantioJson);
            setHistPlantioInfo(histPlantioJson);

            // HistPulvInfo
            const histPulvHeaders = {
                ...obj,
                trabalho_id: trabalho.id,
            };
            const histPulvResponse = await APIManager.send('/trabalho/histpulv/info', 'GET', histPulvHeaders);
            const histPulvJson = await histPulvResponse.json();
            console.log('Dados Histórico de pulv/info: ', histPulvJson);
            setHistPulvInfo(histPulvJson);

            // HistCoordsInfo
            const histCoordsHeaders = {
                ...obj,
                trabalho_id: trabalho.id,
            };
            const histCoordsResponse = await APIManager.send('/trabalho/histcoords/info', 'GET', histCoordsHeaders);
            const histCoordsJson = await histCoordsResponse.json();
            console.log('Dados Histórico de coords/info: ', histCoordsJson);
            setHistCoordsInfo(histCoordsJson);


            // GeralLinhasInfo
            const geralLinhasHeaders = {
                ...obj,
                trabalho_id: trabalho.id,
                numero: 1,
            };
            const geralLinhasResponse = await APIManager.send('/trabalho/geraldatalinhas', 'GET', geralLinhasHeaders);
            const geralLinhasJson = await geralLinhasResponse.json();
            console.log('Dados Histórico de geral/linhas: ', geralLinhasJson);
            setGeralLinhasInfo(geralLinhasJson);

            setProgress(prevProgress => prevProgress + 12.5);

        } catch (error) {
            console.log('Erro ao carregar informações sobre o serviço:', error);
        }
    };

    const getLastPonto = async (trabalho) => {
        try {
            const obj = await AuthBuildHeaderUserObject();
            const headers = {
                ...obj,
                trabalho_id: trabalho.id,
            };

            const response = await APIManager.send('/trabalho/lastponto', 'GET', headers);
            const json = await response.json();
            console.log('Dados LastPonto: ', json)
            setProgress(prevProgress => prevProgress + 12.5);
        } catch (error) {
            console.error('Erro ao carregar dados de lastPonto:', error);
        }
    }

    const getHistPlantio = async (trabalho, historic = [], iniDate = trabalho.data_inicio) => {
        const trabalhoId = trabalho.id;

        try {
            const obj = await AuthBuildHeaderUserObject();
            const headers = {
                ...obj,
                trabalho_id: trabalhoId,
                limit_rows: limitRows,
                ini_date: iniDate,
                numero: 4, // Número da requisição
            };

            const response = await APIManager.send('/trabalho/histplantio', 'GET', headers);
            const newHistplantio = await response.json();

            // Função que concatena os novos dados aos dados históricos já recuperados
            const updatedHistoric = [...historic, ...newHistplantio];

            // Atualiza o estado com os novos dados
            setHistPlantio(updatedHistoric);


            // Se a resposta não estiver vazia, continua buscando mais dados
            if (newHistplantio.length === limitRows) {
                const lastDate = newHistplantio[newHistplantio.length - 1].data;
                getHistPlantio(trabalho, updatedHistoric, lastDate);
            }

            setProgress(prevProgress => prevProgress + 12.5);

        } catch (error) {
            console.error('Erro ao carregar dados da API:', error);
        }
    };


    const getHistlinhaInfo = async (trabalho) => {
        try {
            const obj = await AuthBuildHeaderUserObject();
            const headers = {
                ...obj,
                trabalho_id: trabalho.id,
                numero: 1,
            };
            const response = await APIManager.send('/trabalho/histlinha/info', 'GET', headers);
            const json = await response.json();
            console.log('Dados Histórico de linha/info: ', json)
            setProgress(prevProgress => prevProgress + 12.5);
        } catch (error) {
            console.log('Erro ao carregar dados linha/info:', error);
        }
    }

    const getHistLinha = async (trabalho) => {
        const trabalhoId = trabalho.id;
        const ini_date = trabalho.data_inicio;
        try {
            const obj = await AuthBuildHeaderUserObject();
            const headers = {
                ...obj,
                trabalho_id: trabalhoId,
                limit_rows: limitRows,
                ini_date: ini_date,
                numero: 4,
            };
            const response = await APIManager.send('/trabalho/histlinha', 'GET', headers);
            const histlinha = await response.json();
            setHistLinha(histlinha)
            setProgress(prevProgress => prevProgress + 12.5);
        } catch (error) {
            console.log('Erro ao carregar dados linha:', error);
        }
    }

    function goBack() {
        history.push({
            pathname: '/works',
        });
        window.location.reload();
    }

    const getHistPulv = async (trabalho) => {
        const ini_date = trabalho.data_inicio;

        try {
            const obj = await AuthBuildHeaderUserObject();
            const headers = {
                ...obj,
                trabalho_id: trabalho.id,
                limit_rows: limitRows,
                ini_date: ini_date,
            };
            const response = await APIManager.send('/trabalho/histpulv', 'GET', headers);
            const histpulv = await response.json();
            setHistPulv(histpulv)
            setProgress(prevProgress => prevProgress + 12.5);
        } catch (error) {
            console.log('Erro ao carregar dados pulv:', error);
        }
    }

    const getHistCoords = (trabalho) => {
        const trabalhoId = trabalho.id;

        const fetchData = async () => {
            try {
                const obj = await AuthBuildHeaderUserObject();
                const headers = {
                    ...obj,
                    trabalho_id: trabalhoId,
                };

                const response = await APIManager.send('/trabalho/histcoords', 'GET', headers);
                const histcoords = await response.json();
                setHistCoords(histcoords)
                polilinhas(histcoords);
                setProgress(prevProgress => prevProgress + 12.5);
            } catch (error) {
                console.error('Erro ao carregar dados da API:', error);
            }
        };

        fetchData();
    };

    const polilinhas = (trabalho) => {


        // Extrai as coordenadas (latitude e longitude) de cada objeto no array
        const coords = trabalho.map(item => ({
            path: {
                lat: item.lat,
                lng: item.lng
            },
        }));


        const lastPonto = {
            latitude: trabalho[trabalho.length - 1].lat,
            longitude: trabalho[trabalho.length - 1].lng,
            coordinateType: 'last',
        }
        const firstPonto = {
            latitude: trabalho[0].lat,
            longitude: trabalho[0].lng,
            coordinateType: 'first',
        }

        let markers = [lastPonto, firstPonto]
        const centerMap = markers.length > 0 ? { lat: markers[markers.length - 1].latitude, lng: markers[markers.length - 1].longitude } : null;

        setMarker(markers);
        setLinhas(coords);
        setcenterMap(centerMap);

    }

    const bindData = (trabalho) => {
        //Dados Gerais
        let op = ' - - '
        if (trabalho.operador) { op = trabalho.operador }
        setoperador(op)

        let talh = ' - - '
        if (trabalho.talhao) { talh = trabalho.talhao }
        settalhao(talh)

        let vel = ' - - '
        if (trabalho.coordenada) { vel = trabalho.coordenada.velocidade_antena_kmh.toFixed(1) + ' km/h' } //condicional de velocidade
        setvelocidadeMedia(vel)

        setvalidaVelocidade(WorkGetErrorVelocidade(trabalho))

        let dist = ' - - '
        if (trabalho.pulv && trabalho.pulv.pulv_ponto) {
            dist = trabalho.pulv.pulv_ponto.metros_percorridos.toFixed(2) + ` m`  //condicional de distancia percorrida
        } else {
            dist = 0.00 + ` m `
        }
        setdistanciaPercorrida(dist)

        ////Dados de Plantio
        if (trabalho.plantio && trabalho.plantio.plantio_ponto) {
            let areaPlant = ' 0,00 ha'
            if (trabalho.plantio) { areaPlant = trabalho.plantio.plantio_ponto.areaplantada_ha + ` ha` }  //condicional de area plantada
            setareaPlantada(areaPlant)

            let sementesM = ' - - '
            if (trabalho.plantio) { sementesM = trabalho.plantio.plantio_ponto.sementes_metro_total.toFixed(2) + ` s/m` }  //condicional de sementes por metro
            setsementesMetro(sementesM)

            seterroSementeMetro(WorkGetErrorSeedsMeter(trabalho))

            let sementesH = ' - - '
            if (trabalho.plantio) { sementesH = trabalho.plantio.plantio_ponto.sementes_hectare_total.toFixed(2) + ` s/ha` }  //condicional de sementes por hectare
            setsementesHectare(sementesH)

            let populacaoT = ' - - '
            if (trabalho.plantio) { populacaoT = trabalho.plantio.plantio_ponto.populacao.toFixed(2) + ` ppl` }   //condicional de populacao total
            setpopulacaoTotal(populacaoT)

            let populacaoM = ' - - '
            if (trabalho.plantio) { populacaoM = trabalho.plantio.plantio_ponto.populacao_media_linha + ` ppl/ha` }  //condicional de populacao media
            setpopulacaoMedia(populacaoM)

            let linhasPlant = ' - - '
            if (trabalho.plantio) { linhasPlant = trabalho.plantio.plantio_ponto.linhas.length }  //condicional de quantidade de linhas da plantadeira
            setlinhasPlantadeira(linhasPlant)
        }

        ////pulverizacao
        if (trabalho.pulv && trabalho.pulv.pulv_ponto) {
            let areaPulv = ' - - '
            if (trabalho.pulv) { areaPulv = trabalho.pulv.pulv_ponto.area_pulverizada + ` ha` }  //condicional de area pulverizada
            setareaPulverizada(areaPulv)

            let pressao = ' - - '
            if (trabalho.pulv) { pressao = trabalho.pulv.pulv_ponto.pressao_atual_psi.toFixed(2) + ` psi` } //condicional de pressao atual
            setpressaoAtual(pressao)

            seterroPressaoAtual(WorkGetErrotCurrentPressure(trabalho))

            let temperatura = ' - - '
            if (trabalho.pulv) { temperatura = trabalho.pulv.pulv_ponto.temperatura + ` ºC` }  //condicional de temperatura atual
            settemperaturaAtual(temperatura)

            seterroTemperaturaAtual(WorkGetErrorTemperature(trabalho))

            let litro = ' - - '
            if (trabalho.pulv) { litro = trabalho.pulv.pulv_ponto.litros_consumidos.toFixed(2) + ` L` } //condicional de litros Consumidos
            setlitrosConsumidos(litro)
        }

        let maxTemp = ' - - '
        if (trabalho.pulv) { maxTemp = trabalho.pulv.config_temp_max }  //condicional de temperatura maxima
        setconfigTemperaturaMax(maxTemp)

        let PressaoMinPsi = ' - - '
        if (trabalho.pulv) { PressaoMinPsi = trabalho.pulv.config_pressao_min_psi }  //condicional de pressao minima
        setpressaoMinPsi(PressaoMinPsi)

        let PressaoMaxPsi = ' - - '
        if (trabalho.pulv) { PressaoMaxPsi = trabalho.pulv.config_pressao_max_psi }  //condicional de pressao maxima
        setpressaoMaxPsi(PressaoMaxPsi)

        let TaxaSementeMetroMax = ' - - '
        if (trabalho.plantio) { TaxaSementeMetroMax = trabalho.plantio.config_sementesmetro_max }  //condicional de taxa de sementes por metro
        settaxaSementesMetroMax(TaxaSementeMetroMax)

        let TaxaSementeMetroMin = ' - - '
        if (trabalho.plantio) { TaxaSementeMetroMin = trabalho.plantio.config_sementesmetro_min }  //condicional de taxa de sementes por metro
        settaxaSementesMetroMin(TaxaSementeMetroMin)

        let TaxaSementeMetro = ' - - '
        if (trabalho.plantio) { TaxaSementeMetro = trabalho.plantio.config_sementesmetro }  //condicional de taxa de sementes por metro
        settaxaSementesMetro(TaxaSementeMetro)

        let AntenaAlturaM = ' - - '
        if (trabalho.antena_altura_m) { AntenaAlturaM = trabalho.antena_altura_m }  //condicional de taxa de sementes por metro
        setantemaAlturaM(AntenaAlturaM)

        let EspacamentoLinhas = ' - - '
        if (trabalho.plantio) { EspacamentoLinhas = trabalho.plantio.config_espacamento_cm }  //condicional de taxa de sementes por metro
        setespecamentoLinhas(EspacamentoLinhas)

        let VelocidadeMaxPermitida = ' - - '
        if (trabalho.plantio) { VelocidadeMaxPermitida = trabalho.plantio.config_vel_kmh_max }  //condicional de taxa de sementes por metro
        setvelocidadeMaxPermitida(VelocidadeMaxPermitida)


        // Diferença de tempo entre a data do trabalho ea data atual para verificar o status do trabalho    
        let now = new Date()
        let date = new Date(trabalho.data_update_server)
        let dif = now.getTime() - date.getTime()
        dif = dif / 1000 / 60
        setdiftime(dif)

        //Horas Trabalhadas
        //#TODO: Ver como vai por plantio_ponto neste condicional pois quando é colocado dá como undefined mesmo exustindo a propiedade no obj

        if (trabalho.plantio && trabalho.plantio.plantio_ponto) {

            let milliseconds = trabalho.plantio.plantio_ponto.millsec_trabalhados
            let horas = milliseconds / 1000 / 60 / 60
            let minutos = horas - Math.floor(horas)
            minutos = minutos * 60

            sethorasTrabalhadas(Math.floor(horas) + 'h' + Math.floor(minutos) + 'min')
        }


        //Vazão
        if (trabalho.pulv && trabalho.pulv.pulv_ponto) {
            setvazaoAlvo(trabalho.pulv.pulv_ponto.vazao_geral_lha)
            setvazaoAtual(trabalho.pulv.pulv_ponto.vazao_atual_lha)
            setvazaoAtualMin(trabalho.pulv.pulv_ponto.vazao_atual_lmin)
        } else {
            setvazaoAlvo('----')
            setvazaoAtual('----')
            setvazaoAtualMin('----')
        }

        setProgress(prevProgress => prevProgress + 12.5);
    }



    return (
        <>
            <div style={{ flex: 1 }}>
                {progress < 100 ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#1a1a1a', color: '#fff' }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>Carregando dados do trabalho...</Typography>
                        <Box sx={{ width: '50%', mb: 2 }}>
                            <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 5, backgroundColor: '#333', '& .MuiLinearProgress-bar': { backgroundColor: '#F8C102' } }} />
                        </Box>
                        <Typography variant="body1">{progress}%</Typography>
                    </Box>
                ) : (
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: isMobile ? '500px' : '100%' }}>
                        <IconButton
                            onClick={() => { goBack() }}
                            style={{
                                color: '#F8C102',
                                margin: '20px 0',
                                alignSelf: 'start',
                                padding: '10px',
                                backgroundColor: '#333',
                                borderRadius: '50%',
                                transition: 'background-color 0.3s ease',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#555'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#333'}
                        >
                            <ArrowBackRounded />
                        </IconButton>

                        {trabalho && (
                            <div style={{ flexDirection: 'column' }}>
                                <BasicCard style={{ marginBottom: values.margin[2], backgroundColor: '#282828', color: '#fff' }}>
                                    <Typography style={{ marginBottom: values.margin[1], color: '#F8C102' }}>{strings.getString('screen_monitorItem_general_settings')}</Typography>
                                    <div style={{ padding: 5, margin: 5, justifyContent: 'space-between', color: '#fff' }}>
                                        <TextField label={strings.getString('screen_monitorItem_date_initial')} style={{ margin: 5 }} value={DateFormat(new Date(trabalho.data_inicio))} InputProps={{ startAdornment: (<InputAdornment position="start"><DateRangeIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('screen_monitorItem_speed_actual')} style={{ margin: 5 }} error={validaVelocidade} value={velocidadeMedia} InputProps={{ startAdornment: (<InputAdornment position="start"><SpeedIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('screen_monitorItem_hours_work')} style={{ margin: 5 }} value={horasTrabalhadas} InputProps={{ startAdornment: (<InputAdornment position="start"><AccessTimeIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('screen_monitorItem_distance_traveled')} style={{ margin: 5 }} value={distanciaPercorrida} InputProps={{ startAdornment: (<InputAdornment position="start"><TrendingUpIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('info_operator')} style={{ margin: 5 }} value={operador} InputProps={{ startAdornment: (<InputAdornment position="start"><PersonIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('unity_talhao')} style={{ margin: 5 }} value={talhao} InputProps={{ startAdornment: (<InputAdornment position="start"><MapIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('screen_monitorItem_number_lines')} style={{ margin: 5 }} value={linhasPlantadeiraMedia} InputProps={{ startAdornment: (<InputAdornment position="start"><ViewHeadlineIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('screen_monitorItem_antenna_height')} style={{ margin: 5 }} value={antemaAlturaM} InputProps={{ startAdornment: (<InputAdornment position="start"><VerticalAlignTopIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('screen_monitorItem_line_spacing')} style={{ margin: 5 }} value={especamentoLinhas} InputProps={{ startAdornment: (<InputAdornment position="start"><ExpandIcon /></InputAdornment>), }} variant="standard" />
                                        <TextField label={strings.getString('warn_speed_max_permited')} style={{ margin: 5 }} value={velocidadeMaxPermitida} InputProps={{ startAdornment: (<InputAdornment position="start"><ReportIcon /></InputAdornment>), }} variant="standard" />
                                    </div>
                                </BasicCard>
                                {trabalho.plantio && trabalho.plantio.plantio_ponto && (
                                    <BasicCard style={{ marginBottom: values.margin[2], backgroundColor: '#282828', color: '#fff' }} title={strings.getString('screen_monitorItem_planting_data')}>
                                        <div style={{ padding: 5, margin: 5, justifyContent: 'space-around', color: '#fff' }}>
                                            <TextField label={strings.getString('unity_area')} style={{ margin: 5 }} value={areaPlantada} InputProps={{ startAdornment: (<InputAdornment position="start"><DateRangeIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_seeds_meter')} style={{ margin: 5 }} error={erroSementeMetro} value={sementesMetro} InputProps={{ startAdornment: (<InputAdornment position="start"><SpeedIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_seeds_hectare')} style={{ margin: 5 }} value={sementesHectare} InputProps={{ startAdornment: (<InputAdornment position="start"><AccessTimeIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_total_population')} style={{ margin: 5 }} value={populacaoTotal} InputProps={{ startAdornment: (<InputAdornment position="start"><TrendingUpIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_average_population_lines')} style={{ margin: 5 }} value={populacaoMedia} InputProps={{ startAdornment: (<InputAdornment position="start"><PersonIcon /></InputAdornment>), }} variant="standard" />

                                            <Typography style={{ marginTop: values.margin[1], color: '#F8C102' }}>{strings.getString('screen_monitorItem_planting_settings')}</Typography>
                                            <TextField label={strings.getString('screen_monitorItem_maximum_seed_rate')} style={{ margin: 5 }} value={taxaSementesMetroMax} InputProps={{ startAdornment: (<InputAdornment position="start"><ArrowUpwardIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_seed_target_rate')} style={{ margin: 5 }} value={taxaSementesMetro} InputProps={{ startAdornment: (<InputAdornment position="start"><MapIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_minimum_seed_rate')} style={{ margin: 5 }} value={taxaSementesMetroMin} InputProps={{ startAdornment: (<InputAdornment position="start"><ViewHeadlineIcon /></InputAdornment>), }} variant="standard" />
                                        </div>
                                    </BasicCard>
                                )}
                                {trabalho.pulv && trabalho.pulv.pulv_ponto && (
                                    <BasicCard style={{ marginBottom: values.margin[2], backgroundColor: '#282828', color: '#fff' }}>
                                        <Typography style={{ marginBottom: values.margin[1], color: '#F8C102' }}>{strings.getString('screen_monitorItem_spray_data')}</Typography>
                                        <div style={{ padding: 5, margin: 5, justifyContent: 'space-around', color: '#fff' }}>
                                            <TextField label={strings.getString('unity_area_pulverized')} style={{ margin: 5 }} value={areaPulverizada} InputProps={{ startAdornment: (<InputAdornment position="start"><RoomIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_current_pressure')} style={{ margin: 5 }} value={pressaoAtual} error={erroPressaoAtual} InputProps={{ startAdornment: (<InputAdornment position="start"><TimelineIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('unity_target_flow')} style={{ margin: 5 }} value={vazaoAlvo} InputProps={{ startAdornment: (<InputAdornment position="start"><BrokenImageIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_current_flow')} style={{ margin: 5 }} value={vazaoAtual} InputProps={{ startAdornment: (<InputAdornment position="start"><InvertColorsIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_current_flow_min')} style={{ margin: 5 }} value={vazaoAtualMin} InputProps={{ startAdornment: (<InputAdornment position="start"><InvertColorsIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('unity_liters_consumed')} style={{ margin: 5 }} value={litrosConsumidos} InputProps={{ startAdornment: (<InputAdornment position="start"><LocalDrinkIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_current_temperature')} style={{ margin: 5 }} value={temperaturaAtual} error={erroTemperaturaAtual} InputProps={{ startAdornment: (<InputAdornment position="start"><ThermostatIcon /></InputAdornment>), }} variant="standard" />

                                            <Typography style={{ marginTop: values.margin[1], color: '#F8C102' }}>{strings.getString('screen_monitorItem_spray_settings')}</Typography>
                                            <TextField label={strings.getString('screen_monitorItem_pressure_max')} style={{ margin: 5 }} value={pressaoMaxPsi} InputProps={{ startAdornment: (<InputAdornment position="start"><ArrowUpwardIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_pressure_min')} style={{ margin: 5 }} value={pressaoMinPsi} InputProps={{ startAdornment: (<InputAdornment position="start"><ArrowDownwardIcon /></InputAdornment>), }} variant="standard" />
                                            <TextField label={strings.getString('screen_monitorItem_temperature_max')} style={{ margin: 5 }} value={configTemperaturaMax} InputProps={{ startAdornment: (<InputAdornment position="start"><WarningAmberIcon /></InputAdornment>), }} variant="standard" />
                                        </div>
                                    </BasicCard>
                                )}

                                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', marginBottom: values.margin[2] }}>
                                    {trabalho.plantio && trabalho.plantio.plantio_ponto && (
                                        <BasicCard style={{ width: isMobile ? '100%' : '68%', height: 380, marginBottom: isMobile ? values.margin[2] : '0', backgroundColor: '#282828', color: '#fff' }}>
                                            <MonitorChartLimits
                                                linhas={trabalho.plantio.plantio_ponto.linhas}
                                                item={trabalho.plantio}
                                                style={{ width: '100%', justifyContent: 'center' }} />
                                        </BasicCard>
                                    )}
                                    {trabalho.pulv && trabalho.pulv.pulv_ponto && (
                                        <BasicCard style={{ width: isMobile ? '100%' : '31%', marginLeft: isMobile ? '0' : values.margin[1], backgroundColor: '#282828', color: '#fff' }} title={`${strings.getString('screen_monitorItem_section_indicator')} - ( + ${trabalho.pulv.pulv_ponto.sessoes.length})`}>
                                            <WorksPulvSectionDisplay items={trabalho.pulv.pulv_ponto.sessoes} />
                                        </BasicCard>
                                    )}
                                </div>

                                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <div style={{ flexDirection: 'row', width: isMobile ? '100%' : '48%', marginRight: isMobile ? '0' : values.margin[2], marginBottom: isMobile ? values.margin[2] : '0' }}>
                                        <BasicCard>
                                            <ReportsGraph data={reportData} style={{ width: '100%', backgroundColor: '#282828', color: '#fff' }} />
                                        </BasicCard>
                                    </div>
                                    <div style={{ flex: 1, width: isMobile ? '100%' : '50%', marginLeft: isMobile ? '0' : values.margin[2] }}>
                                        <BasicCard>
                                            <ReportsMaps data={reportData} style={{ width: isMobile ? '480px' : '100%', backgroundColor: '#282828', color: '#fff' }} />
                                        </BasicCard>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!trabalho && progress === 0 && (
                            <ErrorCard
                                page='works'
                                title='Impossível encontrar o registro'
                                message='Não foi possível encontrar o trabalho, se o erro persistir entre em contato com o nosso suporte'
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
export default CliWorkdetails;

/*  console.log('tb.plantio.plantio_ponto.millsec_trabalhados ',tb.plantio.plantio_ponto.millsec_trabalhados )
            let minutos =  (tb.plantio.plantio_ponto.millsec_trabalhados / 1000) / 60;
            let horas = minutos / 60;
            let horasTrabalhadas = horas.toFixed(2) + ` h`
            sethorasTrabalhadas(horasTrabalhadas)
           // horas = horas.getHours() + ':' + horas.getMinutes()
           // sethorasTrabalhadas((horas)) //#TODO: verificar se é correto arredondar */

/*
 transforma o mili em minutos, e arrendonda pra baixo
     ex: 35468469 mili
     min: 591,14115
     floor: 591
     horas: 591/60 = 9,85
     arredonda pra baixo: 9
     horas: 9 minutos: 591
     minutos: 591 - 9HORAS (540min)
     minutos 51min
     9h:51min
 transforma em horas, desconta o decimal e converte o decimal em minutos (arrendondando para baixo)
 ex: 35468469 mili
 horas: 9,85
 arrendonda pra baixo: 9
 minutos = 9,85 - 9
 minutos = 0,85
 minutos = 0,85 * 60 = 51
 9h:51min
*/


