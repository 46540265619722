/*
USE CASE: WorkGetErrorSeedsMeter
Desc: Compara os valores do objeto para determinar se está com erro de sementes
params: 
   - item: objeto no padrão da API que representa um trabalho
returns:
  - True caso tenha erro de sementes por metro, False caso esteja tudo OK ou os parametros forem inválidos
*/

import strings from "../values/strings/strings"

const WorkGetErrorSeedsMeter = (item) => {

    if(!item || 
        !item.plantio || 
        !item.plantio.config_sementesmetro ||
        !item.plantio.plantio_ponto ||
        !item.plantio.plantio_ponto.sementes_metro_total
    ) return null

    let message = null
    
    if (item.plantio.plantio_ponto.sementes_metro_total > item.plantio.config_sementesmetro) return message = `${strings.getString('WorkGetErrorSeedsMeter_seeds_meter_exceed')} `
    if (item.plantio.plantio_ponto.sementes_metro_total < item.plantio.config_sementesmetro) return null
   
    return message
   
}
export default WorkGetErrorSeedsMeter;

