import CacheClearAll from "./CacheClearAll";

/*
USE CASE: AuthPerformLogout
Desc: Perform the logout process, deleting the cache data
params: 
   - NONE
returns:
  - A promise that: execute the use case CacheClearAll and resolve
*/

const AuthPerformLogout = () => {
    return new Promise(async (resolve, reject) => {
        await CacheClearAll()
        resolve()
    })
}
export default AuthPerformLogout;