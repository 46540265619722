import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React from 'react';

function ProjectSwitch(props) {
    return (
        <FormGroup style={{...props.style}}>
            <FormControlLabel label={props.label ? props.label : ""} color={props.color} 
                control={
                    <Switch size={props.size} checked={props.checked} onChange={props.onChange}/>
                }
            />
        </FormGroup>
    );
}

export default ProjectSwitch;