import React from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';
import MyAcount from '../components/pagescomponents/MyAcount';

function Profile(props) {
    return (
        <DashboardFrame >
            <MyAcount />
        </DashboardFrame>
        //Congigurações de conta do usuário
        //Editar informações da conta:
            //Nome
            //Senha
            //Telefone
        
    );
}

export default Profile;