import APIManager from "../connection/APIManager";
import AuthBuildHeaderUserObject from "./AuthBuildHeaderUserObject";

/*
USE CASE: WorksMonitorDeleteItem
Desc: Caso de uso responsável por deletar um item da api no servidor, 
devolvendo resolve caso o item seja excluido com sucesso e reject caso ocorra algum erro.
params: 
   - item: Recebe um item especifico de um determinado array para que seja feito o delete desse determinado item.
returns:
  - Uma promise que retorna resolve caso o item seja excluido com sucesso e reject caso ocorra algum erro.
*/
const WorksMonitorDeleteItem = (item) => {
    return new Promise( async (resolve, reject) => {
      if (!item || !item.id) {
        return reject('Item não definido')
      }

      let headers = await AuthBuildHeaderUserObject().catch(e => {return null})

      if(!headers) {
        return reject('Falha ao obter dados do usuário logado')
      }

      headers['trabalho_id'] = item.id

      APIManager.send("/trabalho/resumo", "DELETE", headers)
      .then(res => {
        return resolve()
      }).catch(e => {
        return reject()
      })
    })
}
export default WorksMonitorDeleteItem;

