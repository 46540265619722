import React from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';
import Equipament from '../components/pagescomponents/Equipament'

function Equipments(props) {
    return (
        <DashboardFrame title={strings.getString('screen_equipments_title')}>
        <Equipament/>
        </DashboardFrame>
    );
}

export default Equipments;