import { Typography } from '@mui/material';
import React from 'react';
import ProjectTable from '../basic/ProjectTable';
import ChartTest from '../basic/ChartTest';

function DashBoardAdv(props) {
    return (
        <div>
            
        </div>
    );
}

export default DashBoardAdv;