import APIManager from "../connection/APIManager"

/*
USE CASE: AuthChangePasswordToken
Desc: irá receber o token e fazer a checagem no servidor se for valido resolve() caso contrario reject()
params: 
   - token: string que contem o token do servidor para comparara com este este token do usuário
returns:
  - Uma promise que:  
    resolve() se o token for válido
    reject() se o token for inválido
*/
const AuthChangePasswordToken = (token) => {
    return new Promise((resolve, reject) => {        
        if(!token || token === "") return reject()

        let headers = {
            token: token
        }

        APIManager.send("/changepass","GET",headers).then(res => {
            return resolve()
        }).catch(e => {
            console.log(e)
            return reject()
        })
    })
}
export default AuthChangePasswordToken;