/*
USE CASE: WorkGetErrorTemperature
Desc: Compara os valores do objeto para determinar se a temperatura está acima do permitido ou não
params: 
   - item: objeto no padrão da API que representa um trabalho
returns:
  - True caso tenha erro de velocidade, False caso esteja tudo OK ou os parametros forem inválidos
*/

import strings from '../values/strings/strings'

const WorkGetErrorTemperature = (item) => {

    if(!item || !item.pulv || !item.pulv.pulv_ponto) return null
    
    let message = null
    
    if (item.pulv.pulv_ponto.temperatura < item.pulv.config_temp_max) return  message = false //`${strings.getString('WorkGetErrorTemperature_temperature_permited')} `    //#TODO - Add em strings
    
    if (item.pulv.pulv_ponto.temperatura > item.pulv.config_temp_max) return  message = `${strings.getString('WorkGetErrorTemperature_temperature_max_exee')} `    //#TODO - Add em strings

    return message

}
export default WorkGetErrorTemperature;