/*
USE CASE: UserUnlock
Desc: Retorna do servidor o status do usuário 200 
params: 
   - item: objeto com os dados do usuário a ser verificado
returns:
  - Uma promise que retorna resolve para autorizado 
  
*/
import AuthBuildHeaderUserObject from './AuthBuildHeaderUserObject';
import APIManager from '../connection/APIManager';

const UserUnlock = (item) => {
  return new Promise( async (resolve, reject) => { 
    
    let header = await AuthBuildHeaderUserObject().catch(e => {return null})
    if(!header) {
      reject('Falha ao obter dados do usuário logado')
      return;
    } 
    
    let headers = {
      ...header,      
    }

    let body = {
      obj: item
    }

  APIManager.send("/usuarios", "PUT", headers, body)
  .then(res => {
    if (!res.ok) {
        throw new Error(`Erro na requisição: Status ${res.status}`);
    }
    if (res.status === 200 && res.headers.get('Content-Type')?.includes('application/json')) {
        return res.json();
    } else {
        return res.text();
    }
    })
    .then(json => {
      resolve(json);
    })
    .catch(e => {
      if (e instanceof Error) {
        // Erro de programação ou rede
        console.log('UserUnlock - error', e.message);
        reject(e.message);
      } else if (e instanceof Response) {
        // Erro HTTP, tenta ler a resposta para mais detalhes
        e.text().then(text => {
          console.log('UserUnlock - HTTP error', e.status, text);
          reject(text);
        });
      } else {
        // Outro tipo de erro
        console.log('UserUnlock - unexpected error', e);
        reject(e);
      }
    })
  
})
}
export default UserUnlock;