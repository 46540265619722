import CacheKeys from "../models/CacheKeys";

/*
USE CASE: CacheClearAll
Desc: Clear all cache keys of the application
params: 
   - NONE
returns:
  - A promise that: runs a loop through the CacheKeys and remove them of the localStorage, then resolve
*/

const CacheClearAll = () => {
    return new Promise(async (resolve, reject) => {
        const keys = Object.keys(CacheKeys)
        keys.forEach(v => {
            localStorage.removeItem(v)
        })
        resolve()
    })
}
export default CacheClearAll;