import React from 'react';

/*
NAME: RowContainer
DESC: Uma div com o CSS padrão para servir de container com orientação horizontal para os componentes filhos.
props:
- - - - Default
- style: Object, style object to overwrite this component style
- children: Component, renders component childs
NOTES:
*/

function RowContainer({styles, children}) {
    return (
        <div style={{display: 'flex', flexWrap: 'wrap', ...styles}}>
            {children}
        </div>
    );
}

export default RowContainer;