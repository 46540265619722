/*
NAME: EquipamentChartModels
DESC: Componente responsavel por processar os dados do array de equipamentos e enviar 
para seu componente filho o modelo e quantidade de equipamentos de cada modelo
EX: {text: "SM4", value: "4"} {text: "GPS", value: "2"}
props:
- items: Array de objetos que representa a lista de equipamentos, conforme recebe da API
- title: Titulo do grafico
- - - - Default
NOTES:
 - #TODO - Deve ser adicionado no JSON de equipamentos o modelo e a quantidade de equipamentos de cada modelo
*/
import React, { useState, useEffect } from "react";
import ChartTotal from '../basic/ChartTotal'
function EquipamentChartModels({items, title}) {
  
   const [equipamentos, setequipamentos] = useState([]);
   const [chartValues, setChartValues] = useState([])
   
   useEffect(() => {
      if(items && items.length > 0){
         setequipamentos(items)
         bindData(items)    
          
      }
   }, [items])

   const bindData = (arr) => {
      let newChartValues = []
      let apps = new Map ()
      
      arr.forEach((item, i) => {
         //App pode ser uma string valida ou undefined/null
         let app = item.app ? item.app : 'SM4'
         
         if(!apps.has(app)){ //Entrando pela primeira vez na lista
            apps.set(app, 1) //Adicionando o primeiro item
         } else {
            apps.set(app, apps.get(app) + 1) //Incrementando o valor
         }         
      })

      newChartValues = [...apps.entries()].map((item, i) => {
         return {
            text: item[0],
            value: item[1]
         }
      })
     
      console.log('newChart', newChartValues)

      setChartValues(newChartValues)      
   }
   
   return (
    
         <ChartTotal title={title} items={chartValues} total={equipamentos.length} width={450}/>
     
 

   )
}

export default EquipamentChartModels;