import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Build, Devices, DonutLarge, ExitToApp, People, Person, RecentActors, Reorder, Settings, SettingsApplications, Visibility } from '@mui/icons-material';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import strings from '../../values/strings/strings'
import Pages from '../../pages/Pages';
import Systemconfigs from '../pagescomponents/Systemconfigs';

function DashboardDrawerList(props) {
    const history = useHistory()
    const [configsOpen, setConfigsOpen] = useState(false);

    const openSystemconfis = () => {
        setConfigsOpen(true);
    }
    const closeSystemconfigs = () => {
        setConfigsOpen(false);
    }

    return (
        <div>
            <List>
                <ListItem button onClick={() => { history.push(Pages.routes.Dashboard) }} >
                    <ListItemIcon><DonutLarge color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_dashboard_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Monitor) }} >
                    <ListItemIcon><Visibility color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_monitor_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Works) }} >
                    <ListItemIcon><Reorder color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('default_works_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Equipments) }} >
                    <ListItemIcon><Devices color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('default_equipments_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Customers) }} >
                    <ListItemIcon><RecentActors color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_customers_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Tools) }} >
                    <ListItemIcon><Build color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_tools_title')} />
                </ListItem>

                <br />
                <Divider />
                <br />
                <ListItem button onClick={() => { history.push(Pages.routes.Users) }} >
                    <ListItemIcon><People color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_users_title')} />
                </ListItem>
                <ListItem button onClick={openSystemconfis}>
                    <ListItemIcon><SettingsApplications color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_system_title')} />
                </ListItem>

                <br />
                <Divider />
                <br />

                <ListItem button onClick={() => { history.push(Pages.routes.Profile) }} >
                    <ListItemIcon><Person color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_profile_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Settings) }} >
                    <ListItemIcon><Settings color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_settings_title')} />
                </ListItem>
            </List>

            <Systemconfigs isOpen={configsOpen} onClose={closeSystemconfigs} />


            <ListItem button onClick={() => { history.push(Pages.routes.Logout) }} >
                <ListItemIcon><div style={{ transform: 'rotate(180dg)' }}><ExitToApp color="primary" /></div></ListItemIcon>
                <ListItemText primary={strings.getString('screen_log_out')} />
            </ListItem>
        </div>
    );
}

export default DashboardDrawerList;