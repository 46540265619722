/*
NAME: ProjectChart
DESC: componente padrão para renderizar graficos
props: 
- title: string, titulo do grafico
- onViewMore: #DEFINIR function, função para chamar a tela respectiva, 
- onRefresh: #DEFINIR function, função para atualizar o grafico
- #DEFINIR DEPOIS OUTRAS PROPRIEDADES
- - - - Default
- style: Object, style object to overwrite this component style
NOTES:
    implementar o grafico posteriormente, por agora apenas estruturar o componente
*/

import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Refresh from "./Refresh";
import ViewMore from "./ViewMore";
import values from '../../values/values';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import strings from "../../values/strings/strings";

function ProjectChartEquip(props) {
  
  const COLORS = ["#FCCB00", "#FF6384", "#36A2EB", "#4BC0C0"];

  const getFill = (index) => COLORS[index % COLORS.length];
  
  
  const data = [
    {
      "name": "Total de Equipamentos",
      "value": props.total
    },
    {
      "name": strings.getString('screen_equipament_blocked'),
      "value": props.items.equipamentosBloqueados
    },
    {
      "name": strings.getString('screen_equipament_connected'),
      "value": props.items.equipamentosConectados
    },
    {
      "name": strings.getString('screen_equipament_registred'),
      "value": props.items.equipamentosRegistrados
    }
  ];
  
  const cells = data.map((entry, index) => (
    <Cell key={`cell-${index}`} fill={getFill(index)} />
  ));

  return (
    <><Stack spacing={1} style={{ ...props.style }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {props.onViewMore && <ViewMore onClick={props.onViewMore} />}
        <Typography style={{ flex: 1, alignSelf: 'center', marginLeft: values.margin[3], marginRight: values.margin[3] }}>{'Informações sobre os Equipamentos'}</Typography>
        {props.onRefresh && <Refresh onClick={props.onRefresh} />}
      </div>
      <div style={{ height: 400, width: '100%', display:'flex' }}>
      <PieChart width={500} height={450}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            label
            align="left"
          >
            {cells}
          </Pie>
          {/*
          //TODO - ADICIONAR LEGENDA
          <Legend align="right" />*/}
          <Tooltip />
        </PieChart>
      </div>
    </Stack></>


  );
}

export default ProjectChartEquip;




