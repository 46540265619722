/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/*
NAME: EquipmentsList
DESC: Componente responsavel por renderizar a lista de equipamentos
props:
- items: Array de objetos que representa a lista de equipamentos
- usuarioLogado: Tipo de ususario logado
- loading: Boolean que indica se a lista esta carregando ou nao
- RowsPerPage: Quantidade de linhas por pagina da tabela que será exibida
-refresh: Função que vem de um componente pai para atualizar a lista de usuarios através de um get

- - - - Default

NOTES:

*/

import React, { useState, useEffect } from 'react';
import strings from '../../values/strings/strings';
import ProjectTable from '../basic/ProjectTable';
import { IconButton } from '@mui/material';
import { Info, KeyboardReturnSharp } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import Pages from '../../pages/Pages';
import DateFormat from '../../usecases/DateFormat';
import UserTypes from '../../models/UserTypes';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from '@mui/material/Tooltip';
import AgricultureIcon from '@mui/icons-material/Agriculture';

function EquipmentsDashboardList({ items, usuario, usuarioLogado, loading, RowsPerPage, refresh }) {

    const [headers, setheaders] = useState(['Info', "Status", `${strings.getString('info_name')}`, `${strings.getString('info_ultimate_registred')}`]);
    const [tableItems, setTableItems] = useState([])
    const [usuarioLogado1, setUsuarioLogado] = useState(null)
    const [status, setStatus] = useState([])


    const history = useHistory();

    useEffect(() => {

        if (usuarioLogado && usuarioLogado.tipo !== UserTypes.CLI && headers[2] !== "Usuário") {
            let headersNew = [...headers]
            headersNew = [...headers.slice(0, 2), ...headers.slice(2, headers.length)]
            setheaders(headersNew)
        }
        setUsuarioLogado(usuarioLogado)

    }, [usuarioLogado])

    useEffect(() => {
        if (items) {
            console.log('Status Equipament', JSON.stringify(items))
            let items1 = items
            let EquipamentTabela = bindData(items1)
            setTableItems(EquipamentTabela)
        }
    }, [items])

    function cadastroEquipamento(item) {
        history.push(Pages.routes.Equipments + "/" + item.id)
    }


    function redirectEquipament(item) {
        console.log("item selecionado", item)
        history.push(Pages.routes.Equipments + "/" + item.device_id)
    }

    function redirectMonitor(item) {
        history.push(Pages.routes.Monitor + "/" + item.id)
    }

    function zoom(item) {
        alert('Função Vazia')
    }

    function redirectUser(item) {
        history.push(Pages.routes.Users + "/" + item.usuario_id)
    }

    function infoWorks(item) {
        return <IconButton color="info" onClick={() => cadastroEquipamento(item)}><Info /></IconButton>
    }



    const bindData = (data) => {

        let binded = []

        binded = data.map((item, index) => {

            let obj = {}
            obj.info = <Tooltip title="Ver informações" >{infoWorks(item)}</Tooltip>
            obj.status = <Tooltip title="Mostar no mapa">{itemStatus(item)}</Tooltip>
            obj.nome = <Button onClick={() => redirectEquipament(item)}> {item.nome} </Button> //#TODO - Adicionar o nome do usuario dono de determinado equipamento
            obj.ultimoregistro = DateFormat(new Date(item.data_update_server))
            return obj
        })
        return binded
    }


    function detalhesEquipamento(item) {
        history.push(Pages.routes.Equipments)

    }
    function itemStatus(item) {
        //#TODO  - falta ser adicionado no array de objetos o status do equipamento
        item.status = 'Conectado'
        if (item.status === 'Conectado') {
            return <IconButton color='success' onClick={() => zoom(item)}><LocationOnIcon /></IconButton>
        } else {
            return <Button onClick={() => zoom(item)} disabled ><LocationOnIcon /></Button>
        }
    }


    return (

        <ProjectTable
            loading={loading}
            rowsPerPage={RowsPerPage}
            style={{ flex: 1, whiteSpace: 'nowrap', width: 200, }}
            onViewMore={detalhesEquipamento}
            title={strings.getString('default_equipament_title_situation')}
            headers={headers}
            items={tableItems}
            onRefresh={refresh}
            icon={<AgricultureIcon style={{ marginRight: '8px', alignSelf: 'center' }} sx={{ fontSize: 35, color: "#ffd600" }} />}
        />

    );

}

export default EquipmentsDashboardList;

