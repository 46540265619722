import SearchIcon from '@mui/icons-material/Search';
import PageviewIcon from '@mui/icons-material/Pageview';
import React from "react";
import { Button, Fab } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

/*
NAME: ViewMore
DESC: Componente responsavel por renderizar o botão de ver +
props:
Style: Para fazer o posicionamento do botão via props ,
 Variant: Define o tipo de botão, podendo ser: text,outlined,contained,
 Color: Define a cor do botão, podendo ser: default,inherit,primary,secondary. Por padrão é default,
onClick: Função que será executada ao clicar no botão.
startIcon: Icone que será exibido no lugar do texto do botão.

NOTES:
Este componente será usado como um botão ver mais sendo exibido um icone que é um componente do material ui ao inves de estar escrito ver +.

*/

function ViewMore(props) {
   
   function verMais() {
     return  <Fab size='small' variant={'contained'} color='primary' onClick={props.onClick}><SearchIcon/></Fab>
   }
   return (
   <div>
     <Tooltip title='Ver mais detalhes'>{verMais()}</Tooltip>
   </div>
      
   );
}

export default ViewMore;

