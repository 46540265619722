import { IconButton, Button, Card, CardActions, CardContent, CssBaseline, Divider, Paper, Typography, Stack, TextField, FormControlLabel, InputLabel, Input } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router';
import SaframaxLogo from '../components/basic/SaframaxLogo';
import AuthPerformLogin from '../usecases/AuthPerformLogin';
import ValidadeString from '../usecases/ValidateString';
import ValidateFieldEmail from '../usecases/ValidateFieldEmail';
import Utilities from '../util/Utilities';
import colors from '../values/colors';
import strings from '../values/strings/strings';
import values from '../values/values';
import Pages from './Pages';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import ProjectSwitch from '../components/basic/ProjectSwitch'

import FilledInput from '@mui/material/FilledInput';
import APIManager from '../connection/APIManager';

function Login(props) {
    const history = useHistory()

    const [loading, setloading] = useState(false)
    const [email, setemail] = useState("")
    const [email_error, setEmail_error] = useState(null)
    const [password, setpassword] = useState("")
    const [password_error, setpassword_error] = useState(null)
    const [errormessage, seterrormessage] = useState(null)
    const [showPassword, setShowPassword] = useState(false);
    const [apiStatus, setApiStatus] = useState(false)

    useEffect(async () => {
        //Check if the API is running
        const response = await APIManager.send("/", "GET", null, null);
        if (!response.ok) {
            console.log('API is not running')
            setApiStatus(false)
            //history.push(Pages.routes.Error)
        } else {
            console.log('API is running')
            setApiStatus(true)
        }
    }, [])

    const login = async () => {
        seterrormessage(null)
        //Validates the email and password strings
        let emailMessage = await ValidateFieldEmail(email)
        if (emailMessage) {
            setEmail_error(emailMessage)
        } else setEmail_error(null)

        let invalidPass = await ValidadeString(password)
        if (invalidPass) setpassword_error(strings.getString('warn_password_empty'))
        else setpassword_error(null)

        if (emailMessage || invalidPass) return

        //Perform login with API
        AuthPerformLogin(email, password).then(res => {
            //go to dashboard
            history.push(Pages.routes.Dashboard)
        }).catch(e => {
            seterrormessage(e)
        })
    }

    const resetPassword = () => {
        //go to reset password
        history.push(Pages.routes.ResetPassword)
    }

    const singUp = () => {
        history.push(Pages.routes.SignUp)
    }





    return (
        apiStatus === false && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CssBaseline />
                <Stack style={{ width: "80%", maxWidth: values.card_default_width }}>
                    <SaframaxLogo />
                    <Card style={{ padding: values.padding[1] }}>
                        <CardContent>
                            <Stack spacing={4}>
                                <Typography variant="h5" color="primary">Teste da api falhou</Typography>
                                <Typography>{strings.getString('screen_login_api_not_running')}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </div>
        ),

        apiStatus === true && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CssBaseline />
                <Stack style={{ width: "80%", maxWidth: values.card_default_width }}>
                    <SaframaxLogo />
                    <Card style={{ padding: values.padding[1] }}>{/*values.card_default_width */}
                        <CardContent>
                            <Stack spacing={4}>
                                <Typography variant="h5" color="primary">{strings.getString('screen_login_title')}</Typography>

                                <TextField label={strings.getString('info_email')}
                                    variant="standard" error={email_error}
                                    helperText={email_error}
                                    onChange={e => setemail(e.target.value)} />

                                <TextField label={strings.getString('info_password')}
                                    variant="standard"
                                    error={password_error}
                                    helperText={password_error}
                                    type={showPassword ? "text" : "password"}
                                    onChange={e => setpassword(e.target.value)}
                                    onKeyPress={(e) => { if (Utilities.checkKeyIsEnter(e)) login() }} />

                                <ProjectSwitch label={strings.getString('info_password_show')} checked={showPassword} onChange={() => { setShowPassword(!showPassword) }} />


                                <div style={{ alignItems: 'flex-start', }}>
                                    <Button style={{ flex: 1 }} size="small" onClick={resetPassword}>{strings.getString('screen_login_forgotpassword')}</Button>
                                </div>
                                <Stack direction="row">
                                    <Button style={{ flex: 1 }} variant="contained" onClick={login}>{strings.getString('screen_login_signin')}</Button>
                                </Stack>
                                {errormessage && errormessage !== "" && <Button color="error">{errormessage}</Button>}
                            </Stack>
                        </CardContent>
                    </Card>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: values.padding[1] }}>
                        <Typography>{strings.getString('screen_login_singup_info')}</Typography>
                        <Button style={{ marginLeft: 10 }} onClick={singUp}>{strings.getString('screen_login_singup')}</Button>
                    </div>
                </Stack>
            </div>
        )
    );
}

export default Login;

