/*
NAME: UsersList
DESC: Componente responsavel por listar o array de objetos contendo usuarios
props: 
- usuarioLogado: Tipo de usuario logado
- items: Array de objetos contendo usuarios
-refresh: Função que vem de um componente pai para atualizar a lista de usuarios através de um get
- RowsPerPage: Quantidade de linhas por pagina na tabela
- loading: Booleano para mostrar ou não o icone de carregando

NOTES:

*/

import { useEffect, useState } from "react";
import DateFormat from "../../usecases/DateFormat";
import ProjectTable from "../basic/ProjectTable";
import { Info, MarkEmailRead, Person, RecentActors } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Pages from "../../pages/Pages";
import { useHistory } from "react-router";
import UserTypes from "../../models/UserTypes"
import UserUnlock from "../../usecases/UserUnlock";
import strings from '../../values/strings/strings'

function UsersList({ usuarioLogado, items, refresh, rowsPerPage, loading, style }) {
    const [headers, setheaders] = useState([
        '', '', '', `${strings.getString('info_authorized')}`, "Usuário", `${strings.getString('info_data')}`, `${strings.getString('info_equips')}`,
        `${strings.getString('info_tipo')}`, `${strings.getString('info_name')}`, `${strings.getString('info_email')}`, `${strings.getString('info_telephone')}`,
    ]);
    const [itemnsTable, setitemnsTable] = useState([])
    const [usuarioLogado1, setUsuarioLogado] = useState(null)

    useEffect(() => {
        console.log('Users - useEffect', JSON.stringify(items))
        if (usuarioLogado && usuarioLogado.tipo !== UserTypes.CLI && headers[2] !== "Usuário") {
            let headersNew = [...headers]
            setheaders(headersNew)
        } else {
            let headersNew = [...headers]
            headersNew = [...headers.slice(0, 2), `${strings.getString(' default_user_name')}`, ...headers.slice(2, headers.length)]
            setheaders(headersNew)
        }
        setUsuarioLogado(usuarioLogado)
    }, [usuarioLogado])

    useEffect(() => {
        if (items) {
            let items1 = items
            let userTabela = bindData(items1)
            setitemnsTable(userTabela)
        }
    }, [items])

    const history = useHistory();

    const bindData = (data) => {
        let binded = [];

        binded = data.map((item, i) => {
            let obj = {}

            obj.info = (<IconButton color="info" onClick={() => redirectUser(item)}><Info /></IconButton>)
            obj.status = usuarioautorizado(item)
            obj.statusemail = autorizaemail(item)
            obj.lock = mudaStatusAutorizacao(item, i)
            if (usuarioLogado && usuarioLogado.tipo !== UserTypes.CLI)
                obj.usuario = <Button onClick={() => redirectUser(item)}> {item.nome} </Button>
            obj.data = DateFormat(new Date(item.data_update_server))
            obj.equips = item.qtd ? item.qtd : 0
            obj.tipo = item.tipo
            obj.nome = item.nome
            obj.email = item.email
            obj.telefone = item.telefone

            return {
                ...obj,
                loading: false,
            }
        })
        return binded;
    }

    function mudaStatusAutorizacao(item, i) {


        let iconeAutorizacao, tooltipMessage = ''

        if (item.autorizado === 1) {
            iconeAutorizacao = (<IconButton color="success" onClick={() => statusItem(item, i)} ><LockOpenIcon /></IconButton>)
            tooltipMessage = 'AUTORIZADO'
        }

        if (item.autorizado !== 1) {
            iconeAutorizacao = (<IconButton color="error" onClick={() => statusItem(item, i)} ><LockOpenIcon /></IconButton>)
            tooltipMessage = 'BLOQUEADO'
        }

        return (
            <Tooltip title={tooltipMessage}>
                {iconeAutorizacao}
            </Tooltip>
        )

    }

    function statusItem(item, i) {
        loading = true
        UserUnlock(item).then(() => {
            let newItem = { ...item }
            newItem.autorizado === 1 ? newItem.autorizado = 0 : newItem.autorizado = 1
            items[i] = newItem
            let binded = bindData(items)
            setitemnsTable(binded)
            refresh()
        }).finally(() => {
            loading = false
        })
    }

    function usuarioautorizado(item) {
        if (item.autorizado === 1) {
            return (<IconButton color="success" ><Person /></IconButton>)
        } else {
            return (<IconButton color="error" ><Person /></IconButton>)
        }
    }

    function autorizaemail(item) {
        if (item.emailconfirmado === 1) {
            return (<IconButton color="success" ><MarkEmailRead /></IconButton>)
        } else {
            return (<IconButton color="error" ><EmailIcon /></IconButton>)
        }
    }

    function redirectUser(item) {
        history.push(Pages.routes.Users + "/" + item.id)
    }

    function detalhesUsuarios() {
        history.push(Pages.routes.Users)
    }



    return (
        <ProjectTable
            rowsPerPage={rowsPerPage}
            style={{ flex: 1, whiteSpace: 'nowrap', width: 500 }}
            loading={loading}
            headers={headers}
            items={itemnsTable}
            onRefresh={refresh}
            title={strings.getString('screen_list_user')}
            onViewMore={detalhesUsuarios}
            icon={<RecentActors style={{ marginRight: '8px', alignSelf: 'center' }} sx={{ fontSize: 35, color: "#ffd600" }} />}
        />

    );
}

export default UsersList;