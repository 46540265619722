/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/*
NAME: WorksPulvSectionDisplay
DESC: Componente responsável por renderizar as seções de um determinado trabalho por meio da renderização condiconal de cada seção

props:
- itens: Array de objetos, seções do trabalho
- - - - Default
- style: Object, style object to overwrite this component style

NOTES:

*/


import SectionDisabled from '../../assets/images/sessao_desativado.png' //Secção desativada
import SectionEnabled from '../../assets/images/sessao_ligado.png' //Secção Ligada
import SessionWithoutFlow from '../../assets/images/sessao_ligado_semfluxo.png' //Secção Ligada sem fluxo
import { Button, Card, Paper, Typography } from "@mui/material";
import values from '../../values/values'
import { useEffect, useState } from 'react';


function WorksPulvSectionDisplay({ items, style }) {

    const [sections, setsections] = useState([])
    console.log('Lista de dados Pulv', items)


    useEffect(() => {
        if (items) {

            let orderAsc = [...items]

            orderAsc.sort((a, b) => {
                return a.numero - b.numero
            })

            setsections(orderAsc)

        }
        console.log('sections', sections)

    }, [items])

    function BGColor(item) {
        if (item.sessao_status === 'ABERTO') {
            return '#408c3c'
        }

        if (item.sessao_status === 'FECHADO') {
            return '#757575' //Vermelho cor de erro '#d82c2c'
        }

        if (item.sessao_status !== 'ABERTO' || item.sessao_status !== 'FECHADO') {
            return '#d82c2c'
        }
    }

    function Imagem(item) {
        if (item.sessao_status === 'ABERTO') {
            return SectionEnabled
        }

        if (item.sessao_status !== 'ABERTO') {
            return SessionWithoutFlow
        }
    }

    const renderItem = (item) => {

        //#TODO - Verificar se está correto E ADICIONAR Secção Ligada sem fluxo no JSON e posteriormente no front
        return (
            <div style={{ flex: 1, marginBottom: values.margin[2], justifyContent: 'center', alignContent: 'center' }}>

                <Card style={{ backgroundColor: BGColor(item), display: 'flex', flexDirection: 'column', display: 'flex', alignSelf: 'center', margin: values.margin[1], flex: 1, alignItems: 'center', }}>
                    <div style={{ alignSelf: 'center', }}>
                        {item.numero} {/*Numero da linha */}
                    </div>

                    <img src={Imagem(item)} alt="imagem" style={{ display: 'flex', height: '60%', width: '80%', justifyContent: 'center', flexWrap: 'wrap', flex: 1, minHeight: '35px', maxWidth: '70px', maxHeight: '60px' }} />


                    {/* #TODO - Arrumar problema no banco de dados do sm4 pois o mesmo está enviando somente a primeira linha da secção
                    { item.sessao_linha_ini != item.sessao_linha_fim &&
                        <Typography variant="h6" color='primary'style={{ alignSelf:'center', justifyContent:'center',}}>{item.sessao_linha_ini} - {item.sessao_linha_fim}</Typography>
                    }
            
                    {item.sessao_linha_ini == item.sessao_linha_fim  &&    
                        <Typography variant="h6" color='primary'style={{ alignSelf:'center', justifyContent:'center',}}>{item.sessao_linha_ini} </Typography>                       
                    } /*/}

                </Card>
            </div>
        )
    }

    return (

        <div style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', flex: 1 }} >
            {sections && sections.length > 0
                && sections.map((item, index) => {
                    return renderItem(item)
                })
            }

            {(!sections || sections.length === 0) && ("Nenhuma seção disponível")
            }
        </div>

    );
}

export default WorksPulvSectionDisplay;



/*


/*
NAME: WorksPulvSectionDisplay
DESC: Componente responsável por renderizar as seções de um determinado trabalho por meio da renderização condiconal de cada seção

props:
- itens: Array de objetos, seções do trabalho
- - - - Default
- style: Object, style object to overwrite this component style

NOTES:




import SectionDisabled from '../../assets/images/sessao_desativado.png' //Secção desativada
import SectionEnabled from '../../assets/images/sessao_ligado.png' //Secção Ligada
import SessionWithoutFlow from '../../assets/images/sessao_ligado_semfluxo.png' //Secção Ligada sem fluxo
import { Button, Card, Paper, Typography } from "@mui/material";
import values from'../../values/values'
import { useEffect } from 'react';


function WorksPulvSectionDisplay({ items, style }) {

    useEffect(() => {
        if (items){
            renderSection(10)
        }
    }, [items])
 
    const renderSection = (num ) => {
      
    }
    

    function BGColor(item){
        if(item.sessao_status == 'ABERTO'){
            return '#408c3c'
        }

        if(item.sessao_status == 'FECHADO'){
            return '#757575' //Vermelho cor de erro '#d82c2c'
        }
    }

    function Imagem(item){
        if(item.sessao_status == 'ABERTO'){
            return SectionEnabled
        }

        if(item.sessao_status == 'FECHADO'){
            return SectionDisabled
        }
    }
    
    const renderItem = (item) => {
    
        //#TODO - Verificar se está correto E ADICIONAR Secção Ligada sem fluxo
        return (      
            <div style={{display:'flex', flex:1}}>
                <Card  style={{flexWrap:'wrap',backgroundColor: BGColor(item),flexDirection:'column', display:'flex', alignSelf:'center', margin:values.margin[1], flex:1, alignItems:'center', }}> 
                    <div style={{ alignSelf:'center', }}>
                        {item.numero} {/*Numero da linha 
                    </div>
                
                    <img src={Imagem(item)} alt="imagem" style={{padding:2 ,display:'flex', height:'60%', width:'80%', justifyContent: 'center', flexWrap:'wrap', flex:1,   minHeight:'45px', maxWidth:'70px', flex:1, minHeight:'50px' }}/>
                
                    { item.sessao_linha_ini != item.sessao_linha_fim &&
                        <Typography variant="h6" color='primary'style={{ alignSelf:'center', justifyContent:'center',}}>{item.sessao_linha_ini} - {item.sessao_linha_fim}</Typography>
                    }
            
                    {item.sessao_linha_ini == item.sessao_linha_fim  &&    
                        <Typography variant="h6" color='primary'style={{ alignSelf:'center', justifyContent:'center',}}>{item.sessao_linha_ini} </Typography>                       
                    }
                </Card>
                </div>
            )
    }
 /*

  <div style={{flexBasis: '100%', height: 0}}></div>

 
  
   return (
       
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', flex:1,  }} >
                
                {items && items.length > 0
                    && items.map((item, index) => {
                      return renderItem(item)
                    }
                    )            
                }

                {!items || items.length === 0 
                    && ("Nenhuma seção disponível")
                }


            
            </div>
        
   );
}

export default WorksPulvSectionDisplay;


*/