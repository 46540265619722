import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Snackbar, Box, Grid, Card, CardContent, Typography, Tooltip, CircularProgress } from '@mui/material';
import APIManager from '../../connection/APIManager';
import AuthBuildHeaderUserObject from '../../usecases/AuthBuildHeaderUserObject';
import strings from '../../values/strings/strings';

const CustomerDetails = () => {
    const { id } = useParams();
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [recoveryCode, setRecoveryCode] = useState('');
    const [notification, setNotification] = useState('');
    const [copyNotification, setCopyNotification] = useState('');

    useEffect(() => {
        fetchCustomerDetails(id);
    }, [id]);

    const fetchCustomerDetails = async (customerId) => {
        setLoading(true);
        setError('');
        let obj = await AuthBuildHeaderUserObject().catch(e => { return null });
        if (!obj) {
            setError('Failed to build headers');
            setLoading(false);
            return;
        }

        const headers = {
            ...obj,
            'Content-Type': 'application/json',
            userID: customerId,
        };

        try {
            const response = await APIManager.send(`/usuarios/${customerId}`, 'GET', headers);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCustomer(data);
        } catch (err) {
            setError('Failed to fetch customer details');
        } finally {
            setLoading(false);
        }
    };

    const getCodePassword = async () => {
        let obj = await AuthBuildHeaderUserObject().catch(e => { return null });
        if (!obj) {
            setNotification('Erro ao gerar código de recuperação');
            return;
        }

        let headers = {
            ...obj
        }

        let body = { email: customer.email, mode_dev: true };

        try {
            const response = await APIManager.send('/changepass', 'POST', headers, body);
            const data = await response.json();
            setRecoveryCode(data.token);
            setNotification('Código de recuperação gerado com sucesso!');
            console.log("Resposta de solicitação de código ", data);

            // self.addEventListener('push', (event) => {
            //     const options = {
            //         body: 'This notification was generated from a push!',
            //         icon: 'images/icon.png',
            //         badge: 'images/badge.png',
            //     };
            //     event.waitUntil(self.registration.showNotification('Saframanager', options));

            // })

        } catch (error) {
            setNotification('Erro ao gerar código de recuperação');
            console.error("Erro ao solicitar código de recuperação", error);
        }
    };

    const handleCopyCode = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(recoveryCode)
                .then(() => {
                    setCopyNotification('Código copiado para a área de transferência!');
                    setTimeout(() => setCopyNotification(''), 2000);
                })
                .catch(err => {
                    console.error('Erro ao copiar o código: ', err);
                });
        } else {
            const textarea = document.createElement('textarea');
            textarea.value = recoveryCode;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                setCopyNotification('Código copiado para a área de transferência!');
                setTimeout(() => setCopyNotification(''), 2000);
            } catch (err) {
                console.error('Erro ao copiar o código: ', err);
                setCopyNotification('Erro ao copiar o código.');
                setTimeout(() => setCopyNotification(''), 2000);
            }
            document.body.removeChild(textarea);
        }
    };

    const obterCodigo = () => {
        if (recoveryCode) {
            handleCopyCode();
        } else {
            getCodePassword();
        }
    };

    function statusSubs(customer) {
        if (customer && customer.autorizado === 1) {
            return 'Ativo';
        } else if (customer && customer.autorizado === 0) {
            return 'Inativo';
        } else {
            return 'Não informado';
        }
    }

    if (loading) return <CircularProgress />;
    if (error) return <div>Error: {error}</div>;

    return (
        <Box sx={{ padding: '20px' }}>
            {customer && (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ backgroundColor: '#222', textAlign: 'center' }}>
                                <CardContent>
                                    <Typography variant="h6" sx={{ color: '#fff' }}>{strings.getString('default_equipments_registered')}</Typography>
                                    <Typography variant="h4" sx={{ color: '#ffcc00' }}>{customer.Qtd_Equipamento || '- - -'}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ backgroundColor: '#222', textAlign: 'center' }}>
                                <CardContent>
                                    <Typography variant="h6" sx={{ color: '#fff' }}>{strings.getString('works_total')}</Typography>
                                    <Typography variant="h4" sx={{ color: '#ffcc00' }}>{customer.Qtd_Trabalho || '- - -'}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ backgroundColor: '#222', textAlign: 'center' }}>
                                <CardContent>
                                    <Typography variant="h6" sx={{ color: '#fff' }}>{strings.getString('works_most_recent')}</Typography>
                                    <Typography variant="h4" sx={{ color: '#ffcc00' }}>
                                        {customer.Ultimo_Trabalho ? new Date(customer.Ultimo_Trabalho).toLocaleDateString() : '- - -'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ backgroundColor: '#222', textAlign: 'center' }}>
                                <CardContent>
                                    <Typography variant="h6" sx={{ color: '#fff' }}>Subscription Status</Typography>
                                    <Typography variant="h4" sx={{ color: '#ffcc00' }}>Subscription</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Box sx={{ marginTop: '10px' }}>
                        <Card sx={{ backgroundColor: '#333', color: '#fff' }}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>{strings.getString('info_customer')}</Typography>
                                <Typography><strong>{strings.getString('info_name')}: </strong> {customer.nome}</Typography>
                                <Typography><strong>{strings.getString('info_email')}: </strong> {customer.email}</Typography>
                                <Typography>
                                    <strong>{strings.getString('info_data')} :</strong> {customer.data_cad_server ? new Date(customer.data_cad_server).toLocaleDateString() : '- - -'}
                                </Typography>
                                <Typography>
                                    <strong>{strings.getString('info_last_acess')} :</strong> {customer.data_update_server ? new Date(customer.data_update_server).toLocaleDateString() : '- - -'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Grid container spacing={3} sx={{ marginTop: '10px' }}>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ backgroundColor: '#222', textAlign: 'center' }}>
                                <CardContent>
                                    <Tooltip title="Clique para copiar o código de recuperação" arrow>
                                        <Button
                                            onClick={obterCodigo}
                                            sx={{
                                                backgroundColor: '#ffcc00',
                                                color: '#000',
                                                width: '100%',
                                                '&:hover': {
                                                    backgroundColor: '#ffcc00'
                                                }
                                            }}
                                        >
                                            {recoveryCode || 'Obter código de alteração de senha'}
                                        </Button>
                                    </Tooltip>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={!!notification}
                        message={notification}
                        onClose={() => setNotification('')}
                        autoHideDuration={6000}
                    />
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={!!copyNotification}
                        message={copyNotification}
                        onClose={() => setCopyNotification('')}
                        autoHideDuration={2000}
                    />
                </>
            )}
        </Box>
    );
};

export default CustomerDetails;
