import es from './es'
import en from './en'
import ptbr from './pt-br'

let strings = {
    language: null,

    values: {
        app_name: 'saframanager',

        company_name: 'saframax',

        info_email: 'Email',
        info_cpf_cnpj: 'CPF/CNPJ',
        info_country: 'País',
        info_password: 'senha',
        info_password_confirm: 'confirmar senha',
        info_password_sucess: 'Senha alterada!',
        info_password_show: 'Mostrar senha',
        info_send: 'enviar por email',
        info_code: 'Digitar código',
        info_save: 'salvar',
        info_delete: 'Deletar',
        info_logout: 'Sair',
        info_loading: 'Carregando',
        info_validating: 'Validando',
        info_error_sending_email: 'Não foi possivel enviar!',
        info_registration_sucess: 'Cadastro realizado!',
        info_register: 'Cadastro',
        info_close: 'Fechar',
        info_filtro: 'Filtro',
        info_status: 'Status',
        info_tipo: 'Tipo',
        info_cultura: 'Cultura',
        info_precisao: 'Precisão',
        info_inicio: 'Início',
        info_ultimate_registred: 'Ultimo registro',
        info_last_acess: 'Ultimo acesso',
        info_operator: 'Operador',
        info_authorized: 'Autorizado',
        info_data: 'Data de Registro',
        info_email_status: 'Status Email',
        info_equips: 'Qtd. Equips',
        info_name: 'Nome',
        info_telephone: 'Telefone',
        info_registred: 'Registrado',
        info_not_registred: 'Não registrado',
        info_model: 'Modelo',
        info_customer: 'Dados do cliente',
        //info_tooltip_


        warn_email_empty: 'Informe o email',
        warn_email_invalid: 'Informe um endereço de email válido',
        warn_email_long: 'Endereço de email muito longo',
        warn_password_empty: 'Informe a senha',
        warn_login_unauthorized: 'Email ou senha inválidos',
        warn_login_servererror: 'Não foi possível fazer o login, tente novamente mais tarde',
        warn_login_error: 'Não foi possível fazer o login',
        warn_phone_limit: 'Telefone deve conter 10 caracteres',
        warn_phone_only_numbers: 'Telefone deve conter apenas numeros',
        warn_phone_invalid: 'Telefone inválido',
        warn_password_invalid: 'Senha inválida',
        warn_password_length: 'Senha deve conter entre 8 e 100 caracteres',
        warn_password_error: 'As senhas não são igauis',
        warn_email_validate_error: 'Não foi possível validar o email',
        warn_email_link_error: 'O link de confirmação de e-mail era inválido!',
        warn_error_sending_email: 'Erro ao enviar o email ',
        warn_error_send_server: 'Não foi possivel enviar ao servidor',
        warn_difrent_password: 'As senhas não são iguais',
        warn_speed_max_permited: 'Velocidade máxima permitida',


        default_user_name: 'Usuário',
        default_user_autorized: 'Usuário autorizado',
        default_works_title: 'Trabalhos',
        default_equipments_title: 'Equipamentos',
        default_equipment_title: 'Equipamento',
        default_equipament_title_situation: 'Lista de Equipamentos',
        default_equipments_registered: 'Equipamentos Registrados',
        default_equipments_no_registered: 'Equipamentos não Registrados',
        default_list_equipments: 'Lista de Equipamentos',
        default_login_want: 'Deseja fazer o login?',
        default_ask_account: 'Já possui conta ?',
        default_suggest_login: 'Faça o Login',
        default_works_send: 'Trabalhos Enviados',
        default_works_start: 'Trabalhos Iniciados',
        deafult_works_error: 'Trabalhos com Erro',
        default_works_list: 'Lista de Trabalhos',
        default_received: 'Recebidos',
        default_pending: 'Pendentes',
        default_send_email_confirmation: 'Enviar email de confirmação',

        unity_area: 'Área trabalhada',
        unity_area_pulverized: 'Área pulverizada',
        unity_area_unit: 'Área(ha)',
        unity_talhao: 'Talhão',
        unity_area_total_map: 'Área total mapeada (ha)',
        unity_area_map: 'Área Total Mapeada:',
        unity_precision_medium: 'Precisão Média',
        unity_initiated_title: 'Iniciados',
        unity_processing_title: 'Processando',
        unity_curret_connections_title: 'Conexões Atuais',
        unity_connections_today_title: 'Conexões Hoje',
        unity_actived_jobs_title: 'Trabalhos Ativos',
        unity_synchronisms_lastMonth: 'Sincronismos Ultimos 30 dias',
        unity_work_pendent_title: 'Trabalhos Pendentes',
        unity_new_user_title: 'Novo Usuário',
        unity_version_app: 'Versão App',
        unity_liters_consumed: 'Litros Consumidos',

        users_connecteds: 'Usuários Conectados',
        users_new_users: 'Novos Usuários',

        equips_connecteds: 'Equipamentos \n Conectados ',

        works_total: 'Total de trabalhos',
        works_total_monitoring: 'Total em Monitoramento',
        works_most_recent: 'Trabalho mais recente',

        screen_login_signin: 'entrar',
        screen_login_signin_message: 'Acesse a sua conta',
        screen_login_forgotpassword: 'Esqueci a senha',
        screen_login_title: 'Acesse a sua conta',
        screen_login_singup: 'cadastre-se',
        screen_login_singup_info: 'Não possui conta?',
        screen_log_out: 'Sair da conta',

        screen_singup_title: 'Crie sua conta!',
        screen_singup_message_registration_sucsses: 'Uma mensagem de confirmação foi enviada para o endereço de e-mail informado. Acesse seu email e siga as instruções para validar sua conta',
        screen_singup_success_message: 'Uma mensagem de confirmação foi enviada para o endereço de e-mail informado. Acesse seu email e siga as instruções para validar sua conta',
        screen_singup_error_title: "Não foi possível concluir o cadastro!",
        screen_singup_error_emailexists: 'Parece que já existe uma conta de usuário com este endereço de email',
        screen_singup_error_internal: 'Ocorreu um erro ao fazer o registro, por favor verifique os dados e tente novamente',
        screen_singup_new_registration: 'Novo Cadastro',

        screen_emailconfirmation_title: 'Confirmação de Email',
        screen_emailconfirmation_loading: 'Validando e-mail',
        screen_emailconfirmation_success_title: 'E-mail confirmado',
        screen_emailconfirmation_success_message: 'Seu endereço de e-mail foi confirmado, sua conta será avaliada e estará sujeita a aprovação, este processo pode levar até 2 dias úteis. Se necessário entraremos em contato. Assim que sua conta for validada lhe enviaremos uma mensagem para seu endereço de e-mail.',
        screen_emailconfirmation_error_title: 'Não foi possível validar',
        screen_emailconfirmation_error_message: 'Este link de confirmação de e-mail não é válido!',

        screen_resetpassword_title: 'Redefinir Senha',
        screen_resetpassword_message: 'Informe abaixo o E-mail da sua conta, e vamos enviar uma mensagem com instruções para redefinir a sua senha.',
        screen_resetpassword_newpassword: 'Informe a nova senha!',
        screen_resetpassword_confirm: 'Confirme a senha',
        screen_resetpassword_email_recovery: 'Enviamos uma mensagem para o endereço de E-mail informado, lá você encontra instruções sobre como recuperar sua senha.',
        screen_resetpassword_update_error_title: 'Não foi possível alterar a senha',
        screen_resetpassword_update_error_message: 'Não foi possível alterar a senha, possívelmente esta mensagem já foi utilizada ou expirou, tente novamente',
        screen_resetpassword_token_error_title: 'Não foi possivel validar',
        screen_resetpassword_token_error_message: 'Não foi possivel validar o e-mail, possívelmente esta mensagem já foi utilizada ou expirou, tente novamente',

        screen_chart_works_period: 'Gráfico de trabalhos por periodo',
        screen_chart_sinc_period: 'Gráfico de sincronismos por período',
        screen_dashboard_title: 'Painel de Controle',

        screen_dashboardcli_works_recent: 'Trabalhos Recentes',

        screen_equipamentconnectd_title: 'Gráfico de Equipamentos Conectados',
        screen_equipamentchartregistred_titele: 'Gráfico de Equipamentos ',
        screen_resetpassword_error_sending_email: 'Não foi possível enviar para o endereço de email informado, confirme que o email esta correto ou tente novamente mais tarde',
        screen_resetpassword_saframanager_login: 'Agora voce pode acessar o saframaganeger através da sua nova senha.',
        screen_resetpassword_validate_loading_password: 'Aguarde enquanto o sistema verifica este endereço de e-mail',

        screen_monitor_title: 'Monitoramento',

        screen_dashboartec_registered_customers: 'Clientes Cadastrados',
        screen_dashboartec_waiting_for_authorization: 'Aguardando Autorização',
        screen_dashboartec_pending_email_confirmation: ' Confirmação de Email Pendente',

        screen_monitor_list_title: 'Lista de trabalho_resumo / equipamento',

        screen_userChart_title: 'Gráfico de usuários por período',

        screen_list_user: 'Lista de usuários',

        screen_list_customers: 'Lista de clientes',

        screen_list_equip: 'Lista de Equipamentos',

        screen_equipments_title: 'Equipamentos',

        screen_customers_title: 'Clientes',

        screen_tools_title: 'Ferramentas',

        screen_users_title: 'Usuários',

        screen_system_title: 'Sistema',

        screen_profile_title: 'Minha Conta',

        screen_settings_title: 'Configurações',

        screen_works_title: 'Trabalhos',

        screen_userform_name_validation: 'Insira um nome válido',
        screen_userform_name_long: 'Nome muito longo',

        screen_monitorItem_title: 'Trabalhos Atuais',

        screen_monitoring_chart_title: 'Gráfico de Sementes/Metro por Linha',

        screen_equipament_chart_title: 'Gráfico de Equipamento por modelos',
        screen_equipament_blocked: 'Equipamentos Bloqueados',
        screen_equipament_connected: 'Conectados',
        screen_equipament_not_connected: 'Não Conectados',
        screen_equipament_registred: 'Equipamentos Registrados',

        screen_monitorItem_date_initial: 'Data Inicial',
        screen_monitorItem_speed_actual: 'Velocidade Atual',
        screen_monitorItem_hours_work: 'Horas Trabalhadas',
        screen_monitorItem_distance_traveled: 'Distância Percorrida',
        screen_monitorItem_temperature_max: 'Temperatura MAX',
        screen_monitorItem_pressure_max: 'Pressão MAX psi',
        screen_monitorItem_pressure_min: 'Pressão MIN psi',
        screen_monitorItem_number_lines: 'Quantidade de linhas',
        screen_monitorItem_antenna_height: 'Altura da antena',
        screen_monitorItem_line_spacing: 'Espaçamento entre linhas',
        screen_monitorItem_seeds_meter: 'Sementes por metro',
        screen_monitorItem_seeds_hectare: 'Sementes por hectare',
        screen_monitorItem_total_population: 'População total',
        screen_monitorItem_average_population_lines: 'População média por linha',
        screen_monitorItem_maximum_seed_rate: 'Taxa MAX sementes/m',
        screen_monitorItem_seed_target_rate: 'Taxa alvo sementes/m',
        screen_monitorItem_minimum_seed_rate: 'Taxa MIN sementes/m',
        screen_monitorItem_general_data: 'Dados gerais',
        screen_monitorItem_general_settings: 'Configurações Gerais',
        screen_monitorItem_planting_data: 'Dados de plantio',
        screen_monitorItem_planting_settings: 'Configurações de Plantio',
        screen_monitorItem_spray_data: 'Dados da Pulverização',
        screen_monitorItem_current_pressure: 'Pressão Atual', unity_target_flow: 'Vazão Alvo',
        screen_monitorItem_current_flow: 'Vazão Atual',
        screen_monitorItem_current_flow_min: 'Vazão Atual L/ha min',
        screen_monitorItem_current_temperature: 'Temperatura Atual',
        screen_monitorItem_spray_settings: 'Configurações de Pulverização',
        screen_monitorItem_section_indicator: 'Indicador de Seções',



        //UseCases
        //usecase_workgeterrorcurrentpressure_pressure_exceeded
        WorkGetErrotCurrentPressure_pressure_exceeded: 'Pressão excedida',

        WorkGetErrorVelocidade_seepd_max_exeeded: 'Velocidade excedida',

        WorkGetErrorVelocidade_work_stopped: 'Trabalho parado',

        WorkGetErrorTemperature_temperature_permited: 'Temperatura permitida',
        WorkGetErrorTemperature_temperature_max_exceeded: 'Temperatura máxima excedida',

        WorkGetErrorSeedsMeter_seeds_meter_exceed: 'Sementes por metro excedidas',

        ValidateFieldCPF_CNPJ_warn_cpfCnpj_empty: 'Informe o CPF ou CNPJ',
        ValidateFieldCPF_CNPJ_warn_cpfCnpj_invalid: 'CPF ou CNPJ inválido',

        ValidateTelephone_warn_phone_invalid: 'Telefone inválido',
        ValidateTelephone_warn_phone_limit: 'Telefone deve conter 10 caracteres',
        ValidateTelephone_warn_phone_only_numbers: 'Telefone deve conter apenas numeros',

        ValidatePassword_warn_password_invalid: 'Senha inválida',
        ValidatePassword_warn_password_length: 'Senha deve conter entre 8 e 100 caracteres',

        ValidateFieldEmail_warn_email_empty: 'Informe o email',
        ValidateFieldEmail_warn_email_invalid: 'Informe um endereço de email válido',
        ValidateFieldEmail_warn_email_long: 'Endereço de email muito longo',


    },

    getString: (id) => {
        if (!id) return ""
        let linguagem = strings.values
        let linguagem_selecionada = ""

        if (linguagem_selecionada === 'es') {
            linguagem = es.values;
        }

        if (linguagem[id]) return linguagem[id]
        else if (strings.values[id]) return strings.values[id]
        else return ""
    },

    getIds: () => {
        return Object.keys(strings.values)
    },

    getLanguageTitle: () => {
        let tag = strings.language
        console.log(`TAG ${tag}`)
        if (es.tag === tag) return es.name
        if (en.tag === tag) return en.name
        if (ptbr.tag === tag) return ptbr.name
        return 'Nenhum'
    }
}


export default strings




