/*
USE CASE: WorksGetData
Desc: retona da api um array de objetos dos trabalhos
params: 
   - trabalho_id: integer, id do trabalho, no caso da consulta ser todos os dados de um trabalho especifico
   - modo_resumo: boolean, caso seja um trabalho ainda não finalizado, para retornar dados no modo resumo
   OU
   - NONE
returns:
  - Uma promise que se bem sucedida retorna um array de objetos (trabalhos), ou um objeto do trabalho especifico quando 
  houver trabalho_id, caso contrario rejeita
*/
import APIManager from "../connection/APIManager";
import AuthBuildHeaderUserObject from "./AuthBuildHeaderUserObject";


const WorksGetData = (trabalho_id) => {
  return new Promise(async (resolve, reject) => {

    let obj = await AuthBuildHeaderUserObject().catch(e => { return null })
    if (!obj) reject()

    let headers = {
      ...obj,
    }
    if (trabalho_id) headers.trabalho_id = trabalho_id

    APIManager.send("/trabalho/basic", "GET", headers).then(res => {
      return res.json()
    }).then(json => {
      resolve(json)
    }).catch(e => {
      reject(e)
    })

  })
}
export default WorksGetData;