import { Button, Card, CardContent, CssBaseline, Typography, Stack, TextField, LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Utilities from '../util/Utilities';
import strings from '../values/strings/strings';
import SaframaxLogo from '../components/basic/SaframaxLogo';
import values from '../values/values';
import ValidatePassword from '../usecases/ValidatePassword';
import ValidateFieldEmail from '../usecases/ValidateFieldEmail';
import UserUpdatePassword from '../usecases/UserUpdatePassword';
import MessageShow from '../components/basic/MessageShow';
import AuthRequestPasswordEmail from '../usecases/AuthRequestPasswordEmail'
import AuthChangePasswordToken from '../usecases/AuthChangePasswordToken';
import AuthRecoverPasswordByCode from '../usecases/AuthRecoverPasswordByCode';
import Pages from './Pages';

function ResetPassword(props) {
    const history = useHistory()
    const [errormessage, seterrormessage] = useState(null)
    const [loading, setloading] = useState(true)
    const [token, settoken] = useState(null)
    const [enviado, setenviado] = useState(null)
    const [requestErro, setRequestErro] = useState(null)
    const [password, setpassword] = useState("")
    const [passwordError, setpasswordError] = useState(null)
    const [confirmPassword, setconfirmPassword] = useState("")
    const [confirmPasswordError, setconfirmPasswordError] = useState(null)
    const [email, setemail] = useState("")
    const [emailError, setEmailError] = useState(null)
    const [code, setCode] = useState("")
    const [codeError, setCodeError] = useState(null)

    useEffect(() => {
        checkToken()
    }, [])

    const checkToken = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const parametro = queryParams.get('token');
        if (parametro) {
            settoken(parametro)
            setloading(true)
            AuthChangePasswordToken(parametro).catch(() => {
                setRequestErro(true)
            }).finally(() => {
                setloading(false)
            })
        } else {
            setloading(false)
        }
    }

    async function sendEmailToken() {
        let emailInvalid = await ValidateFieldEmail(email)
        if (emailInvalid) {
            setEmailError(emailInvalid)
            return
        }
        setloading(true)
        AuthRequestPasswordEmail(email).then(() => {
            setenviado(true)
            setRequestErro(null)
        }).catch(() => {
            setenviado(true)
            setRequestErro(true)
        }).finally(() => {
            setloading(false)
        })
    }

    async function enviaUsuario() {
        setloading(true)
        let deuErro = false

        let passwordInvalid = await ValidatePassword(password)
        if (passwordInvalid) {
            setpasswordError(passwordInvalid)
            deuErro = true
        } else setpasswordError(null)

        if (password !== confirmPassword || confirmPassword === "") {
            setconfirmPasswordError(strings.getString("warn_password_error"))
            deuErro = true
        } else setconfirmPasswordError(null)

        if (deuErro) return

        let user = {}
        user.password = password
        user.token = token

        UserUpdatePassword(user).then(() => {
            setenviado(true)
            setRequestErro(null)
        }).catch(() => {
            setenviado(true)
            setRequestErro(true)
        }).finally(() => {
            setloading(false)
        })
    }

    async function useCode() {
        let emailInvalid = await ValidateFieldEmail(email)
        if (emailInvalid) {
            setEmailError(emailInvalid)
            return
        }
        if (!code) {
            setCodeError("Código é obrigatório")
            return
        }

        AuthRecoverPasswordByCode(email, code, password).then(() => {
            setenviado(true)
            setRequestErro(null)
        }).catch(() => {
            setenviado(true)
            setRequestErro(true)
            seterrormessage(strings.getString('screen_resetpassword_update_error_message'))
        }).finally(() => {
            setloading(false)
        })
    }

    function backlogin() {
        history.push(Pages.routes.Login)
    }

    function close() {
        history.push(Pages.routes.HomePage)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', }}>
            <CssBaseline />
            <Stack style={{ width: values.card_default_width, maxWidth: values.card_default_width }}>
                <SaframaxLogo />
                <Card style={{ padding: values.padding[1] }}>
                    <CardContent>
                        {loading &&
                            <Stack spacing={2}>
                                <MessageShow title={strings.getString('info_validating') + "..."} message={strings.getString('screen_resetpassword_validate_loading_password')} />
                                <LinearProgress />
                            </Stack>
                        }

                        {!token && !enviado && !loading && !code &&
                            <Stack spacing={2}>
                                <MessageShow title={strings.getString('screen_resetpassword_title')} message={strings.getString('screen_resetpassword_message')} />
                                <TextField label={strings.getString('info_email')} error={emailError} helperText={emailError} variant="standard" value={email} onChange={e => setemail(e.target.value)}
                                    onKeyPress={(e) => { if (Utilities.checkKeyIsEnter(e)) sendEmailToken() }} />
                                {errormessage && errormessage !== "" && <Button type="error">{errormessage}</Button>}

                                <Stack direction="row">
                                    <Button style={{ flex: 1, marginRight: '10px' }} onClick={sendEmailToken} variant='contained'>{strings.getString('info_send')}</Button>
                                    <Button style={{ flex: 1, marginLeft: '10px' }} onClick={() => setCode(true)} variant='contained'>{strings.getString('info_code')}</Button>
                                </Stack>
                            </Stack>
                        }

                        {enviado && !token && !loading &&
                            <Stack spacing={2}>
                                {!requestErro && <MessageShow message={strings.getString('screen_resetpassword_email_recovery')} title={'Email Enviado!'} />}
                                {requestErro && <MessageShow type="error" message={strings.getString('screen_resetpassword_error_sending_email')} title={strings.getString('warn_error_sending_email')} />}
                                <Button onClick={close} variant="contained" >{strings.getString('info_close')}</Button>
                            </Stack>
                        }

                        {code && !enviado && !requestErro && !loading &&
                            <Stack spacing={2}>
                                <Typography variant="h6" color="primary">Insira o email e o código</Typography>
                                <TextField label={strings.getString('info_email')} error={emailError} helperText={emailError} variant="standard" value={email} onChange={e => setemail(e.target.value)}
                                    onKeyPress={(e) => { if (Utilities.checkKeyIsEnter(e)) sendEmailToken() }} />

                                {errormessage && errormessage !== "" && <Button type="error">{errormessage}</Button>}
                                <TextField label="Código" variant="standard" onChange={(e) => setCode(e.target.value)} />

                                <TextField error={passwordError} helperText={passwordError} label={Utilities.primeiraMaiuscula(strings.getString('info_password'))} variant="standard" type="password" onChange={e => setpassword(e.target.value)} />
                                <TextField error={confirmPasswordError} helperText={confirmPasswordError} label={Utilities.primeiraMaiuscula(strings.getString('screen_resetpassword_confirm'))} variant="standard" type="password" onChange={e => setconfirmPassword(e.target.value)} />

                                {errormessage && errormessage !== "" && <Button type="error">{codeError}</Button>}
                                <Button variant="contained" onClick={useCode}>Enviar</Button>

                            </Stack>
                        }

                        {token && !enviado && !requestErro && !loading &&
                            <Stack spacing={2}>
                                <Typography variant="h4" color="primary">{strings.getString('screen_resetpassword_title')}</Typography>
                                <Typography>{strings.getString('screen_resetpassword_newpassword')}</Typography>
                                <TextField error={passwordError} helperText={passwordError} label={Utilities.primeiraMaiuscula(strings.getString('info_password'))} variant="standard" type="password" onChange={e => setpassword(e.target.value)} />
                                <TextField error={confirmPasswordError} helperText={confirmPasswordError} label={Utilities.primeiraMaiuscula(strings.getString('screen_resetpassword_confirm'))} variant="standard" type="password" onChange={e => setconfirmPassword(e.target.value)} />

                                {errormessage && errormessage !== "" && <Button type="error">{errormessage}</Button>}
                                <Button style={{ flex: 1 }} onClick={enviaUsuario} variant='contained'>{strings.getString('info_save')}</Button>
                            </Stack>
                        }

                        {(token || code) && !enviado && requestErro && !loading &&
                            <Stack spacing={2}>
                                <MessageShow style={{ padding: values.padding[1], width: values.card_default_width }} type="error" title={strings.getString('screen_resetpassword_token_error_title')} message={strings.getString('screen_resetpassword_token_error_message')} />
                                <Button onClick={close} variant="contained" >{strings.getString('info_close')}</Button>
                            </Stack>
                        }

                        {token && enviado && !requestErro && !loading &&
                            <Stack spacing={2}>
                                <MessageShow style={{ padding: values.padding[1], width: values.card_default_width }} title={strings.getString('info_password_sucess')} message={strings.getString('screen_resetpassword_saframanager_login')} />
                                <Button onClick={backlogin} variant="contained">{strings.getString('screen_login_signin_message')}</Button>
                            </Stack>
                        }

                        {code && enviado && !requestErro && !loading &&
                            <Stack spacing={2}>
                                <MessageShow style={{ padding: values.padding[1], width: values.card_default_width }} title={strings.getString('info_password_sucess')} message={strings.getString('screen_resetpassword_saframanager_login')} />
                                <Button onClick={backlogin} variant="contained">{strings.getString('screen_login_signin_message')}</Button>
                            </Stack>

                        }

                        {token && enviado && requestErro && !loading &&
                            <Stack spacing={2}>
                                <MessageShow type="error" style={{ padding: values.padding[1], width: values.card_default_width }} title={strings.getString('screen_resetpassword_update_error_title')} message={strings.getString('screen_resetpassword_update_error_message')} />
                                <Button onClick={close} variant="contained" >{strings.getString('info_close')}</Button>
                            </Stack>
                        }
                    </CardContent>
                </Card>
            </Stack>
        </div>
    );
}

export default ResetPassword;
