import React, { useState } from 'react';
import { Button, Popover, List, ListItem, Slider, FormControl, InputLabel, Select, ListItemText } from '@mui/material';
import values from '../../values/values';

function MapConfigPanel({ lineColor, onLineColorChange, strokeWeight, onStrokeWeightChange }) {
  const [open, setOpen] = useState(false);

  const colorOptions = values.graphicColors;

  const handleColorSelect = (color) => {
    onLineColorChange(color);
    setOpen(false);
  };

  const handleSliderChange = (event, newValue) => {
    onStrokeWeightChange(newValue);
    strokeWeight = newValue;
  };

  return (
    <div>
        <List>
        <ListItemText primary="Cor da Linha" />
          <ListItem>
            <List>
              {colorOptions.map((option, index) => (
                <ListItem key={index}>
                  <Button
                    style={{ backgroundColor: option.color, width: '20px', height: '10', marginRight: '8px' }}
                    onClick={() => handleColorSelect(option.color)}
                  />
                  {option.name}
                </ListItem>
              ))}
            </List>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel>Espessura da Linha</InputLabel>
              <Slider
                value={strokeWeight}
                min={1}
                max={30}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                aria-labelledby="line-thickness-label"
              />
            </FormControl>
          </ListItem>
        </List>
    </div>
  );
}

export default MapConfigPanel;
