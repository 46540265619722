/* eslint-disable no-unused-vars */
/*
NAME: DashBoardCli
DESC: Página renderizada dentro de dashboard quando usuário for CLIENTE
props:
- NADA
- - - - Default
- NADA
NOTES:
    - #TODO - Mapa não mostra itens da lista de equipamentos
    - #TODO - Botão PIN do mapa não implementado
*/

import React, { useEffect, useState } from 'react';
import BasicCard from '../basic/BasicCard';
import InfoCard from '../basic/InfoCard';
import InfoContainer from '../basic/InfoContainer';
import RowContainer from '../basic/RowContainer';
import RowContainerItem from '../basic/RowContainerItem';
import ViewMore from '../basic/ViewMore';
import ButtonEdit from '../basic/ButtonEdit'
import EquipmentsDashboardList from '../specific/EquipmentsDashboardList';
import EquipmentsMap from '../specific/EquipmentsMap';
import WorksList from '../specific/WorksList';
import values from '../../values/values';
import EquipamentGetData from '../../usecases/EquipamentGetData';
import WorksGetData from '../../usecases/WorksGetData'
import { CircularProgress, Stack, Typography } from '@mui/material';
import { Button, Divider } from '@mui/material';
import { useHistory } from 'react-router';
import Pages from '../../pages/Pages';
import strings from '../../values/strings/strings';
import Refresh from '../basic/Refresh';
import CacheLoadCredentials from '../../usecases/CacheLoadCredentials';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { yellow } from '@mui/material/colors';

function DashBoardCli() {

    const [equipamentos, setequipamentos] = useState([]);
    const [equipamentosLoading, setEquipamentosLoading] = useState(true)
    const [loading, setloading] = useState(false)
    const [trabalhos, settrabalhos] = useState([]);
    const [trabalhosPendentes, settrabalhosPendentes] = useState(0);
    const [trabalhosEnviados, settrabalhosEnviados] = useState(0);
    const [trabalhosIniciados, settrabalhosIniciados] = useState(0);
    const [areaMapeada, setareaMapeada] = useState(0);
    const [usuarioLogado, setusuarioLogado] = useState(null);

    //uSeStates para os trabalhos recentes, com valores apenas para teste


    const history = useHistory()

    //Renderização do componente
    useEffect(() => {
        CacheLoadCredentials().then(res => {
            if (res && res.tipo) setusuarioLogado(res.tipo)
        })
        getEquipamentos()
        getTrabalhos()
    }, [])

    useEffect(() => {

        let enviados = 0;
        let iniciados = 0;
        let pendentes = 0;

        trabalhos.forEach(function (item, index) {
            if (!item.modo_resumo && item.sincronismo.sincronismo_status === 'CONCLUIDO') enviados++
            if (!item.modo_resumo && item.sincronismo.sincronismo_status !== 'CONCLUIDO') pendentes++
            if (item.modo_resumo) iniciados++
        })

        let area = 0

        trabalhos.forEach(function (item, index) {
            if (item.plantio.plantio_ponto) {
                area += item.plantio.plantio_ponto.areaplantada_ha
            } else if (!item.plantio.plantio_ponto && item.pulv.pulv_ponto) {
                area += item.pulv.pulv_ponto.metros_percorridos
            } else {
                return ' - - '
            }

        })

        setareaMapeada(area.toFixed(2))
        settrabalhosEnviados(enviados)
        settrabalhosIniciados(iniciados)
        settrabalhosPendentes(pendentes)

    }, [trabalhos])

    const getEquipamentos = () => {

        setloading(true)
        EquipamentGetData().then(items => {
            setloading(true)
            setequipamentos(items)
            console.log('Equipamentos LISTA', items)
        }).catch(err => {
            console.log('Erro ao contar a quantidade de equipamentos ' + err)
        }).finally(() => {
            setloading(false)
        })
    }

    const getTrabalhos = () => {
        setloading(true)
        WorksGetData().then(items => {
            settrabalhos(items)
            //console.log('Trabalhos',items)
        }).catch(err => {
            console.log('Erro ' + err)
        }).finally(() => {
            setloading(false)
        })
    }

    function equipament() {
        history.push(Pages.routes.Equipments)
    }

    function works() {
        history.push(Pages.routes.Works)
    }

    function edit() {
        alert('Editar')
    }

    return (
        <div>

            <RowContainer>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title={strings.getString('unity_area_total_map')}
                        value={areaMapeada !== null ? areaMapeada : <CircularProgress />}
                    />
                </RowContainerItem>
                <RowContainerItem>
                    <InfoCard
                        flex
                        title={strings.getString('default_works_start')}
                        value={trabalhosIniciados !== null ? trabalhosIniciados : <CircularProgress />}
                    />
                </RowContainerItem>
                <RowContainerItem >
                    <InfoCard
                        flex
                        title={strings.getString('default_works_send')}
                        value={trabalhosEnviados !== 0 ? trabalhosEnviados : <CircularProgress />}
                    />
                </RowContainerItem>
                <RowContainerItem >
                    <InfoCard
                        flex
                        title={strings.getString('unity_work_pendent_title')}
                        value={trabalhosPendentes !== null ? trabalhosPendentes : <CircularProgress />}
                    />
                </RowContainerItem>
                <RowContainerItem >
                    <InfoCard
                        flex
                        title={strings.getString('default_equipments_title')}
                        value={equipamentos.length !== 0 ? equipamentos.length : <CircularProgress />}
                    />
                </RowContainerItem>
            </RowContainer>

            <RowContainer spacing={1}>
                <RowContainerItem minWidth={300} height={400}>
                    <EquipmentsMap trabalhos={trabalhos} height={741} />
                </RowContainerItem>
                <RowContainerItem minWidth={300} height={400}>
                    <BasicCard style={{ marginBottom: values.margin[2], overflow: 'hidden' }}>
                        <EquipmentsDashboardList
                            RowsPerPage={10}
                            items={equipamentos}
                            loading={loading}
                            usuarioLogado={usuarioLogado} />
                    </BasicCard>
                </RowContainerItem>
            </RowContainer>

            <RowContainer spacing={1}>
                <RowContainerItem minWidth={300} height={400}>
                    <BasicCard style={{ marginBottom: values.margin[2], overflow: 'hidden' }}>
                        <WorksList
                            RowsPerPage={10}
                            items={trabalhos}
                            loading={loading}
                            usuarioLogado={usuarioLogado}
                            title={strings.getString('default_works_list')}
                        />
                    </BasicCard>
                </RowContainerItem>
            </RowContainer>

        </div>

    );
}

export default DashBoardCli;