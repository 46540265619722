/*
USE CASE: WorkGetErrotCurrentPressure
Desc: Compara os valores do objeto para determinar se está com erro na pressão atual
params: 
   - item: objeto no padrão da API que representa um trabalho
returns:
  - True caso tenha erro na pressão atual , False caso esteja tudo OK ou os parametros forem inválidos
*/

import strings from '../values/strings/strings'

const WorkGetErrotCurrentPressure = (item) => {
    if(!item || 
        !item.plantio || 
        !item.pulv ||
        !item.pulv.config_pressao_max_psi ||
        !item.pulv.pulv_ponto.pressao_atual_psi
    ) return null

    let message = null

    if (item.pulv.pulv_ponto.pressao_atual_psi > item.pulv.config_pressao_max_psi) return message = `${strings.getString('WorkGetErrotCurrentPressure_pressure_exceeded')} `
    if (item.pulv.pulv_ponto.pressao_atual_psi < item.pulv.config_pressao_max_psi) return null  

    return message
    
}
export default WorkGetErrotCurrentPressure;


