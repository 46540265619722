/*NAME: Monitoring
DESC: Componente será responsável pela renderização do mapa de equipamentos e a lista de trabalhos
props:
- 
- - - - Default
- style: Object, style object to overwrite this component style
NOTES:
    - #TODO - Mapa não mostra a posição dos equipamentos dos trabalhos listados
*/

import React, { useEffect, useState, useContext } from 'react';
import BasicCard from "../basic/BasicCard";
import InfoCard from "../basic/InfoCard";
import RowContainer from "../basic/RowContainer";
import RowContainerItem from "../basic/RowContainerItem";
import EquipmentsMap from "../specific/EquipmentsMap";
import MonitorList from '../specific/MonitorList';
import strings from '../../values/strings/strings';
import MonitoringItem from './MonitoringItem';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import values from '../../values/values';
import { Divider } from '@mui/material';
import Refresh from '../basic/Refresh';
import WorksGetMonitoring from '../../usecases/WorksGetMonitoring';

function Monitoring({ }) {
  const [trabalhos, settrabalhos] = useState([]);
  const [loading, setloading] = useState(null);
  const [usuarioLogado, setusuarioLogado] = useState(null);
  const [equipamentosConectados, setequipamentosConectados] = useState(0);
  const [areaMapeada, setareaMapeada] = useState(0);
  const [precisao, setprecisao] = useState([]);
  const [itemSelecionado, setitemSelecionado] = useState(null);
  const [plano, setPlano] = useState(null);
  // const { user } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let id = window.location.pathname.split('/')[2];

    if (id) setitemSelecionado({ id: id });

    if (!id) {
      setitemSelecionado(null);
      getWorks();
    }

  }, []);

  useEffect(() => {
    if (trabalhos.length > 0) {
      let copia = [...trabalhos];
      calculoPrecisao(copia);
      //-----------------------------------
      let total = 0;
      trabalhos.forEach(item => {
        let count;
        if (item.equipamento.connected) {
          count++;
        } else {
          count--;
        }

        if (item.plantio.plantio_ponto && item.pulv.pulv_ponto) {
          let totalPulv = 0;
          let totalPlantio = 0;

          if (item.plantio.plantio_ponto && item.pulv.pulv_ponto) {
            let totalPulv = 0;
            let totalPlantio = 0;

            totalPulv = (item.pulv.pulv_ponto.vazao_geral_lha * 100);
            totalPulv = totalPulv / item.pulv.config_taxa_alvo;

            totalPlantio = (item.plantio.plantio_ponto.sementes_metro_total * 100);
            totalPlantio = totalPlantio / item.plantio.config_sementesmetro;

            total = total + ((totalPulv + totalPlantio) / 2);
          }

          if (item.plantio.plantio_ponto && !item.pulv.pulv_ponto) {
            let totalPlantio = 0;
            totalPlantio = (item.plantio.plantio_ponto.sementes_metro_total * 100);
            totalPlantio = totalPlantio / item.plantio.config_sementesmetro;

            total = total + totalPlantio;
          }

          if (!item.plantio && item.pulv) {
            let totalPulv = 0;
            totalPulv = (item.pulv.pulv_ponto.vazao_geral_lha * 100);
            totalPulv = totalPulv / item.pulv.config_taxa_alvo;
            total = total + totalPulv;
          }

          if (!item.plantio && !item.pulv) {
            total = total + 0;
          }
        }
      });

      total = total / trabalhos.length;
      setprecisao(total.toFixed(0));

      //area mapeada
      let area = 0;
      trabalhos.forEach(function (item, index) {
        if (!trabalhos[index]) return ' - - ';

        if (trabalhos[index].plantio) {
          try {
            area += trabalhos[index].plantio.plantio_ponto.areaplantada_ha;
          } catch (e) {
            return ' - - ';
          }
        } else if (trabalhos[index].pulv) {
          try {
            area += trabalhos[index].pulv.pulv_ponto.area_pulverizada; //#TODO - NÃO USAR METROS PERCORRIDOS, AREA
          } catch (e) {
            return ' - - ';
          }
        } else if (trabalhos[index].plantio && trabalhos[index].pulv) {
          area += trabalhos[index].plantio.plantio_ponto.areaplantada_ha + trabalhos[index].pulv.pulv_ponto.area_pulverizada;
        } else {
          return ' - - ';
        }
      });

      setareaMapeada(area.toFixed(2));
    }
  }, [trabalhos]);

  const calculoPrecisao = (item) => {
    // WorksGetPrecision(item) #TODO - Item de plantio ponto consta como undeined
  };

  const getWorks = () => {
    setloading(true);
    WorksGetMonitoring(null, true).then(data => {  //#TODO - Alterar o caso de uso de WorksGetData para WorksGetMonitoring assim que o caso de uso estiver retornando os dados dos trabalhos da api
      // ordenar por data 
      console.log('data: ', data);
      settrabalhos(data);
    }).finally(() => {
      setloading(false);
    });
  };

  const deleteItemArray = (item, index) => {
    //logica de remover item do array
    let copia = [...trabalhos];
    copia.splice(index, 1);
    settrabalhos(copia);
  };

  const itemSelected = (id) => {
    setitemSelecionado(id);
  };

  const atualizar = () => {
    history.push('/dashboard');
  };

  return (
    <div>
      {!itemSelecionado &&
        <div>
          <RowContainer>
            <RowContainerItem style={{ display: 'flex', flex: 4, flexDirection: 'column' }} >
              <div style={{ height: 300 }}>
                <EquipmentsMap trabalhos={trabalhos} />
              </div>
            </RowContainerItem>
            <RowContainerItem style={{ display: 'flex', flex: 1, flexDirection: 'column' }} >
              <div style={{ height: 300 }}>
                <InfoCard style={{ height: 150 }} title={<span style={{ fontSize: 20, alignContent: 'center' }}>{strings.getString('unity_area_map')}</span>} value={areaMapeada + 'ha'} />
                <Divider orientation="horizontal" flexItem style={{ margin: values.margin[1] }} />
                <InfoCard style={{ height: 150 }} title={<span style={{ fontSize: 20, alignContent: 'center' }}>{strings.getString('equips_connecteds')}</span>} value={equipamentosConectados} />
              </div>
            </RowContainerItem>
          </RowContainer>
          <RowContainer>
            <RowContainerItem style={{ flex: 2 }}>
              <BasicCard >
                <MonitorList
                  onSelect={itemSelected}
                  usuarioLogado={usuarioLogado}
                  RowsPerPage={10}
                  items={trabalhos}
                  refresh={getWorks}
                  loading={loading}
                  onDelete={deleteItemArray}
                />
              </BasicCard>
            </RowContainerItem>
          </RowContainer>
        </div>
      }
      {itemSelecionado &&
        <RowContainer>
          <MonitoringItem item={itemSelecionado} />
        </RowContainer>
      }
    </div>
  );
}

export default Monitoring;


/*


  useEffect(() => {
       // console.log('Tela Instanciada')
     
       
        getWork()
        let newInterval = setInterval(() => {
            console.log('interval call')
           getWork()           
        },1000)   
        setUpdater(newInterval)      

        const onClose = () => {     
            console.log('interval onClose')       
            clearInterval(newInterval)
        }        
        return onClose
    }, [])
  
    useEffect(() => {       
        if(item){  
            console.log('item', item)
            settrabalho(item)
        }        
    }, [item])

    useEffect(() => {
        if(trabalho){
            console.log('trabalho', JSON.stringify(trabalho))
            bindData(trabalho)
        } 
    }, [trabalho])

*/