import values from '../values/values.js'
import strings from '../values/strings/strings.js'
import ValidateString from './ValidateString.js'
import ValidateInteger from './ValidateInteger.js'
/*
USE CASE: ValidateTelephone
Desc: Valida uma string para padrão de telefone, deve conter apenas numeros e o tamanho minimo para strings deve ser de 10 caracteres
params: 
   - string, telefone a ser validado
returns:
  - A promise that, se o telefone for valido resolve com null, se não resolve com a mensagem de erro
*/
const ValidateTelephone = (telefone) => {
    return new Promise(async (resolve, reject) => {

        let stringValid = await ValidateString(telefone)
        if(stringValid){
            return resolve(strings.getString('ValidateTelephone_warn_phone_invalid'))
        }

        if (telefone.length > values.phone_limit) {
            return resolve(strings.getString('ValidateTelephone_warn_phone_limit'));
        }
        stringValid = await ValidateInteger(telefone)
        if (stringValid) {
            return resolve(strings.getString('ValidateTelephone_warn_phone_only_numbers'));
        }
        return resolve(null);        
    })
}
export default ValidateTelephone;