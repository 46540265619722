import { AppBar, CssBaseline, Toolbar } from '@mui/material';
import React from 'react';

function HomePage(props) {
    return (
        <div>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default HomePage;