import React, { useEffect } from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';
import APIConstants from '../connection/APIConstants';
import CacheLoadCredentials from '../usecases/CacheLoadCredentials';

function System(props) {

    let user = null
    useEffect(() => {
        CacheLoadCredentials().then(res => {
            console.log('Credenciais carregadas', res)
            user = res
        }).catch(e => {
            console.log('Erro ao carregar credenciais', e)
        })
    })    
    
    return (
    <div>
        {user && user.tipo === APIConstants.user_type_dev && (
            <DashboardFrame title={strings.getString('screen_system_title')}>
            </DashboardFrame>
    
        )}
    </div>
    );
}

export default System;