import React from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';
import Customer from '../components/pagescomponents/Customer';

function Customers(props) {
    return (
        <DashboardFrame title={strings.getString('screen_customers_title')}>
            <Customer />
        </DashboardFrame>
    );
}

export default Customers;