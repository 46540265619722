/*
NAME: WorksList
DESC: Componente Resposanvel por montar a lista de trabalhos 
props:
- items: array de objetos de trabalhos
- usuarioLogado: Tipo de usuario logado, vem do componente pai 
- title: titulo da tabela vem de um componente que chama este componente
- RowsPerPage: numero de linhas que serao exibidas na tabela
- loading: boolean que indica se a tabela esta carregando
- refresh: funcao que atualiza a tabela

- - - - Default
- style: Object, style object to overwrite this component style
- children: Component, renders component childs
NOTES:

*/
import React, { useState, useEffect } from 'react';
import ProjectTable from '../basic/ProjectTable';
import DateFormat from '../../usecases/DateFormat';
import { Button, IconButton, Snackbar, Typography } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Pages from '../../pages/Pages';
import { useHistory } from 'react-router-dom';
import UserTypes from '../../models/UserTypes'
import strings from '../../values/strings/strings'
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import Agriculture from '@mui/icons-material/Agriculture';
import CircularProgress from '@mui/material/CircularProgress'




const TIME_LIMIT = 5

function WorksList({ onSelect, items, usuarioLogado, title, RowsPerPage, loading, refresh, onViewMore }) {

    const [headers, setheaders] = useState([
        'Ação', "Status", `${strings.getString('default_equipment_title')}`, `${strings.getString('info_tipo')}`, `${strings.getString('unity_talhao')}`,
        `${strings.getString('info_cultura')}`, `${strings.getString('unity_area_unit')}`, "Usuário", `Data Início`, "Data Final", `${strings.getString('info_operator')}`
    ])
    const [itemnsTable, setitemnsTable] = useState([])
    const [usuarioLogado1, setUsuarioLogado] = useState(null)


    useEffect(() => {
        if (usuarioLogado && usuarioLogado.tipo === UserTypes.CLI && headers[2] !== "Usuário") {
            let headersNew = [...headers]
            headersNew = [...headers.slice(0, 1), ...headers.slice(1, headers.length)]
            setheaders(headersNew)
        }
        setUsuarioLogado(usuarioLogado)
    }, [headers, usuarioLogado])

    const history = useHistory();

    useEffect(() => {
        if (items) {
            diferencaTime()
            console.log('trablhos', JSON.stringify(items))
            let items1 = items
            let userTabela = bindData(items1)
            setitemnsTable(userTabela)
        }
    }, [items])

    const diferencaTime = () => { }

    const bindData = (data) => {
        let binded = [];

        let orderData = data.sort((a, b) => {
            return new Date(b.data_update_server).getTime() - new Date(a.data_update_server).getTime()
        })

        binded = orderData.map(item => {

            let dif = ((new Date().getTime()) - (new Date(item.data_update_server).getTime())) / 1000 / 60
            if (dif > TIME_LIMIT) item.trabalho_status = 'DESCONECTADO'

            let obj = {}
            obj.monitorar = iconMonitor(item)
            obj.status = iconStatus(item)
            obj.equipamento = item.equipamento.nome
            obj.tipo = tipo(item)
            obj.talhao = item.talhao
            obj.cultura = item.cultura.nome
            obj.area = area(item)
            if (usuarioLogado && usuarioLogado.tipo !== UserTypes.CLI) {
                obj.usuario = <Button onClick={() => redirectUsersWorks(item)}> {item.usuario.nome} </Button>
            }
            obj.data = DateFormat(new Date(item.data_inicio))
            obj.data_final = DateFormat(new Date(item.data_fim))
            obj.operador = item.operador
            obj.equipamento = <Button onClick={() => redirectWorksEquipaments(item)}> {item.equipamento.nome} </Button>

            return obj

        })
        return binded;
    }


    function redirectMonitoring(item) {
        history.push(Pages.routes.Monitor)
    }
    function redirectWorks(item) {
        onSelect(item)
        history.push(Pages.routes.Works + "/" + item.id)
    }

    function redirectUsersWorks(item) {
        if (item.tipo === UserTypes.CLI) {
            history.push(`/customer/${item.id}`)
        } else {
            <Snackbar
                open={true}
                message={'Usuário não é cliente'}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        }
    }

    function redirectWorksEquipaments(item) {
        history.push(Pages.routes.Works + "/" + item.equipamento.id)
    }

    function iconStatus(item) {



        let iconeStatus, tooltipMessage = item.trabalho_status



        if (!item.modo_resumo || item.modo_resumo == null) {
            if (item.sincronismo) {
                if (item.sincronismo.sincronismo_status === 'CONCLUIDO') {
                    iconeStatus = (<IconButton> <DoneIcon color='success' /> </IconButton>)
                } else {
                    iconeStatus = (<CircularProgress style={{ width: '25px', height: '25px' }} />)
                }
            } else {
                iconeStatus = (<CircularProgress style={{ width: '25px', height: '25px' }} />)
            }
        }
        //#TODO FAZER A VERIFICAÇÃO DE STATUS

        if (item.modo_resumo) {

            if (item.trabalho_status === 'DESCONECTADO') {
                iconeStatus = (<IconButton><SignalWifiOffIcon color='disabled' /> </IconButton>)
            }

            else if (item.trabalho_status === 'PARADO') { //TODO VER COMOVAI FICAR AQUI 
                iconeStatus = (<IconButton><Agriculture color='error' /></IconButton>)
            }

            else if (item.trabalho_status === 'INICIADO') { ////TODO VER COMOVAI FICAR AQUI 
                iconeStatus = (<IconButton> <Agriculture color='success' /> </IconButton>)
            }

        }

        return (
            <Tooltip title={item.trabalho_status}>
                {iconeStatus}
            </Tooltip>
        )
    }

    function area(item) {
        if (!item.plantio && !item.pulv) {
            return '----'
        } else if (item.plantio && item.plantio.plantio_ponto) {
            try {
                return item.plantio.plantio_ponto.areaplantada_ha.toFixed(3)
            } catch (error) {
                return '----'
            }
        } else if (item.pulv && item.pulv.pulv_ponto) {
            try {
                return item.pulv.pulv_ponto.area_pulverizada.toFixed(3)
            } catch (error) {
                return '----'
            }
        } else if (item.plantio && item.pulv && item.plantio.plantio_ponto && item.pulv.pulv_ponto) {
            try {
                return ((item.plantio.plantio_ponto.areaplantada_ha) + (item.pulv.pulv_ponto.area_pulverizada)).toFixed(3)
            } catch (error) {
                return '----'
            }
        }
    }

    function tipo(item) {
        if (item.plantio && item.pulv) {
            return <Typography>
                <span style={{ color: '#15ae06' }}> - Plantio </span>
                <br />
                <span style={{ color: '#03a9f4' }}> - Pulverização</span>
            </Typography>
        }
        if (!item.plantio && item.pulv) {
            return <Typography style={{ color: '#03a9f4' }}> - Pulverização</Typography>
        }
        if (item.plantio && !item.pulv) {
            return <Typography style={{ color: '#15ae06' }}> - Plantio</Typography>
        }
        if (!item.plantio && !item.pulv) {
            return <Typography> ---- </Typography>
        }

    }

    function iconMonitor(item) {

        let iconeMonitor, tooltipMessage = 'Ver detalhe'

        if (!item.modo_resumo && item.sincronismo) {

            if (item.sincronismo.sincronismo_status === 'CONCLUIDO') { //#TODO adicionar conferir se vai ser por sincronismo_status 
                iconeMonitor = (<Button onClick={() => redirectWorks(item)}><SearchIcon color='success' /> </Button>)
            } else {
                iconeMonitor = (<Button disabled><SearchIcon color='disabled' /></Button>)
            }
        }
        //

        else if (item.modo_resumo && item.sincronismo) {

            if (item.trabalho_status === 'DESCONECTADO') {
                iconeMonitor = (<Button onClick={() => redirectMonitoring(item)}><PlayCircleFilledIcon color='success' /> </Button>)
            }

            if (item.trabalho_status === 'PARADO') { //TODO VER COMOVAI FICAR AQUI 
                iconeMonitor = (<Button onClick={() => redirectMonitoring(item)}><PlayCircleFilledIcon color='success' /> </Button>)
            }

            if (item.trabalho_status === 'INICIADO') { ////TODO VER COMOVAI FICAR AQUI 
                iconeMonitor = (<Button onClick={() => redirectMonitoring(item)}><PlayCircleFilledIcon color='success' /> </Button>)
            }

        }

        else if (!item.modo_resumo && !item.sincronismo) {
            iconeMonitor = (<Button disabled><SearchIcon color='disabled' /></Button>)
        }

        return (
            <Tooltip title={tooltipMessage}>
                {iconeMonitor}
            </Tooltip>
        )
    }


    return (
        <ProjectTable
            onViewMore={onViewMore}
            title={title}
            rowsPerPage={RowsPerPage}
            style={{ flex: 1, whiteSpace: 'nowrap', width: 500 }}
            loading={loading}
            headers={headers}
            items={itemnsTable}
            onRefresh={refresh} />
    );
}

export default WorksList;


