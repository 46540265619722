import React, { useState } from 'react';
import values from '../../values/values'

function RowContainerItem({minWidth, margin, style, children}) {
    return (
        <div style={{
                flex: 1, 
                minWidth: minWidth ? minWidth : 200, 
                margin: margin ? margin : values.margin[2],
                ...style
                }}>
            {children}
        </div>
    );
}

export default RowContainerItem;