import { Stack, Typography } from '@mui/material';
import React from 'react';
import colors from '../../values/colors';
import strings from '../../values/strings/strings';

function SaframaxLogo(props) {
    return (
        <div style={{ justifyContent: 'center', display: 'flex', padding: 20 }}>
            <Stack style={{ alignItems: 'center' }}>
                {/* <img src="../../assets/images/logo_light.png" alt="Logo" style={{ width: 100, height: 100 }} /> */}
                <Typography variant="h4" color='#ffca28' align="center">{strings.getString('app_name').toUpperCase()}</Typography>
            </Stack>
        </div>
    );
}

export default SaframaxLogo;