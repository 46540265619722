/*
NAME: ButtonEdit
DESC: 
props:
- 
- - - - Default
- style: Object, style object to overwrite this component style
- children: Component, renders component childs
NOTES:

*/
import EditIcon from '@mui/icons-material/Edit';
import { Button, Fab } from '@mui/material'; 

function ButtonEdit(props) {
   return (
         <Fab size='small' style={{...props.style}} variant='contained' color='primary' onClick={props.onClick}><EditIcon /></Fab>
   );
}

export default ButtonEdit;