/*
NAME: MonitoringItem
DESC: Componente resposavel por exibir um equipamento na lista de equipamentos especifico por meio do ID
props:
- item: Array de objetos, de um determinado equipamento
- - - - Default
- style: Object, style object to overwrite this component style

NOTES:
#TODO - Não está sendo opssivel monitorar os trabalhos do inacio, pois provavlemente estão finalizados no servidor 
*/

import { IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import BasicCard from "../basic/BasicCard";
import values from '../../values/values'
import { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';
import DateFormat from "../../usecases/DateFormat";
import strings from "../../values/strings/strings";
import MonitorChartLimits from "../specific/MonitorChartLimits";
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PersonIcon from '@mui/icons-material/Person';
import MapIcon from '@mui/icons-material/Map';
import TimelineIcon from '@mui/icons-material/Timeline';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import RoomIcon from '@mui/icons-material/Room';
import TextureIcon from '@mui/icons-material/Texture';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ExpandIcon from '@mui/icons-material/Expand';
import ReportIcon from '@mui/icons-material/Report';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import WorkGetErrorVelocidade from "../../usecases/WorkGetErrorVelocidade";
import WorkGetErrorTemperature from "../../usecases/WorkGetErrorTemperature";
import WorkGetErrorSeedsMeter from "../../usecases/WorkGetErrorSeedsMeter";
import WorkGetErrotCurrentPressure from "../../usecases/WorkGetErrotCurrentPressure";
import WorksPulvSectionDisplay from "../specific/WorksPulvSectionDisplay";
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import Agriculture from '@mui/icons-material/Agriculture';
import ErrorCard from "../basic/ErrorCard";
import AutorenewIcon from '@mui/icons-material/Autorenew'
import MapPanel from "../basic/MapPanel";
import APIManager from "../../connection/APIManager";
import AuthBuildHeaderUserObject from "../../usecases/AuthBuildHeaderUserObject";
import WorksGetMonitoring from "../../usecases/WorksGetMonitoring";
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ArrowBackRounded } from "@mui/icons-material";

const TIME_LIMIT = 10



function MonitoringItem({ item, style }) {

    const [updater, setUpdater] = useState(null)
    const [loading, setloading] = useState(null)
    const [trabalho, settrabalho] = useState({})
    const [horasTrabalhadas, sethorasTrabalhadas] = useState(null)
    const [distanciaPercorrida, setdistanciaPercorrida] = useState(null)
    const [operador, setoperador] = useState(null)
    const [talhao, settalhao] = useState(null)
    const [velocidadeMedia, setvelocidadeMedia] = useState("- -")
    const [areaPlantada, setareaPlantada] = useState(null)
    const [sementesMetro, setsementesMetro] = useState(null)
    const [sementesHectare, setsementesHectare] = useState(null)
    const [populacaoTotal, setpopulacaoTotal] = useState(null)
    const [populacaoMedia, setpopulacaoMedia] = useState(null)
    const [linhasPlantadeiraMedia, setlinhasPlantadeira] = useState(null)
    const [areaPulverizada, setareaPulverizada] = useState(null)
    const [pressaoAtual, setpressaoAtual] = useState(null)
    const [vazaoAlvo, setvazaoAlvo] = useState(null)
    const [vazaoAtual, setvazaoAtual] = useState(null)
    const [vazaoAtualMin, setvazaoAtualMin] = useState(null)
    const [litrosConsumidos, setlitrosConsumidos] = useState(null)
    const [temperaturaAtual, settemperaturaAtual] = useState(null)
    const [populacaoMedialinha, setpopulacaoMedialinha] = useState(null)
    const [configVelMax, setconfigVelMax] = useState(null)
    const [configTemperaturaMax, setconfigTemperaturaMax] = useState(0)
    const [pressaoMinPsi, setpressaoMinPsi] = useState(0)
    const [pressaoMaxPsi, setpressaoMaxPsi] = useState(0)
    const [taxaSementesMetroMax, settaxaSementesMetroMax] = useState(0)
    const [taxaSementesMetroMin, settaxaSementesMetroMin] = useState(0)
    const [taxaSementesMetro, settaxaSementesMetro] = useState(0)
    const [antemaAlturaM, setantemaAlturaM] = useState(0)
    const [especamentoLinhas, setespecamentoLinhas] = useState(0)
    const [velocidadeMaxPermitida, setvelocidadeMaxPermitida] = useState(0)
    const [erroTemperaturaAtual, seterroTemperaturaAtual] = useState(false)
    const [validaVelocidade, setvalidaVelocidade] = useState(false)
    const [erroSementeMetro, seterroSementeMetro] = useState(false)
    const [erroPressaoAtual, seterroPressaoAtual] = useState(false)
    const [diftime, setdiftime] = useState(null)
    const [marker, setMarker] = useState([])
    const [linhas, setLinhas] = useState([])
    const [centerMap, setcenterMap] = useState(null)
    const [progress, setProgress] = useState(0)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();


    useEffect(() => {
        getWorkData()

        const interval = setInterval(() => {
            getWorkData()

        }, 7000)

        return () => clearInterval(interval)
    }, [])

    const getWorkData = async () => {
        if (loading) return
        setloading(true)

        console.log('Item de trabalho: ', item)
        const trabalho = await WorksGetMonitoring(item.id, false)
        console.log('Dados de trabalho: ', trabalho)
        if (trabalho && trabalho.length > 0) {
            settrabalho(trabalho[0]);
            bindData(trabalho[0]);
            loadMapData(trabalho[0]);
        } else {
            console.error('Nenhum dado de trabalho retornado pela API');
        }
        setloading(false)
    }

    function goBack() {
        history.push({
            pathname: '/monitor',
        });
        window.location.reload();
    }


    const loadMapData = (trabalho) => {
        const trabalhoId = trabalho.id;

        const fetchData = async () => {
            try {
                const obj = await AuthBuildHeaderUserObject();
                const headers = {
                    ...obj,
                    trabalho_id: trabalhoId,
                };

                const response = await APIManager.send('/trabalho/histcoords', 'GET', headers);
                const json = await response.json();

                polilinhas(json);
            } catch (error) {
                console.error('Erro ao carregar dados da API:', error);
            }
        };

        fetchData()

    };

    const polilinhas = (trabalho) => {

        const coords = trabalho.map(item => ({
            lat: item.lat,
            lng: item.lng,
        }));


        const lastPonto = {
            latitude: trabalho[trabalho.length - 1].lat,
            longitude: trabalho[trabalho.length - 1].lng,
            coordinateType: 'last',
        }
        const firstPonto = {
            latitude: trabalho[0].lat,
            longitude: trabalho[0].lng,
            coordinateType: 'first',
        }

        let markers = [lastPonto, firstPonto]
        const centerMap = markers.length > 0 ? { lat: markers[0].latitude, lng: markers[0].longitude } : null;


        const linhas = [{
            path: coords,
            color: '#19d251',

        }]

        setMarker(markers);
        setLinhas(linhas);
        setcenterMap(centerMap);
        setProgress(prevProgress => prevProgress + 25)
    }

    console.log('Lista de dados: ', marker)


    const zoomMap = 15;



    const bindData = (trabalho) => { //#TODO - Mudar item para trabalho
        //Dados Gerais
        let op = ' - - '
        if (trabalho.operador) { op = trabalho.operador }
        setoperador(op)

        let talh = ' - - '
        if (trabalho.talhao) { talh = trabalho.talhao }
        settalhao(talh)

        let vel = ' - - '
        if (trabalho.coordenada) { vel = trabalho.coordenada.velocidade_antena_kmh.toFixed(1) + ' km/h' } //condicional de velocidade
        setvelocidadeMedia(vel)

        setvalidaVelocidade(WorkGetErrorVelocidade(trabalho))

        let dist = ' - - '
        if (trabalho.pulv && trabalho.pulv.pulv_ponto) {
            dist = trabalho.pulv.pulv_ponto.metros_percorridos.toFixed(2) + ` m`  //condicional de distancia percorrida
        } else {
            dist = 0.00 + ` m `
        }
        setdistanciaPercorrida(dist)

        //Dados de Plantio

        if (trabalho.plantio && trabalho.plantio.plantio_ponto) {
            let areaPlant = ' 0,00 ha'
            if (trabalho.plantio) { areaPlant = trabalho.plantio.plantio_ponto.areaplantada_ha + ` ha` }  //condicional de area plantada
            setareaPlantada(areaPlant)

            let sementesM = ' - - '
            if (trabalho.plantio) { sementesM = trabalho.plantio.plantio_ponto.sementes_metro_total.toFixed(2) + ` s/m` }  //condicional de sementes por metro
            setsementesMetro(sementesM)

            seterroSementeMetro(WorkGetErrorSeedsMeter(trabalho))

            let sementesH = ' - - '
            if (trabalho.plantio) { sementesH = trabalho.plantio.plantio_ponto.sementes_hectare_total.toFixed(2) + ` s/ha` }  //condicional de sementes por hectare
            setsementesHectare(sementesH)

            let populacaoT = ' - - '
            if (trabalho.plantio) { populacaoT = trabalho.plantio.plantio_ponto.populacao.toFixed(2) + ` ppl` }   //condicional de populacao total
            setpopulacaoTotal(populacaoT)

            let populacaoM = ' - - '
            if (trabalho.plantio) { populacaoM = trabalho.plantio.plantio_ponto.populacao_media_linha + ` ppl/ha` }  //condicional de populacao media
            setpopulacaoMedia(populacaoM)

            let linhasPlant = ' - - '
            if (trabalho.plantio) { linhasPlant = trabalho.plantio.plantio_ponto.linhas.length }  //condicional de quantidade de linhas da plantadeira
            setlinhasPlantadeira(linhasPlant)

            let popMediaLinha = ' - - '
            if (trabalho.plantio) { popMediaLinha = trabalho.plantio.plantio_ponto.linhas }
            setpopulacaoMedialinha(popMediaLinha)
        }

        ////pulverizacao
        if (trabalho.pulv && trabalho.pulv.pulv_ponto) {
            let areaPulv = ' - - '
            if (trabalho.pulv) { areaPulv = trabalho.pulv.pulv_ponto.area_pulverizada + ` ha` }  //condicional de area pulverizada
            setareaPulverizada(areaPulv)

            let pressao = ' - - '
            if (trabalho.pulv) { pressao = trabalho.pulv.pulv_ponto.pressao_atual_psi.toFixed(2) + ` psi` } //condicional de pressao atual
            setpressaoAtual(pressao)

            seterroPressaoAtual(WorkGetErrotCurrentPressure(trabalho))

            let temperatura = ' - - '
            if (trabalho.pulv) { temperatura = trabalho.pulv.pulv_ponto.temperatura + ` ºC` }  //condicional de temperatura atual
            settemperaturaAtual(temperatura)

            seterroTemperaturaAtual(WorkGetErrorTemperature(trabalho))

            let litro = ' - - '
            if (trabalho.pulv) { litro = trabalho.pulv.pulv_ponto.litros_consumidos.toFixed(2) + ` L` } //condicional de litros Consumidos
            setlitrosConsumidos(litro)
        }

        let maxTemp = ' - - '
        if (trabalho.pulv) { maxTemp = trabalho.pulv.config_temp_max }  //condicional de temperatura maxima
        setconfigTemperaturaMax(maxTemp)

        let PressaoMinPsi = ' - - '
        if (trabalho.pulv) { PressaoMinPsi = trabalho.pulv.config_pressao_min_psi }  //condicional de pressao minima
        setpressaoMinPsi(PressaoMinPsi)

        let PressaoMaxPsi = ' - - '
        if (trabalho.pulv) { PressaoMaxPsi = trabalho.pulv.config_pressao_max_psi }  //condicional de pressao maxima
        setpressaoMaxPsi(PressaoMaxPsi)

        let TaxaSementeMetroMax = ' - - '
        if (trabalho.plantio) { TaxaSementeMetroMax = trabalho.plantio.config_sementesmetro_max }  //condicional de taxa de sementes por metro
        settaxaSementesMetroMax(TaxaSementeMetroMax)

        let TaxaSementeMetroMin = ' - - '
        if (trabalho.plantio) { TaxaSementeMetroMin = trabalho.plantio.config_sementesmetro_min }  //condicional de taxa de sementes por metro
        settaxaSementesMetroMin(TaxaSementeMetroMin)

        let TaxaSementeMetro = ' - - '
        if (trabalho.plantio) { TaxaSementeMetro = trabalho.plantio.config_sementesmetro }  //condicional de taxa de sementes por metro
        settaxaSementesMetro(TaxaSementeMetro)

        let AntenaAlturaM = ' - - '
        if (trabalho.antena_altura_m) { AntenaAlturaM = trabalho.antena_altura_m }  //condicional de taxa de sementes por metro
        setantemaAlturaM(AntenaAlturaM)

        let EspacamentoLinhas = ' - - '
        if (trabalho.plantio) { EspacamentoLinhas = trabalho.plantio.config_espacamento_cm }  //condicional de taxa de sementes por metro
        setespecamentoLinhas(EspacamentoLinhas)

        let VelocidadeMaxPermitida = ' - - '
        if (trabalho.plantio) { VelocidadeMaxPermitida = trabalho.plantio.config_vel_kmh_max }  //condicional de taxa de sementes por metro
        setvelocidadeMaxPermitida(VelocidadeMaxPermitida)


        // Diferença de tempo entre a data do trabalho ea data atual para verificar o status do trabalho    
        let now = new Date()
        let date = new Date(trabalho.data_update_server)
        let dif = now.getTime() - date.getTime()
        dif = dif / 1000 / 60
        setdiftime(dif)

        //Horas Trabalhadas
        //#TODO: Ver como vai por plantio_ponto neste condicional pois quando é colocado dá como undefined mesmo exustindo a propiedade no obj

        if (trabalho.plantio && trabalho.plantio.plantio_ponto) {

            let milliseconds = trabalho.plantio.plantio_ponto.millsec_trabalhados
            let horas = milliseconds / 1000 / 60 / 60
            let minutos = horas - Math.floor(horas)
            minutos = minutos * 60

            sethorasTrabalhadas(Math.floor(horas) + 'h' + Math.floor(minutos) + 'min')
        }


        //Vazão
        if (trabalho.pulv && trabalho.pulv.pulv_ponto) {
            setvazaoAlvo(trabalho.pulv.pulv_ponto.vazao_geral_lha)
            setvazaoAtual(trabalho.pulv.pulv_ponto.vazao_atual_lha)
            setvazaoAtualMin(trabalho.pulv.pulv_ponto.vazao_atual_lmin)
        } else {
            setvazaoAlvo('----')
            setvazaoAtual('----')
            setvazaoAtualMin('----')
        }

        setProgress(prevProgress => prevProgress + 50)

    }

    const renderTrabalhoStatus = () => {

        if (!trabalho.trabalho_status) return <Alert variant="filled" icon={<AutorenewIcon />} severity="info">OBTENDO DADOS ...</Alert>

        if (diftime >= TIME_LIMIT) {
            return (
                <Alert variant="filled" icon={<SignalWifiOffIcon />} style={{ backgroundColor: '#757575' }} > TRABALHO DESCONECTADO</Alert>
            )
        }
        if (trabalho.trabalho_status === 'FINALIZADO') {
            return (
                <Alert variant="filled" severity="success">TRABALHO FINALIZADO</Alert>
            )
        }
        if (trabalho.trabalho_status === 'PARADO') {
            return (
                <Alert variant="filled" severity='warning'> TRABALHO PARADO</Alert>
            )
        }

        if (trabalho.trabalho_status === 'INICIADO') {
            return (
                <Alert variant="filled" icon={<Agriculture />}> TRABALHO INICIADO </Alert>
            )
        }

    }

    console.log('Dados de população linha: ', populacaoMedialinha)
    return (
        <>
            <IconButton
                onClick={() => { goBack() }}
                style={{
                    color: '#F8C102',
                    margin: '20px 0',
                    alignSelf: 'start',
                    padding: '10px',
                    backgroundColor: '#333',
                    borderRadius: '50%',
                    transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#555'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#333'}
            >
                <ArrowBackRounded />
            </IconButton>
            {trabalho && //#TODO - Solução provissória para não dar erro de null
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', width: isMobile ? '400px' : '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '50%', marginRight: isMobile ? '0' : values.margin[2] }}>
                        {renderTrabalhoStatus()}
                        <BasicCard style={{ marginBottom: values.margin[1], }} title={strings.getString('screen_monitorItem_general_data')} color={'primary'} >
                            <div style={{ flexWrap: 'nowrap', padding: 5, margin: 5, width: '100%' }}>
                                <TextField label={strings.getString('screen_monitorItem_date_initial')} style={{ margin: 5, }} value={DateFormat(new Date(trabalho.data_cad_server))} InputProps={{ startAdornment: (<InputAdornment position="start"><DateRangeIcon /></InputAdornment>), }} variant="standard" />
                                <TextField label={strings.getString('screen_monitorItem_speed_actual')} style={{ margin: 5 }} error={validaVelocidade} value={velocidadeMedia} InputProps={{ startAdornment: (<InputAdornment position="start"><SpeedIcon /></InputAdornment>), }} variant="standard" />
                                <TextField label={strings.getString('screen_monitorItem_hours_work')} style={{ margin: 5 }} value={horasTrabalhadas} InputProps={{ startAdornment: (<InputAdornment position="start"><AccessTimeIcon /></InputAdornment>), }} variant="standard" />
                                <TextField label={strings.getString('screen_monitorItem_distance_traveled')} style={{ margin: 5 }} value={distanciaPercorrida} InputProps={{ startAdornment: (<InputAdornment position="start"><TrendingUpIcon /></InputAdornment>), }} variant="standard" />
                                <TextField label={strings.getString('info_operator')} style={{ margin: 5 }} value={operador} InputProps={{ startAdornment: (<InputAdornment position="start"><PersonIcon /></InputAdornment>), }} variant="standard" />
                                <TextField label={strings.getString('unity_talhao')} style={{ margin: 5 }} value={talhao} InputProps={{ startAdornment: (<InputAdornment position="start"><MapIcon /></InputAdornment>), }} variant="standard" />

                                <Typography style={{ marginTop: values.margin[1] }} color='primary'>{strings.getString('screen_monitorItem_general_settings')}</Typography>
                                <TextField label={strings.getString('screen_monitorItem_number_lines')} style={{ margin: 5 }} value={linhasPlantadeiraMedia} InputProps={{ startAdornment: (<InputAdornment position="start"><ViewHeadlineIcon /></InputAdornment>), }} variant="standard" />
                                <TextField label={strings.getString('screen_monitorItem_antenna_height')} style={{ margin: 5 }} value={antemaAlturaM} InputProps={{ startAdornment: (<InputAdornment position="start"><VerticalAlignTopIcon /></InputAdornment>), }} variant="standard" />
                                <TextField label={strings.getString('screen_monitorItem_line_spacing')} style={{ margin: 5 }} value={especamentoLinhas} InputProps={{ startAdornment: (<InputAdornment position="start"><ExpandIcon /></InputAdornment>), }} variant="standard" />
                                <TextField label={strings.getString('warn_speed_max_permited')} style={{ margin: 5 }} value={velocidadeMaxPermitida} InputProps={{ startAdornment: (<InputAdornment position="start"><ReportIcon /></InputAdornment>), }} variant="standard" />

                            </div>
                        </BasicCard>
                        {trabalho.plantio && trabalho.plantio.plantio_ponto &&
                            <BasicCard style={{ marginBottom: values.margin[1], }}>
                                <Typography style={{ marginBottom: values.margin[1] }} color='primary'>{strings.getString('screen_monitorItem_planting_data')}</Typography>
                                <div style={{ flexWrap: 'wrap', padding: 5, margin: 5, justifyContent: 'space-around', }}>
                                    <TextField label={strings.getString('unity_area')} style={{ margin: 5 }} value={areaPlantada} InputProps={{ startAdornment: (<InputAdornment position="start"><RoomIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_seeds_meter')} style={{ margin: 5 }} error={erroSementeMetro} value={sementesMetro} InputProps={{ startAdornment: (<InputAdornment position="start"><ScatterPlotIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_seeds_hectare')} style={{ margin: 5 }} value={sementesHectare} InputProps={{ startAdornment: (<InputAdornment position="start"><ScatterPlotIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_total_population')} style={{ margin: 5 }} value={populacaoTotal} InputProps={{ startAdornment: (<InputAdornment position="start"><TextureIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_average_population_lines')} style={{ margin: 5 }} value={populacaoMedia} InputProps={{ startAdornment: (<InputAdornment position="start"><DragIndicatorIcon /></InputAdornment>), }} variant="standard" />
                                    {/* */}

                                    <Typography style={{ marginTop: values.margin[1] }} color='primary'>{strings.getString('screen_monitorItem_planting_settings')}</Typography>
                                    <TextField label={strings.getString('screen_monitorItem_maximum_seed_rate')} style={{ margin: 5 }} value={taxaSementesMetroMax} InputProps={{ startAdornment: (<InputAdornment position="start"><ArrowUpwardIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_seed_target_rate')} style={{ margin: 5 }} value={taxaSementesMetro} InputProps={{ startAdornment: (<InputAdornment position="start"><DoneIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_minimum_seed_rate')} style={{ margin: 5 }} value={taxaSementesMetroMin} InputProps={{ startAdornment: (<InputAdornment position="start"><ArrowDownwardIcon /></InputAdornment>), }} variant="standard" />

                                </div>
                            </BasicCard>}
                        {trabalho.pulv && trabalho.pulv.pulv_ponto &&
                            <BasicCard >
                                <Typography style={{ marginBottom: values.margin[1] }} color='primary'>{strings.getString('screen_monitorItem_spray_data')}</Typography>
                                <div style={{ flexWrap: 'wrap', padding: 5, margin: 5, justifyContent: 'space-around', }}>
                                    <TextField label={strings.getString('unity_area_pulverized')} style={{ margin: 5 }} value={areaPulverizada} InputProps={{ startAdornment: (<InputAdornment position="start"><RoomIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_current_pressure')} style={{ margin: 5 }} value={pressaoAtual} error={erroPressaoAtual} InputProps={{ startAdornment: (<InputAdornment position="start"><TimelineIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('unity_target_flow')} style={{ margin: 5 }} value={vazaoAlvo} InputProps={{ startAdornment: (<InputAdornment position="start"><BrokenImageIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_current_flow')} style={{ margin: 5 }} value={vazaoAtual} InputProps={{ startAdornment: (<InputAdornment position="start"><InvertColorsIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_current_flow_min')} style={{ margin: 5 }} value={vazaoAtualMin} InputProps={{ startAdornment: (<InputAdornment position="start"><InvertColorsIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('unity_liters_consumed')} style={{ margin: 5 }} value={litrosConsumidos} InputProps={{ startAdornment: (<InputAdornment position="start"><LocalDrinkIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_current_temperature')} style={{ margin: 5 }} value={temperaturaAtual} error={erroTemperaturaAtual} InputProps={{ startAdornment: (<InputAdornment position="start"><ThermostatIcon /></InputAdornment>), }} variant="standard" />

                                    <Typography style={{ marginTop: values.margin[1] }} color='primary'>{strings.getString('screen_monitorItem_spray_settings')}</Typography>
                                    <TextField label={strings.getString('screen_monitorItem_pressure_max')} style={{ margin: 5 }} value={pressaoMaxPsi} InputProps={{ startAdornment: (<InputAdornment position="start"><ArrowUpwardIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_pressure_min')} style={{ margin: 5 }} value={pressaoMinPsi} InputProps={{ startAdornment: (<InputAdornment position="start"><ArrowDownwardIcon /></InputAdornment>), }} variant="standard" />
                                    <TextField label={strings.getString('screen_monitorItem_temperature_max')} style={{ margin: 5 }} value={configTemperaturaMax} InputProps={{ startAdornment: (<InputAdornment position="start"><WarningAmberIcon /></InputAdornment>), }} variant="standard" />
                                </div>
                            </BasicCard>}
                        {trabalho.pulv && trabalho.pulv.pulv_ponto &&
                            <BasicCard style={{ marginTop: values.margin[1], marginBottom: values.margin[4] }} title={`${strings.getString('screen_monitorItem_section_indicator')} - ( + ${trabalho.pulv.pulv_ponto.sessoes.length})`}>
                                <WorksPulvSectionDisplay items={trabalho.pulv.pulv_ponto.sessoes} />
                            </BasicCard>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '50%', marginRight: isMobile ? '0' : values.margin[2], marginTop: isMobile ? values.margin[2] : '0' }}>
                        <Stack spacing={3}>
                            <MapPanel markers={marker} zoomMap={zoomMap} centerMap={centerMap} linhas={linhas} height={790} />
                        </Stack>
                        {trabalho.plantio && trabalho.plantio.plantio_ponto &&
                            <BasicCard style={{ padding: '10px', marginTop: values.margin[2] }}>
                                <MonitorChartLimits
                                    linhas={populacaoMedialinha}
                                    item={trabalho.plantio}
                                    style={{ width: '100%', height: '350px' }}
                                />
                            </BasicCard>}
                    </div>
                </div>}
            {!trabalho &&
                <ErrorCard
                    page='monitor'
                    title='Impossível encontrar o registro'
                    message='Não foi possível monitorar o trabalho, se o erro persistir entre em contato com o nosso suporte'
                />
            }
        </>
    )
}

export default MonitoringItem;

/*

    console.log('trabalho.plantio.plantio_ponto.millsec_trabalhados ',trabalho.plantio.plantio_ponto.millsec_trabalhados )
            let minutos =  (trabalho.plantio.plantio_ponto.millsec_trabalhados / 1000) / 60;
            let horas = minutos / 60;
            let horasTrabalhadas = horas.toFixed(2) + ` h`
            sethorasTrabalhadas(horasTrabalhadas)
           // horas = horas.getHours() + ':' + horas.getMinutes()
           // sethorasTrabalhadas((horas)) //#TODO: verificar se é correto arredondar */

/*
 transforma o mili em minutos, e arrendonda pra baixo
     ex: 35468469 mili
     min: 591,14115
     floor: 591
     horas: 591/60 = 9,85
     arredonda pra baixo: 9
     horas: 9 minutos: 591
     minutos: 591 - 9HORAS (540min)
     minutos 51min
     9h:51min

 transforma em horas, desconta o decimal e converte o decimal em minutos (arrendondando para baixo)
 ex: 35468469 mili
 horas: 9,85
 arrendonda pra baixo: 9
 minutos = 9,85 - 9
 minutos = 0,85
 minutos = 0,85 * 60 = 51

 9h:51min
 


*/


