
import { Card, CardContent, Stack, Typography } from "@mui/material";
/*
NAME: MessageShow
DESC: Componente para exibir diferentes tipos de mensagens
props:
- title: titulo da mensagem
- message: mensagem a ser exibida
- type: tipo da mensagem
- - - - Default
- style: Object, style object to overwrite this component style
- children: Component, renders component childs
NOTES:
Definir ainda se usará um Card ou não, vou deixar com card, se necessário remover no futuro
*/


function MessageShow(props) {
   return (                    
        <Stack spacing={4}>
            {props.title && <Typography variant="h4" color={props.type ? props.type : 'primary'}>{props.title}</Typography>}
            {props.message && <Typography>{props.message}</Typography>}
            {props.children}
        </Stack>       
   );
}

export default MessageShow;