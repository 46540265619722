const APIConstants = {
    //headers
    'header_auth_user' : "header_auth_user",
    'header_auth_login' : "header_auth_login",
    'header_auth_email' : "header_auth_email",
    'header_auth_pass' : "header_auth_pass",
    'header_auth_token' : "header_auth_token",
    'header_auth_user_type' : "header_auth_user_type",
    'header_error' : "header_error",
    'header_equipamento_id' : "header_equipamento_id",
    'header_install_uuid' : "header_install_uuid",
    'header_equipamento_modelo' : "header_equipamento_modelo",
    'header_equipamento_so' : "header_equipamento_so",
    'header_equipamento_app' : "header_equipamento_app",
    'header_equipamento_appversao' : "header_equipamento_appversao",
    'header_equipamento_apelido' : "header_equipamento_apelido",
    'header_plantio_datahorainicio' : "header_plantio_datahorainicio",
    'header_arquivo_tamanho' : "header_arquivo_tamanho",
    'header_arquivo_data' : "header_arquivo_data",
    'header_auth_idserver' : 'header_auth_idserver',
    'header_auth_word' : "header_auth_word",

    //Errors
    'EXISTS_EMAIL' : 'EXISTS_EMAIL',
    'EXISTS_CPFCNPJ': 'EXISTS_CPFCNPJ',
    
    //user type definitions
    'user_type_dev' : 'DEV',
    'user_type_cli' : 'CLI',
    'user_type_tec' : 'TEC',
    'user_type_adv' : 'DEV',

}

export default APIConstants