import ValidadeString from "./ValidateString";
import values from '../values/values';
import strings from '../values/strings/strings';
/*
USE CASE: ValidatePassword
Desc: Valida se a senha é valida ou vazia, 
ou se contem o numero minimo de caracteresexigidos
params: 
   - string, senha a ser validada
returns:
  - A promise that, se valida resolve null, se invalida resolve com a mensagem de erro
*/
const ValidatePassword = (password) => {
    return new Promise(async (resolve, reject) => {
        let stringInvalid = await ValidadeString(password)
        if (stringInvalid) {
            return resolve(strings.getString('ValidatePassword_warn_password_invalid'))
        }
        if(password.length < values.password_min_length || password.length > values.string_limit){
            return resolve(strings.getString('ValidatePassword_warn_password_length'))
        }
        return resolve()
    })
}
export default ValidatePassword;