import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Dashboard from './pages/Dashboard'
import makeStyles from '@mui/styles/makeStyles';
import HomePage from './pages/HomePage';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Monitor from './pages/Monitor';
import Works from './pages/Works';
import Equipments from './pages/Equipments';
import Customers from './pages/Customers';
import Tools from './pages/Tools';
import Users from './pages/Users';
import System from './pages/System';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import SignUp from './pages/SignUp';
import EmailConfirmation from './pages/EmailConfirmation';
import Pages from './pages/Pages';

import TestComponentShow from './pages/TestComponentShow';
import CacheLoadCredentials from './usecases/CacheLoadCredentials';
import { useEffect, useState } from 'react';
import APIConstants from './connection/APIConstants';
import CustomerInfo from './pages/CustomerInfo';

//#TODO - Verificar se todas as informações necessárias estão vindo da API para as tabelas, listas, gráficos e mapas que ustilizam

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  app: {
    height: '100%',
  }
})
//#TODO - Em todo lugar que for ter a renderização condicional deve-se ser adicionado um botão de voltar
function App() {

  const [user, setUser] = useState(null);

  useEffect(() => {
    const loadUserCredentials = async () => {
      const userData = await CacheLoadCredentials();
      setUser(userData);
    };

    loadUserCredentials();
  }, []);

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Router>
        <Switch>
          <Route path="/" exact><Login /></Route>

          <Route path={Pages.routes.Login}><Login /></Route>
          <Route path={Pages.routes.ResetPassword}><ResetPassword /></Route>
          <Route path={Pages.routes.EmailConfirmation}><EmailConfirmation /></Route>
          <Route path={Pages.routes.SignUp}><SignUp /></Route>

          <Route path={Pages.routes.Dashboard} exact><Dashboard /></Route>
          <Route path={Pages.routes.Monitor} ><Monitor /></Route>
          <Route path={Pages.routes.Works} ><Works /></Route>
          <Route path={Pages.routes.Equipments} ><Equipments /></Route>
          <Route path={Pages.routes.Customers} ><Customers /></Route>
          <Route path={Pages.routes.Customer} ><CustomerInfo /></Route>
          <Route path={Pages.routes.Tools} exact><Tools /></Route>
          <Route path={Pages.routes.Users} ><Users /></Route>
          {user && user.tipo === APIConstants.user_type_dev &&
            <Route path={Pages.routes.System} exact><System /></Route>}
          <Route path={Pages.routes.Profile} exact><Profile /></Route>
          <Route path={Pages.routes.Settings} exact><Settings /></Route>
          <Route path={"/test"} exact><TestComponentShow /></Route>


        </Switch>
      </Router>
    </div>
  );
}

export default App;
