import React, { useState, useEffect } from 'react';
import MapPanel from "../basic/MapPanel";
import MapLegend from '../specific/MapLegend';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';

function Map_litrosmin({ data, style }) {
    const [legends, setLegends] = useState([]);
    const [path, setPath] = useState([]);
    const [marker, setMarker] = useState([]);
    const [centerMap, setCenterMap] = useState(null);
    const [loadingMap, setLoadingMap] = useState(true);
    const [sliderValue, setSliderValue] = useState([0, 100]);
    const [realMin, setRealMin] = useState(0);
    const [realMax, setRealMax] = useState(100);


    const pHistory = data[1].histPulv;

    useEffect(() => {
        processPath();
    }, [legends])

    const listColor = [
        "#F4e538",
        "#FFCA28",
        "#FF5733",
        "#36e236",
        "#118e11",
    ];

    const createLegends = (newMin, newMax) => {
        const divisions = 5;
        const interval = (newMax - newMin) / divisions;
        const newLegends = [];

        newLegends.push({
            id: 0,
            min: null,
            max: newMin,
            color: '#ff0000',
            show: true
        });

        for (let i = 0; i < divisions; i++) {
            const minLimit = newMin + interval * i;
            const maxLimit = newMin + interval * (i + 1);
            newLegends.push({
                id: i + 1,
                min: parseFloat(minLimit.toFixed(1)),
                max: parseFloat(maxLimit.toFixed(1)),
                color: listColor[i],
                show: true
            });
        }

        newLegends.push({
            id: divisions + 1,
            min: newMax,
            max: null,
            color: '#1976d2',
            show: true
        });

        setLegends(newLegends);
        processPath();
    };


    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    const applyLimits = () => {
        const [newMin, newMax] = sliderValue;
        createLegends(newMin, newMax); // Recalcula as legendas
        processPath(); // Atualiza o mapa com as novas legendas
    };

    const processPath = () => {
        let currentPath = [];
        let currentColor = null;
        const paths = [];

        pHistory.forEach((item, index) => {
            const legend = legends.find(legend => legend.show && item.lmin >= legend.min && item.lmin <= legend.max);
            if (legend) {
                if (currentColor !== legend.color) {
                    if (currentPath.length > 0) {
                        paths.push({ path: currentPath, color: currentColor });
                    }
                    currentPath = [];
                    currentColor = legend.color;
                }
                currentPath.push({ lat: item.c_lat, lng: item.c_lng });
            }

            if (index === pHistory.length - 1 && currentPath.length > 0) {
                paths.push({ path: currentPath, color: currentColor });
            }
        });

        setPath(paths);
    };

    const handleLegendChange = (legendId) => {
        const legendsCopy = [...legends];
        legendsCopy[legendId].show = !legendsCopy[legendId].show;
        setLegends(legendsCopy);
        console.log('Legends', legends);
        processPath();
    };

    useEffect(() => {
        if (pHistory && pHistory.length > 0) {
            const min = pHistory.reduce((acc, item) => item.lmin > 0 && item.lmin < acc ? item.lmin : acc, Number.MAX_VALUE);
            const max = pHistory.reduce((acc, item) => item.lmin > acc ? item.lmin : acc, 0);

            // Atualize os estados realMin e realMax
            const newRealMin = min !== Number.MAX_VALUE ? min : 0;
            const newRealMax = max;

            setRealMin(newRealMin);
            setRealMax(newRealMax);

            setSliderValue([newRealMin, newRealMax]);
            createLegends(newRealMin, newRealMax);
            processPath();

            setLoadingMap(false);

            setMarker([
                { latitude: pHistory[0].c_lat, longitude: pHistory[0].c_lng, coordinateType: 'first' },
                { latitude: pHistory[pHistory.length - 1].c_lat, longitude: pHistory[pHistory.length - 1].c_lng, coordinateType: 'last' }
            ]);
            setCenterMap({ lat: pHistory[0].c_lat, lng: pHistory[0].c_lng });
        }
    }, [pHistory]);



    return (
        <div style={{ minWidth: '480px', alignItems: 'center' }}>
            <h3>Vazão de litros por hectares durante o trabalho</h3>
            {loadingMap ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px' }}>
                    <div className="spinner"></div>
                </div>

            ) : (
                <>
                    <MapPanel markers={marker} zoomMap={17} centerMap={centerMap} linhas={path} height={600} width={style} />
                    <MapLegend legends={legends} onLegendChange={handleLegendChange} />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                        <Slider
                            style={{ width: '60%', marginRight: '20px' }}
                            value={sliderValue}
                            onChange={handleSliderChange}
                            valueLabelDisplay="auto"
                            min={realMin}
                            max={realMax}
                        />
                        <Button
                            onClick={applyLimits}
                            style={{ backgroundColor: '#ffca20', color: '#000', borderRadius: '5px' }}
                        >
                            Aplicar Limites
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Map_litrosmin;
