import { Paper } from '@mui/material';
import React from 'react';
import Chart from 'react-google-charts';

function ChartTest(props) {
    return (   
        <div style={{display: 'flex', justifyContent: 'center', ...props.style}}>
          
            <Chart             
                chartType="PieChart"
                data={[
                    ['Task', 'Hours per Day'],
                    ['Work', 11],
                    ['Eat', 2],
                    ['Commute', 2],
                    ['Watch TV', 2],
                    ['Sleep', 7],
                ]}
                options={{
                    legend: 'none',
                    title: 'My Daily Activities',
                    // Just add this option
                    pieHole: 0.5,
                    backgroundColor: { fill:'transparent'},                    
                    legendTextStyle: { color: '#FFF' },                    
                    titleTextStyle: { color: '#FFF' },
                    chartArea: {
                        backgroundColor: {
                            fill: '#F00',
                            stroke: '#F0F',
                            strokeWidth: 5
                        },
                    },
                    pieSliceBorderColor: "transparent",
                    pieSliceBorderWidth: 5,
                }}
                width={400}
                height={400}
            />    
            
        </div>     
    );
}

export default ChartTest;