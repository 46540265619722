import CacheKeys from "../models/CacheKeys";

/*
USE CASE: CacheClearCredentials
Desc: Clear the credentials/session values of the localStorage
params: 
   - NONE
returns:
  - A promise that: resolve after clearing the localStorage with the session key 
*/

const CacheClearCredentials = () => {
    return new Promise((resolve, reject) => {
        localStorage.removeItem(CacheKeys.session)        
        resolve()
    })
}
export default CacheClearCredentials;