/*
USE CASE: ValidateInteger
Desc: Valida se uma string apenas contem numeros inteiros
params: 
   - string, string a ser validada
returns:
  - A promise that, se valida resolve(null), se nao resolve(true)
*/
const ValidateInteger = (val) => {
    return new Promise((resolve, reject) => {
        if (val.match(/^[0-9]+$/)) {
            resolve(null);
        } else {
            resolve(true);
        }        
    })
}
export default ValidateInteger;