import CacheKeys from "../models/CacheKeys";

/*
USE CASE: CacheStoreCredentials
Desc: Store the user data on the localStorage
params: 
   - credentials: Object of the user
returns:
  - A promise that: save the data then resolve
*/

const CacheStoreCredentials = (credentials) => {
    return new Promise((resolve, reject) => {        
        localStorage.setItem(CacheKeys.session,JSON.stringify(credentials)) 
        resolve()
    })
}
export default CacheStoreCredentials;