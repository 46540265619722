import APIManager from "../connection/APIManager"

/*
USE CASE: AuthNewUserEmailToken
Desc: Valida o token gerado quando criado a conta e enviado para o email
params: 
   - token: string, token a ser validado
returns:
  - A promise that, evia para a API o token criado e resolve() se for válido e reject() se for inválido
*/
const AuthNewUserEmailToken = (token) => {
    return new Promise((resolve, reject) => {
        
        let header = {
            token: token
        }
        APIManager.send("/confirmacaoemail","PUT",header,null,200).then((res) => {
            console.log(res)
            return resolve(res)
        }).catch((e) => {
            return reject(e)
        })
    })
}
export default AuthNewUserEmailToken;