/*
NAME: UsersList
DESC: Componente responsavel por listar o array de objetos contendo usuarios
props: 
- usuarioLogado: Tipo de usuario logado
- items: Array de objetos contendo usuarios
-refresh: Função que vem de um componente pai para atualizar a lista de usuarios através de um get
- RowsPerPage: Quantidade de linhas por pagina na tabela
- loading: Booleano para mostrar ou não o icone de carregando

NOTES:

*/

import { useEffect, useState } from "react";
import DateFormat from "../../usecases/DateFormat";
import ProjectTable from "../basic/ProjectTable";
import { Info, RecentActors } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import Pages from "../../pages/Pages";
import { useHistory } from "react-router";
import UserTypes from "../../models/UserTypes"
import strings from '../../values/strings/strings'

function UsersList({ usuarioLogado, items, refresh, rowsPerPage, loading, style }) {
    const [headers, setheaders] = useState([
        '', `${strings.getString('info_name')}`, `${strings.getString('info_data')}`, `${strings.getString('info_tipo')}`, `${strings.getString('info_email')}`, `${strings.getString('info_telephone')}`,
    ]);
    const [itemnsTable, setitemnsTable] = useState([])
    const [usuarioLogado1, setUsuarioLogado] = useState(null)

    useEffect(() => {
        console.log('Users - useEffect', JSON.stringify(items))
        if (usuarioLogado && usuarioLogado.tipo === UserTypes.CLI && headers[0] !== "Usuário") {
            let headersNew = [...headers]
            headersNew = [...headers.slice(0, 2), `${strings.getString(' default_user_name')}`, ...headers.slice(2, headers.length)]
            setheaders(headersNew)
        } else {

        }
        setUsuarioLogado(usuarioLogado)
    }, [usuarioLogado])

    useEffect(() => {
        if (items) {
            let items1 = items
            let userTabela = bindData(items1)
            setitemnsTable(userTabela)
        }
    }, [items])

    const history = useHistory();

    const bindData = (data) => {
        let binded = [];

        binded = data.map((item, i) => {
            let obj = {}

            obj.info = (<IconButton color="info" onClick={() => redirectUser(item)}><Info /></IconButton>)
            obj.nome = item.nome
            obj.data = DateFormat(new Date(item.data_update_server))
            obj.tipo = item.tipo
            obj.email = item.email
            obj.telefone = item.telefone ? item.telefone : 'Sem telefone cadastrado'

            return obj
        })
        return binded;
    }

    function redirectUser(item) {
        console.log("item selecionado", item)
        if (item.tipo === UserTypes.CLI) {
            history.push(`/customer/${item.id}`)
        } else {
            <Snackbar
                open={true}
                autoHideDuration={6000}
                message="Usuario não é um cliente"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        }
    }


    return (
        <ProjectTable
            rowsPerPage={rowsPerPage}
            style={{ flex: 1, whiteSpace: 'nowrap', width: 200 }}
            loading={loading}
            headers={headers}
            items={itemnsTable}
            onRefresh={refresh}
            title={strings.getString('screen_list_user')}
            icon={<RecentActors style={{ marginRight: '8px', alignSelf: 'center' }} sx={{ fontSize: 35, color: "#ffd600" }} />}
        />

    );
}

export default UsersList;