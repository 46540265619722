import React from 'react';
import BasicCard from '../basic/BasicCard';
import InfoCard from '../basic/InfoCard';
import RowContainer from '../basic/RowContainer';
import RowContainerItem from '../basic/RowContainerItem';
import strings from '../../values/strings/strings';
import CustomersList from '../specific/CustomersList';

function DashBoardTec(props) {
    return (
        <div>
            <RowContainer>
                <RowContainerItem >
                    <InfoCard flex title={strings.getString('screen_dashboartec_registered_customers')} value="25" />
                </RowContainerItem>
                <RowContainerItem >
                    <InfoCard flex title={strings.getString('screen_dashboartec_waiting_for_authorization')} value="3" />
                </RowContainerItem>
                <RowContainerItem >
                    <InfoCard flex title={strings.getString('screen_dashboartec_pending_email_confirmation')} value="7" />
                </RowContainerItem>
                <RowContainerItem >
                    <InfoCard flex title={strings.getString('default_equipments_registered')} value="37" />
                </RowContainerItem>
                <RowContainerItem >
                    <InfoCard flex title={strings.getString('default_equipments_no_registered')} value="48" />
                </RowContainerItem>
            </RowContainer>
            <RowContainer>
                
            </RowContainer>
            <RowContainer> 
                <RowContainerItem >
                    <BasicCard>
                        <CustomersList />
                    </BasicCard>
                </RowContainerItem>                       
            </RowContainer>
        </div>  
    );
}

export default DashBoardTec;