import APIManager from "../connection/APIManager"

/*
USE CASE: UserUpdatePassword
Desc: Irá receber um obj contendo o token e a nova senha e setar os novos valores, no usuario que fez a solicitação.
params: 
    - obj {
        token: string, token de autenticação da solitação,
        password: string, nova senha
    }
returns:
  - resolve(): Se caso for alterado com sucesso
  - reject(): Se caso o token não for valido ou tiver algum erro de servidor
*/
const UserUpdatePassword = (obj) => {
    return new Promise((resolve, reject) => {
        try {
            let body = {
                changepass: {
                    pass: obj.password,
                    token: obj.token
                }
            }

            APIManager.send("/changepass","PUT",null,body).then(res => {
                resolve()
            }).catch(e => {
                console.log(e)
                reject()
            })

        } catch (error) {
            return reject()
        }
    })
}
export default UserUpdatePassword;
