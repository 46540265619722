import React, { useEffect, useState } from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import MenuList from '../components/basic/MenuList';
import DashBoardAdv from '../components/pagescomponents/DashBoardAdv';
import DashBoardCli from '../components/pagescomponents/DashBoardCli';
import DashBoardDev from '../components/pagescomponents/DashBoardDev';
import DashBoardTec from '../components/pagescomponents/DashBoardTec';
import APIConstants from '../connection/APIConstants';
import strings from '../values/strings/strings';
import values from '../values/values';
import AuthCheckLogin from '../usecases/AuthCheckLogin';

function Dashboard(props) {

    const [user, setUser] = useState({})
    const [showDev, setshowDev] = useState(false)
    const [showCli, setShowCli] = useState(false)
    const [showTec, setShowTec] = useState(false)
    const [showAdv, setShowAdv] = useState(false)

    useEffect(() => {
        //Start Function to be executed when this Component calls the useEffect empty  
        AuthCheckLogin().then(userData => {
            console.log('UserData da Dashboard', userData);
            setUser(userData)
        }).catch(e => {
            console.log('Erro ao tentar autenticar usuário', e);
        })
    }, [])


    useEffect(() => {
        if (user && user.tipo === APIConstants.user_type_cli) changeShow(setShowCli)
        if (user && user.tipo === APIConstants.user_type_tec) changeShow(setShowTec)
        if (user && user.tipo === APIConstants.user_type_dev && !showDev) changeShow(setshowDev)
        //if(user && user.tipo === APIConstants.user_type_adv) changeShow(setShowAdv)      
    }, [user])

    const onUserData = (userData) => {
        //console.log('UserData DashBoard', userData)      
        setUser(userData)
    }

    const changeShow = (show) => {
        setshowDev(false)
        setShowCli(false)
        setShowTec(false)
        setShowAdv(false)
        show(true)
    }

    const renderDashShowMenu = () => {
        if (user && user.tipo === APIConstants.user_type_adv)
            return (
                <div style={{ display: 'flex', margin: values.margin[2], marginTop: 0, justifyContent: 'flex-end' }}>
                    <MenuList value={`MOD`} items={[
                        { value: 'ADV', onClick: () => { changeShow(setShowAdv); } },
                        { value: 'DEV', onClick: () => { changeShow(setshowDev); } },
                        { value: 'TEC', onClick: () => { changeShow(setShowTec); } },
                        { value: 'CLI', onClick: () => { changeShow(setShowCli); } },
                    ]} />
                </div>
            )
    }

    const renderPresentation = () => {
        if (showAdv) {
            return (
                <DashBoardAdv />
            )
        }
    }

    const renderDevDashboard = () => {
        /*
    
            Activity
                - Equipamentos conectados agora (cadastrados/não cadastrados)
                - Usuários Logados
                - Trabalhos

                - Client Monitors per day
                - New users per day
                - Uploaded Jobs per day
                - Works per day            
    
            Users Preview
                - All
                - Waiting authorization
                - Blocked
                - Past week
                - Link to users
                
                
            Equipments
                - All
                - Active now
                - Active past week
                - Active past year
                - Link to equipments
                - Unregistered
    
                - last active equipments table
    
                - Map of equipments
            
            Works
                - All
                - Current working
                - Past Week
                - Past year
                - Link to works
    
                - last works table
    
            Customers
                - All
                - This week
                - Past month
                - Past year
                - Last ones
            
            System
                - Time active
                - DB Size
    
        */
        if (showDev) {
            return (
                <DashBoardDev />
            )
        }
    }

    const renderCliDashboard = () => {
        /*
            Equipments
            - Number of equipments
            - % of success of equipment last work
            
            Monitor
            - Current works
    
            Works
            - Last works list
            - Number of works sync
            -
        */
        if (showCli) {
            return (
                <div>
                    <DashBoardCli />
                </div>
            )
        }
    }

    const renderTecDashboard = () => {
        /*
            Equipments
            - Number of equipments
            - % of success of equipment last work
            
            Monitor
            - Current works
    
            Works
            - Last works list
            - Number of works sync
        */
        if (showTec) {
            return (
                <DashBoardTec />
            )
        }
    }

    return (
        <><DashboardFrame title={strings.getString('screen_dashboard_title')} onUserData={onUserData}>
            {renderDashShowMenu()}
            {renderDevDashboard()}
            {renderTecDashboard()}
            {renderPresentation()}
            {renderCliDashboard()}
        </DashboardFrame></>

    );
}

export default Dashboard;
