import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Build, Devices, DonutLarge, ExitToApp, ExitToAppTwoTone, People, Person, RecentActors, Reorder, Settings, SettingsApplications, Visibility } from '@mui/icons-material';
import React from 'react';
import { useHistory } from 'react-router';
import strings from '../../values/strings/strings'
import Pages from '../../pages/Pages';
function DashboardDrawerListCLI(props) {
    const history = useHistory()

    return (
        <div>
            <List>
                <ListItem button onClick={() => { history.push(Pages.routes.Dashboard) }} >
                    <ListItemIcon><DonutLarge color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_dashboard_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Monitor) }} >
                    <ListItemIcon><Visibility color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_monitor_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Works) }} >
                    <ListItemIcon><Reorder color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('default_works_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Equipments) }} >
                    <ListItemIcon><Devices color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('default_equipments_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Tools) }} >
                    <ListItemIcon><Build color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_tools_title')} />
                </ListItem>
                <ListItem button onClick={() => { history.push(Pages.routes.Profile) }} >
                    <ListItemIcon><Person color="primary" /></ListItemIcon>
                    <ListItemText primary={strings.getString('screen_profile_title')} />
                </ListItem>

            </List>

            <ListItem button onClick={() => { history.push(Pages.routes.Logout) }} >
                <ListItemIcon><ExitToApp color="primary" /></ListItemIcon>
                <ListItemText primary={strings.getString('screen_log_out')} />
            </ListItem>
        </div>
    );
}

export default DashboardDrawerListCLI;