import APIConstants from "../connection/APIConstants";
import APIManager from "../connection/APIManager";
import CacheStoreCredentials from "./CacheStoreCredentials";
import strings from '../values/strings/strings.js'

/*
USE CASE: AuthPerformLogin
Desc: Fetch API with (email/password) data to perform login
params: 
   - email: String of the user email
   - password: String of the user password
returns:
  - A promise that: resolve if the API returns successfull, and reject if there are errors
*/

const AuthPerformLogin = (email, password) => {
    return new Promise((resolve, reject) => {
        let headers = {}
        headers[APIConstants.header_auth_user] = email
        headers[APIConstants.header_auth_pass] = password

        APIManager.send("/login", "POST", headers).then(res => {
            //Convert the response to json
            return res.json()
        }).then(async json => {
            //Store the credentials in cache
            await CacheStoreCredentials(json)
            console.log('Login efetuado com sucesso', json);
            resolve()
        }).catch(e => {
            //Return error
            console.log('Erro ao tentar efetuar login', e);
            if (e.status === 401) {
                reject(strings.getString('error_invalid_login'))
            } else if (e.status === 500) {
                reject(strings.getString('warn_login_server_error'))
            } else {
                reject(strings.getString('warn_login_error'))
            }
        })
    })
}
export default AuthPerformLogin;