import React, { useEffect, useState } from 'react';
import MapPanel from '../basic/MapPanel';

function EquipmentsMap({ trabalhos, height }) {
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    //console.log('Lista marcadores:', trabalhos);
    if (trabalhos && trabalhos.length > 0) {
      const locationMap = {}; // Objeto para rastrear localizações por ID de equipamento
      const newMarkers = [];

      trabalhos.forEach((t) => {
        if (t.coordenada && t.equipamento.autorizado) {
          const equipamentoID = t.equipamento_id;

          if (!locationMap[equipamentoID]) {
            locationMap[equipamentoID] = true;

            newMarkers.push({
              latitude: t.coordenada.latitude,
              longitude: t.coordenada.longitude,
              nome: t.equipamento.nome,
              id: equipamentoID,
              status: t.trabalho_status,
            });
          }
        }
      });

      setMarkers(newMarkers);
      console.log('newMarkers', newMarkers);
    } else {
      setMarkers([]);
    }
    setLoading(false);
  }, [trabalhos]);

  const zoomMap = 6;
  const centerMap = { lat: -24.061411, lng: -52.426489 };

  return (
    <div>
      <MapPanel markers={markers} zoomMap={zoomMap} centerMap={centerMap} height={height} />
    </div>
  );
}

export default EquipmentsMap;
