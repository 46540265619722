import { Typography, Button, CardContent, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Paper, Select, Stack, Switch, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from 'react';
import values from '../../values/values';
import ProjectSwitch from '../basic/ProjectSwitch';
import colors from '../../values/colors'
import { display } from '@mui/system';
import { useHistory } from 'react-router';
import UserCreate from '../../usecases/UserCreate';
import ValidateFieldEmail from '../../usecases/ValidateFieldEmail';
import ValidadeString from '../../usecases/ValidateString';
import ValidatePassword from '../../usecases/ValidatePassword';
import ValidateTelephone from '../../usecases/ValidateTelephone';
import User from '../../models/User';
import Pages from '../../pages/Pages';
import strings from '../../values/strings/strings';
import Utilities from '../../util/Utilities';

function UserForm(props) {
    const [showpassword, setshowpassword] = useState(false)
    const [showdevinfo, setshowdevinfo] = useState(false)

    const [name, setname] = useState('')
    const [nameError, setnameError] = useState(null)

    const [email, setemail] = useState('')
    const [emailError, setEmailError] = useState(null)

    const [cpf_cnpj, setcpf_cnpj] = useState('');
    const [cpf_cnpjError, setcpf_cnpjError] = useState(null);

    const [pais, setpais] = useState(values.countries[0])
    const [telephone, settelephone] = useState(null)
    const [telephoneError, settelephoneError] = useState(null)

    const [password, setpassword] = useState("")
    const [passwordError, setpasswordError] = useState(null)
    const [confirmPassword, setconfirmPassword] = useState("")
    const [confirmPasswordError, setconfirmPasswordError] = useState(null)

    const [displayPassword, setdisplayPassword] = useState('password');
    const history = useHistory()

    useEffect(() => {
        setshowpassword(props.usePassword)
    }, [props.usePassword])

    useEffect(() => {
        setshowdevinfo(props.useDev)
    }, [props.useDev])

    async function salvar() {
        let deuErro = false
        //Validar campos
        //Se der erro setar os erros nos campos
        let nameInvalid = await ValidadeString(name)
        if (nameInvalid) {
            setnameError(strings.getString('screen_userform_name_validation'))
            deuErro = true
        }

        if (name.length > values.string_limit) {
            setnameError(strings.getString('screen_userform_name_long'))
            deuErro = true
        }

        if (!deuErro) setnameError(null)

        let emailInvalid = await ValidateFieldEmail(email)
        if (emailInvalid) {
            setEmailError(emailInvalid)
            deuErro = true
        } else setEmailError(null)

        let cpf_cnpjInvalid = await ValidadeString(cpf_cnpj)
        if (cpf_cnpjInvalid) {
            setcpf_cnpjError(cpf_cnpjInvalid)
            deuErro = true
        } else setcpf_cnpjError(null)

        let telephoneInvalid = await ValidateTelephone(telephone)
        if (telephoneInvalid) {
            settelephoneError(telephoneInvalid)
            deuErro = true
        } else settelephoneError(null)

        let passwordInvalid = await ValidatePassword(password)
        if (passwordInvalid) {
            setpasswordError(passwordInvalid)
            deuErro = true
        } else setpasswordError(null)

        if (password !== confirmPassword || confirmPassword === "") {
            setconfirmPasswordError(strings.getString('warn_difrent_password'))
            deuErro = true
        } else setconfirmPasswordError(null)

        if (deuErro) return
        //Se der certo montar o objeto
        const user = {
            nome: name,
            email: email,
            pais: pais,
            cpf_cnpj: cpf_cnpj,
            telefone: telephone,
            chavesegura: password
        }
        //Devolver o objeto para a pagina, usando a funcao onSalvar
        props.onSalvar(user)
    }

    const cancelar = () => {
        props.onCancelar()
    }

    const Login = () => {
        history.push(Pages.routes.Login)
    }
    return (
        <div>
            <Stack spacing={4}>
                <TextField label={Utilities.primeiraMaiuscula(strings.getString('info_name'))} value={name} error={nameError} helperText={nameError} variant="standard" onChange={e => setname(e.target.value)} />
                <TextField label={Utilities.primeiraMaiuscula(strings.getString('info_email'))} value={email} error={emailError} helperText={emailError} variant="standard" onChange={e => setemail(e.target.value)} />
                <TextField label={strings.getString('info_cpf_cnpj')} value={cpf_cnpj} error={cpf_cnpjError} helperText={cpf_cnpjError} variant="standard" onChange={e => setcpf_cnpj(e.target.value)} />
                <Stack direction="row" spacing={2}>
                    <TextField select label={strings.getString('info_country')} value={pais} onChange={e => setpais(e.target.value)}>
                        {values.countries.map((item, n) => {
                            return (
                                <MenuItem key={n} value={item}>{item}</MenuItem>
                            )
                        })}
                    </TextField>
                    <TextField label={strings.getString('info_telephone')} value={telephone} error={telephoneError} helperText={telephoneError} type='tel' variant="standard" style={{ flex: 1 }} onChange={e => settelephone(e.target.value)} />
                </Stack>
                <Divider />
                {showpassword &&
                    <Stack spacing={2} direction="row" style={{ flex: 1 }}>

                        <TextField variant="standard"
                            label={strings.getString('info_password')}
                            value={password}
                            error={passwordError}
                            helperText={passwordError}
                            style={{ flex: 1 }}
                            type={displayPassword ? "password" : "text"}
                            onChange={e => setpassword(e.target.value)} />

                        <TextField
                            variant="standard"
                            label={strings.getString('info_password_confirm')}
                            value={confirmPassword}
                            error={confirmPasswordError}
                            helperText={confirmPasswordError}
                            style={{ flex: 1 }}
                            type={displayPassword ? "password" : "text"}
                            onChange={e => setconfirmPassword(e.target.value)} />

                    </Stack>
                }
                <ProjectSwitch label={strings.getString('info_password_show')} checked={displayPassword} onChange={() => { setdisplayPassword(!displayPassword) }} />
                {showdevinfo &&
                    <Stack spacing={2}>
                        <Divider />
                        <ProjectSwitch label={strings.getString('default_send_email_confirmation')} />
                        <ProjectSwitch label={strings.getString('default_user_autorized')} />
                        <Stack spacing={2} direction="row">
                            <Button variant="contained">Cód. Senha</Button>
                            <TextField disabled value={"asdIOS4"} />
                        </Stack>
                    </Stack>
                }
                <Stack direction="row" spacing={2}>
                    {props.onCancelar &&
                        <LoadingButton style={{ flex: 1 }} variant="contained" onClick={cancelar}>Cancelar</LoadingButton>
                    }
                    {props.onSalvar &&
                        <LoadingButton style={{ flex: 1 }} variant="contained" onClick={salvar}>Salvar</LoadingButton>
                    }
                </Stack>
            </Stack>
        </div>
    );
}

export default UserForm;