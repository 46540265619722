import React, { useState, useEffect } from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';
import Monitoring from '../components/pagescomponents/Monitoring';
import CacheLoadCredentials from '../usecases/CacheLoadCredentials';
import Notification from '../components/basic/Notification';

function Monitor(props) {
    const [usuarioLogado, setUsuarioLogado] = useState(null);

    useEffect(() => {
        const loadCredentials = async () => {
            let user = await CacheLoadCredentials();
            if (user && user.autorizado) {
                setUsuarioLogado("autorizado");
            } else {
                setUsuarioLogado("nao_autorizado");
            }
        };

        loadCredentials();
    }, []);

    return (
        <DashboardFrame title={strings.getString('screen_monitor_title')}>
            {usuarioLogado === "nao_autorizado" &&
                <Notification />
            }
            {usuarioLogado === "autorizado" &&
                <Monitoring />
            }
        </DashboardFrame>
    );
}

export default Monitor;
