import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Select, MenuItem } from '@mui/material';
import { Typography } from '@mui/material';


const GraphRelevo = ({ data }) => {
    const [selectedDay, setSelectedDay] = useState('');
    const [uniqueDays, setUniqueDays] = useState([]);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (data[3].histCoords) {
            const days = extractUniqueDays(data[3].histCoords);
            setUniqueDays(days);
            setSelectedDay(days[0] || '');
        }
    }, [data[3].histCoords]);

    useEffect(() => {
        if (selectedDay) {
            const filteredData = filterDataBySelectedDay(data[3].histCoords, selectedDay);
            setChartData(processChartData(filteredData));
        }
    }, [selectedDay, data[3].histCoords]);

    // Função que extrai os dias de trabalhos
    const extractUniqueDays = (histCoords) => {
        const uniqueDaysSet = new Set(histCoords.map(item => new Date(item.date).toDateString()));
        return Array.from(uniqueDaysSet);
    };

    // Função que filtra os dados pelo dia selecionado
    const filterDataBySelectedDay = (histCoords, day) => {
        return histCoords.filter((item) => {
            return new Date(item.date).toDateString() === day;
        });
    };

    // Função que processa os dados para o formato esperado pelo gráfico
    const processChartData = (filteredData) => {
        return filteredData.map(item => ({
            name: new Date(item.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            sementes: item.alt,
        }));
    };

    return (
        <div style={{ width: 'fit-content' }}>
            <Typography variant="h6" component="h3" style={{ marginBottom: '20px' }}>
                Variação de altitude do relevo durante o trabalho
            </Typography>
            <Select
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
                style={{ marginBottom: '10px' }}
            >
                {uniqueDays.map((day) => {
                    const formattedDay = new Date(day).toLocaleDateString('pt-BR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    });
                    return (
                        <MenuItem key={day} value={day}>{formattedDay}</MenuItem>
                    );
                })}
            </Select>
            <div style={{ width: '100%', overflow: 'auto' }}>
                <div style={{ width: '100%' }}>
                    <ResponsiveContainer width={'100%'} height={300}>
                        <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <XAxis dataKey="name" minTickGap={20} />
                            <YAxis />
                            <CartesianGrid stroke="#f5f5f5" />
                            <Tooltip />
                            <Area type="monotone" dataKey="sementes" stroke='#36e236' fillOpacity={0.3} fill='#36e236' />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default GraphRelevo;
