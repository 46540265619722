import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Typography } from "@mui/material";
import AnyDesklogo from '../../assets/images/anydesklogo.png';
import AnyDeskPluginlogo from '../../assets/images/anydeskplugin.png';
import Sm4logo from '../../assets/images/SM4LOGO.png';
import Gpslogo from '../../assets/images/gpsLOGO.png';
import sm3cImage from '../../assets/images/sm3c.png';
import sm3xImage from '../../assets/images/sm3x.png';
//import stxImage from '../../assets/images/stx.png';
import AuthBuildHeaderUserObject from "../../usecases/AuthBuildHeaderUserObject";
import APIManager from "../../connection/APIManager";
import InfoCard from "../basic/InfoCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px',
    marginRight: '20px',
    minWidth: '500px',
  },
  containerItem: {
    margin: '10px',
    width: '95%',
  },
  img: {
    width: '200px',
    height: '190px',
    alignSelf: 'flex-start',
    padding: '10px',
  },
  title: {
    textAlign: 'center',
    fontSize: 'larger',
  },
  button: {
    width: '250px',
    height: '40px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

function Updates() {
  const classes = useStyles();
  const [sm4version, setSm4Version] = useState('');
  const [gpsversion, setGpsVersion] = useState('');
  const [sm3xVersion, setSm3xVersion] = useState('');
  const [sm3cVersion, setSm3cVersion] = useState('');
  const [stxVersion, setStxVersion] = useState('');
  const [error, setError] = useState(false);

  // Busca das versões dos equipamentos
  useEffect(() => {
    getSM4Version();
    getGPSVersion();
    getSM3xVersion();
    getSM3cVersion();
    getSTXVersion();
  }, []);

  const getSM4Version = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/serverinfo/sm4version', 'GET', headers);
      const json = await response.json();
      setSm4Version(json);
      console.log('Versão do SM4:', json);
    } catch (error) {
      console.error('Erro ao buscar versão do SM4:', error);
    }
  };

  const getGPSVersion = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/serverinfo/gpsversion', 'GET', headers);
      const json = await response.json();
      setGpsVersion(json);
      console.log('Versão do GPS:', json);
    } catch (error) {
      console.error('Erro ao buscar versão do GPS:', error);
    }
  };

  const getSM3xVersion = async () => {
    try {
      // Adicione a lógica para obter a versão do SM3x, se aplicável
      // Caso não haja endpoint para versão, você pode remover esta função
    } catch (error) {
      console.error('Erro ao buscar versão do SM3x:', error);
    }
  };

  const getSM3cVersion = async () => {
    try {
      // Adicione a lógica para obter a versão do SM3c, se aplicável
    } catch (error) {
      console.error('Erro ao buscar versão do SM3c:', error);
    }
  };

  const getSTXVersion = async () => {
    try {
      // Adicione a lógica para obter a versão do STX, se aplicável
    } catch (error) {
      console.error('Erro ao buscar versão do STX:', error);
    }
  };

  // Funções de download dos arquivos

  const downloadSM4 = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        setError(true);
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/download/sm4/file', 'GET', headers);
      if (response.status === 200) {
        window.open(response.url, '_blank');
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('Erro ao baixar arquivo SM4:', error);
      setError(true);
    }
  };

  const downloadGPS = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        setError(true);
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/download/gps/file', 'GET', headers);
      if (response.status === 200) {
        window.open(response.url, '_blank');
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('Erro ao baixar arquivo GPS:', error);
      setError(true);
    }
  };

  const downloadSM3x = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        setError(true);
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/download/sm3x/file', 'GET', headers);
      if (response.status === 200) {
        window.open(response.url, '_blank');
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('Erro ao baixar arquivo SM3x:', error);
      setError(true);
    }
  };

  const downloadSM3c = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        setError(true);
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/download/sm3c/file', 'GET', headers);
      if (response.status === 200) {
        window.open(response.url, '_blank');
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('Erro ao baixar arquivo SM3c:', error);
      setError(true);
    }
  };

  const downloadSTX = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        setError(true);
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/download/stx/file', 'GET', headers);
      if (response.status === 200) {
        window.open(response.url, '_blank');
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('Erro ao baixar arquivo STX:', error);
      setError(true);
    }
  };

  const downloadRemoteApp = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        console.log('Erro ao baixar o arquivo AnyDesk');
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/download/remoteapp/file', 'GET', headers);
      if (response.status === 200) {
        window.open(response.url, '_blank');
      } else {
        setError(true);
      }
    } catch (error) {
      console.log('Erro ao baixar o arquivo AnyDesk:', error);
      setError(true);
    }
  };

  const downloadPlugin = async () => {
    try {
      const auth_word = await AuthBuildHeaderUserObject();
      if (!auth_word) {
        console.log('Erro ao baixar o arquivo de Plugin AnyDesk');
        return;
      }
      const headers = {
        ...auth_word,
      };
      const response = await APIManager.send('/download/pluginremoteapp/file', 'GET', headers);
      if (response.status === 200) {
        window.open(response.url, '_blank');
      } else {
        setError(true);
      }
    } catch (error) {
      console.log('Erro ao baixar o arquivo AnyDesk Plugin:', error);
      setError(true);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container className={classes.container}>

        <Grid item className={classes.containerItem}>
          <InfoCard style={{ height: 220 }}>
            <div className={classes.cardContent}>
              <img src={Sm4logo} alt="Sm4" className={classes.img} />
              <Typography variant="h6">Atualização do SM4: {sm4version}</Typography>
              <div className={classes.button}>
                <Button variant="contained" color="success" fullWidth onClick={downloadSM4}>
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </InfoCard>
        </Grid>

        <Grid item className={classes.containerItem}>
          <InfoCard style={{ height: 220 }}>
            <div className={classes.cardContent}>
              <img src={Gpslogo} alt="GPS" className={classes.img} />
              <Typography variant="h6" className={classes.title}>Atualização do GPS: {gpsversion}</Typography>
              <div className={classes.button}>
                <Button variant="contained" color="success" fullWidth onClick={downloadGPS}>
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </InfoCard>
        </Grid>

        <Grid item className={classes.containerItem}>
          <InfoCard style={{ height: 220 }}>
            <div className={classes.cardContent}>
              <img src={sm3xImage} alt="SM3x" className={classes.img} />
              <Typography variant="h6" className={classes.title}>Atualização do SM3x: {sm3xVersion}</Typography>
              <div className={classes.button}>
                <Button variant="contained" color="success" fullWidth onClick={downloadSM3x}>
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </InfoCard>
        </Grid>

        <Grid item className={classes.containerItem}>
          <InfoCard style={{ height: 220 }}>
            <div className={classes.cardContent}>
              <img src={sm3cImage} alt="SM3c" className={classes.img} />
              <Typography variant="h6" className={classes.title}>Atualização do SM3c: {sm3cVersion}</Typography>
              <div className={classes.button}>
                <Button variant="contained" color="success" fullWidth onClick={downloadSM3c}>
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </InfoCard>
        </Grid>

        <Grid item className={classes.containerItem}>
          <InfoCard style={{ height: 220 }}>
            <div className={classes.cardContent}>
              <img src={sm3cImage} alt="STX" className={classes.img} />
              <Typography variant="h6" className={classes.title}>Atualização do STX: {stxVersion}</Typography>
              <div className={classes.button}>
                <Button variant="contained" color="success" fullWidth onClick={downloadSTX}>
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </InfoCard>
        </Grid>

        <Grid item className={classes.containerItem}>
          <InfoCard style={{ height: 220 }}>
            <div className={classes.cardContent}>
              <img src={AnyDesklogo} alt="AnyDesk" className={classes.img} />
              <Typography variant="h6" className={classes.title}>
                Arquivo instalador AnyDesk APK
              </Typography>
              <div className={classes.button}>
                <Button variant="contained" color="success" fullWidth onClick={downloadRemoteApp}>
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </InfoCard>
        </Grid>

        <Grid item className={classes.containerItem}>
          <InfoCard style={{ height: 220 }}>
            <div className={classes.cardContent}>
              <img src={AnyDeskPluginlogo} alt="Plugin" className={classes.img} />
              <Typography variant="h6" className={classes.title}>Arquivo instalador AnyDesk Plugin Samsung APK</Typography>
              <div className={classes.button}>
                <Button variant="contained" color="success" fullWidth onClick={downloadPlugin}>
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </InfoCard>
        </Grid>

      </Grid>
    </div>
  );
}

export default Updates;
