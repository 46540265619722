/*
NAME: CustomerList
DESC: Componente responsavel por listar um array de objetos contendo apenas os clientes
props: 
- usuarioLogado: Tipo de usuario logado
- items: Array de objetos contendo todos os usuarios
- items1: Array de objetos contendo apenas os clientes
- refresh: Função que vem de um componente pai para atualizar a lista de clientes através de um get
- RowsPerPage: Quantidade de linhas por pagina na tabela
- loading: Booleano para mostrar ou não o icone de carregando
NOTES:
*/

import React, { useEffect, useState } from "react";
import DateFormat from "../../usecases/DateFormat";
import ProjectTable from "../basic/ProjectTable";
import { Info, Person, Lock, LockOpen, Email, MarkEmailRead, RecentActors } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { useHistory } from "react-router";
import UserUnlock from "../../usecases/UserUnlock";
import strings from '../../values/strings/strings';


function CustomerList({ items, refresh, rowsPerPage, loading }) {
    const [itemsList, setItemsList] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const transformedItems = items.map(item => ({
            info: <IconButton color="info" onClick={() => history.push(`/customer/${item.id}`)}><Info /></IconButton>,
            autorizado: autorizacaoIcon(item),
            statusemail: emailStatusIcon(item.emailconfirmado),
            data: DateFormat(new Date(item.data_cad_server)),
            nome: item.nome,
            email: item.email,
            telefone: item.telefone || 'Sem telefone cadastrado'
        }));
        setItemsList(transformedItems);
    }, [items, history]);

    function autorizacaoIcon(item) {
        const icon = item.autorizado
            ? <IconButton color="success" onClick={() => toggleLock(item)}><LockOpen /></IconButton>
            : <IconButton color="error" onClick={() => toggleLock(item)}><Lock /></IconButton>;
        return icon;
    }

    function emailStatusIcon(emailconfirmado) {
        return emailconfirmado
            ? <IconButton color="success"><MarkEmailRead /></IconButton>
            : <IconButton color="error"><Email /></IconButton>;
    }

    const toggleLock = async (item) => {
        const updatedItem = { ...item, autorizado: item.autorizado ? 0 : 1 };
        try {
            await UserUnlock(updatedItem);
            setItemsList(prevItems => prevItems.map(it => it.id === item.id ? { ...it, autorizado: updatedItem.autorizado } : it));
            refresh();
        } catch (error) {
            console.error("Erro ao alterar status de autorização", error);
            alert(`Falha ao atualizar: ${error.message}`);
        }
    };


    const headers = [
        '', // Info icon column
        strings.getString('info_authorized'),
        strings.getString('info_email_status'),
        strings.getString('info_data'),
        strings.getString('info_name'),
        strings.getString('info_email'),
        strings.getString('info_telephone'),
    ];

    return (
        <ProjectTable
            rowsPerPage={rowsPerPage}
            style={{ flex: 1, whiteSpace: 'nowrap', width: 500 }}
            loading={loading}
            headers={headers}
            items={itemsList}
            Refresh={refresh}
            icon={<RecentActors style={{ marginRight: '8px', alignSelf: 'center' }} sx={{ fontSize: 35, color: "#ffd600" }} />}
            title={strings.getString('screen_list_customers')}
        />
    );
}

export default CustomerList;
