

/*
USE CASE: AlertConfirm
Desc: Caso de uso que irá exibir uma mensagem de confirmação para que só seja 
executada a ação do usuário se ele confirmar a ação.
params: 
   - onCancel: função que será executada quando o usuário clicar em cancelar e nada será executado
    - onConfirm: função que será executada quando o usuário clicar em confirmar e a ação será executada
returns:
  - Uma promisse que retorna resolve quando o usuário confirmar ou reject quando o usuário cancelar
*/
const AlertConfirm = (show, title, text, onCancel, onDesmiss, onConfirm, btnconfirmtext, btncanceltext)  =>{
  

  function onCancel(){
    if(onCancel) onCancel()
    onDismiss()
}

function onConfirm(){
    if(onConfirm) onConfirm()
    onDismiss()
}

function onDismiss(){
    if(onDismiss) onDismiss()
}

return (
  <div>
  {show && 
      <div></div>
  }
</div>
);
  
}
export default AlertConfirm;