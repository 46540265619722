/*
NAME: MonitorList
DESC: Componente responsavél por renderizar apenas os trabalhos mais recentes 
props:
-  items: Array de objetos que representa a lista de trabalhos
- usuarioLogado: Tipo de ususario logado
- loading: Boolean que indica se a lista esta carregando ou nao
- RowsPerPage: Quantidade de linhas por pagina da tabela que será exibida
-refresh: Função que vem de um componente pai para atualizar a lista de usuarios através de um get
-onDelete: Função que vem de um componente pai para deletar um item do arrray
-onSelect: Função que vem de um componente pai para selecionar um item do arrray 
NOTES:

*/

import { useEffect, useState } from "react";
import DateFormat from "../../usecases/DateFormat";
import ProjectTable from "../basic/ProjectTable";
import { IconButton, Typography } from '@mui/material';
import { useHistory } from "react-router";
import Pages from "../../pages/Pages";
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import WorksMonitorDeleteItem from '../../usecases/WorksMonitorDeleteItem';
import strings from '../../values/strings/strings'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Agriculture from '@mui/icons-material/Agriculture';
import Tooltip from '@mui/material/Tooltip';
import SignalWifiOffIcon from '@mui/icons-material//SignalWifiOff';

const TIME_LIMIT = 10

function MonitorList({ onSelect, items, loading, usuarioLogado, style, RowsPerPage, refresh, onDelete }) {

    const [headers, setheaders] = useState([`Monitorar`, `Status`, `${strings.getString('default_equipment_title')}`, `${strings.getString('info_ultimate_registred')}`, `${strings.getString('info_tipo')}`, `${strings.getString('unity_talhao')}`, `${strings.getString('info_cultura')}`,
        `${strings.getString('unity_area_unit')}`, `${strings.getString('info_precisao')}`, `${strings.getString('info_inicio')}`, `${strings.getString('info_operator')}`, `${strings.getString('info_delete')}`]);
    const [tableItems, setTableItems] = useState([]);
    const [status, setStatus] = useState([])
    const [showdialogsucess, setshowdialogsucess] = useState(false)
    const [message, setmessage] = useState('')


    useEffect(() => {
        if (items) {
            console.log('items: ', items)
            let items1 = items
            let workTabela = bindData(items1)
            setTableItems(workTabela)
        }
    }, [items])

    const history = useHistory();

    const bindData = (data) => {

        let orderData = data.sort((a, b) => {
            return (
                new Date(b.data_update_server).getTime() - new Date(a.data_update_server).getTime() ||
                new Date(b.data_inicio).getTime() - new Date(a.data_inicio).getTime()
            )
        })

        let binded = orderData.map((item, index) => {
            //Calculo da diferença de tempo entre agora e a ultima atualização do trabalho em minutos
            let dif = ((new Date().getTime()) - (new Date(item.data_update_server).getTime())) / 1000 / 60

            if (dif > TIME_LIMIT) {
                item.trabalho_status = 'DESCONECTADO'
            }

            let obj = {}
            //obj.info = <IconButton color="info" onClick={() => redirect(item)}><Info/></IconButton>
            obj.monitoramento = <Tooltip>{itemMonitoramento(item)}</Tooltip>
            obj.status = itemStatus(item)
            obj.equipamento = <Button onClick={() => redirectEquipament(item)}>{item.equipamento.nome}</Button>
            obj.ultimoregistro = DateFormat(new Date(item.data_update_server))
            obj.tipo = tipo(item) //IMPROVE - Verificar se está correto
            obj.talhao = item.talhao ? item.talhao : ' - - '
            obj.cultura = item.cultura ? item.cultura.nome : ' - - '
            obj.area = areaPlantada(item)
            obj.precisao = precisao(item)
            obj.inicio = DateFormat(new Date(item.data_inicio))
            obj.operador = item.operador
            obj.delete = itemDelete(item, index)
            return obj

        })

        return binded
    }

    function itemDelete(item, index) {
        return <Tooltip title={'Excluir Registro'}><IconButton color="error" onClick={() => deleteItem(item, index)}><DeleteIcon /></IconButton></Tooltip>
    }

    function itemMonitoramento(item) {

        return (
            <IconButton color='success' onClick={() => redirectMap(item)} ><PlayCircleFilledIcon /> </IconButton>
        )

    }

    function itemStatus(item) {

        let iconeStatus, tooltipMessage = ''

        if (item.trabalho_status === 'DESCONECTADO') {
            iconeStatus = (<SignalWifiOffIcon color='disabled' />)
            tooltipMessage = 'Equipamento desconectado'
        } else if (item.trabalho_status === 'INICIADO') {
            iconeStatus = (<Agriculture color='success' />)
            tooltipMessage = 'Trabalho em andamento'
        } else if (item.trabalho_status === 'PARADO') {
            iconeStatus = (<Agriculture color='error' />)
            tooltipMessage = 'Trabalho Parado'
        }

        return (
            <Tooltip title={tooltipMessage}>
                {iconeStatus}
            </Tooltip>
        )

    }



    function redirectMap(item) {
        onSelect(item)
        history.push(Pages.routes.Monitor + '/' + item.id)

    }

    function tipo(item) {
        if (item.plantio.id && item.pulv.id) {
            return <Typography>
                <span style={{ color: '#15ae06' }}> - Plantio </span>
                <br />
                <span style={{ color: '#03a9f4' }}> - Pulverização</span>
            </Typography>
        }
        if (!item.plantio.id && item.pulv.id) {
            return <Typography style={{ color: '#03a9f4' }}> - Pulverização</Typography>
        }
        if (item.plantio.id && !item.pulv.id) {
            return <Typography style={{ color: '#15ae06' }}> - Plantio</Typography>
        }
        if (!item.plantio.id && !item.pulv.id) {
            return <Typography> ---- </Typography>
        }

    }

    function areaPlantada(item) {
        if (!item.plantio && !item.pulv) {
            return '----'
        } else if (item.plantio && item.plantio.plantio_ponto) {
            try {
                return item.plantio.plantio_ponto.areaplantada_ha.toFixed(3)
            } catch (error) {
                return '----'
            }
        } else if (item.pulv && item.pulv.pulv_ponto) {
            try {
                return item.pulv.pulv_ponto.area_pulverizada.toFixed(3)
            } catch (error) {
                return '----'
            }
        } else if (item.plantio && item.pulv && item.plantio.plantio_ponto && item.pulv.pulv_ponto) {
            try {
                return ((item.plantio.plantio_ponto.areaplantada_ha) + (item.pulv.pulv_ponto.area_pulverizada)).toFixed(3)
            } catch (error) {
                return '----'
            }
        }
        console.log('item: ', item.pulv.pulv_ponto)
    }

    function deleteItem(item, index) {
        onDelete(item, index)
        WorksMonitorDeleteItem(item).then(res => {

            //Falar pro parent que deu certo para que ele remova esse item do array
            //onDelete(item,index)
            //Remover codigo do caso de uso 
        }).catch(err => {
            console.log('err')
            //Mostrar que deu errado para o usuário > alert
        }).finally(() => {
            console.log('finally')
        })
    }

    function precisao(item) {

        if (item.plantio.plantio_ponto && item.pulv.pulv_ponto) {
            let totalPulv = 0
            let totalPlantio = 0

            totalPulv = (item.pulv.pulv_ponto.vazao_geral_lha * 100)
            totalPulv = totalPulv / item.pulv.config_taxa_alvo

            totalPlantio = (item.plantio.plantio_ponto.sementes_metro_total * 100)
            totalPlantio = totalPlantio / item.plantio.config_sementesmetro

            return ((totalPulv + totalPlantio) / 2).toFixed(0) + '%'
            // let totalPlantio = (item.plantio.plantio_ponto.sementes_metro_total ) / item.plantio.config_taxa_alvo //ARREDONDAR VALORES

        }

        if (item.plantio.plantio_ponto && !item.pulv.pulv_ponto) {
            let totalPlantio = 0
            totalPlantio = (item.plantio.plantio_ponto.sementes_metro_total * 100)
            totalPlantio = totalPlantio / item.plantio.config_sementesmetro

            return totalPlantio.toFixed(0) + '%'

        }

        if (!item.plantio.plantio_ponto && item.pulv.pulv_ponto) {
            let totalPulv = 0
            totalPulv = (item.pulv.pulv_ponto.vazao_geral_lha * 100)
            totalPulv = totalPulv / item.pulv.config_taxa_alvo
            return totalPulv.toFixed(0) + '%'

        }

        if (!item.plantio && !item.pulv) {
            return '----'
        }
    }

    function verMais() {
        history.push(Pages.routes.Works)
    }
    function redirectEquipament(item) {
        history.push(Pages.routes.Equipments + '/' + item.equipamento.id)
    }
    function redirect(item) {

        history.push(Pages.routes.Works + "/" + item.id)
    }
    return (

        <ProjectTable
            loading={loading}
            onViewMore={verMais}
            rowsPerPage={RowsPerPage}
            style={{ flex: 1, whiteSpace: 'nowrap', width: 500, ...style }}
            title={'Trabalhos Atuais em andamento'}
            headers={headers}
            items={tableItems}
            onRefresh={refresh}
        />
    );
}

export default MonitorList;


/*


    const renderTrabalhoStatus = () => {

        if(!trabalho.trabalho_status) return   <Alert variant="filled" icon={<AutorenewIcon />} severity="info">OBTENDO DADOS ...</Alert>
       
        if(diftime >= TIME_LIMIT){
            return(
                <Alert variant="filled" icon={<SignalWifiOffIcon />}style={{backgroundColor:'#757575'}} > TRABALHO DESCONECTADO</Alert>
            )
        }
        if(trabalho.trabalho_status == 'FINALIZADO'){
            return (
                <Alert variant="filled" severity="success">TRABALHO FINALIZADO</Alert>
            )
        }
        if(trabalho.trabalho_status == 'PARADO'){
            return (
                <Alert variant="filled" severity='warning'> TRABALHO PARADO</Alert>
            )
        }

        if(trabalho.trabalho_status == 'INICIADO'){ 
            return (
            <Alert variant="filled" icon ={<Agriculture/>}> TRABALHO INICIADO </Alert>
        )}

    }


*/