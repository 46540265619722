import React, { useState, useEffect } from 'react';
import APIManager from '../../connection/APIManager';
import AuthBuildHeaderUserObject from '../../usecases/AuthBuildHeaderUserObject';
import { Refresh } from '@mui/icons-material';
import RowContainer from '../basic/RowContainer';
import BasicCard from '../basic/BasicCard';
import InfoCard from '../basic/InfoCard';
import RowContainerItem from '../basic/RowContainerItem';
import { Alert } from '@mui/material';

function Systemconfigs({ isOpen, onClose }) {
  const [serverInfo, setServerInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const obj = await AuthBuildHeaderUserObject().catch((e) => {
          return null;
        });

        if (!obj) {
          setLoading(false);
          return;
        }

        const headers = {
          ...obj,
        };

        const response = await APIManager.send('/serverinfo', 'GET', headers);
        const json = await response.json();

        setServerInfo(json);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados do servidor:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const refresh = () => {
    window.location.reload();
  };

  const killServer = async () => {
    try {
      const obj = await AuthBuildHeaderUserObject().catch((e) => {
        return null;
      });

      if (!obj) {
        return;
      }

      const headers = {
        ...obj,
      };

      const response = await APIManager.send('/serverinfo/kill', 'GET', headers);

      if (response.ok) {
        Alert('Servidor desligado');
        console.log('Servidor derrubado com sucesso.');
      } else {
        console.error('Falha ao derrubar o servidor.');
      }
    } catch (error) {
      console.error('Erro ao fazer a solicitação:', error);
    }
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
  };

  const h2 = {
    color: '#F8C102',
    justifyContent: 'center',
  };

  const ulStyle = {
    listStyleType: 'none',
    padding: 0,
  };

  const liStyle = {
    margin: '5px 0',
  };

  const buttonStyle = {
    backgroundColor: '#F8C102',
    color: 'black',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    margin: '10px',

  };

  const loadingStyle = {
    color: '#F8C102',
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
      >
      <div
        style={{
          backgroundColor: 'rgba(25, 25, 25, 1.0)',
          padding: '20px',
          borderRadius: '4px',
        }}
      >
        <h2 style={h2}>Informações do Servidor</h2>
        <BasicCard>
          {loading ? (
            <p style={loadingStyle}>Carregando...</p>
          ) : (
            <InfoCard style={{ height: 180 }}>
              <ul style={ulStyle}>
                <li style={liStyle}>Palavra-chave de autenticação: {serverInfo.auth_word}</li>
                <li style={liStyle}>Versão SM4: {serverInfo.sm4version}</li>
                <li style={liStyle}>Chave SM4: {serverInfo.sm4keypass}</li>
                <li style={liStyle}>Versão GPS: {serverInfo.gpsversion}</li>
              </ul>
            </InfoCard>
          )}
        </BasicCard>
        <button style={{...buttonStyle, float:'left'}} onClick={killServer}>
          Kill Server
        </button>
        <button style={{...buttonStyle, float:'none'}} onClick={refresh}>
          Atualizar
        </button>
        <button style={{...buttonStyle, float:'right'}} onClick={onClose}>
          Fechar
        </button>
      </div>
    </div>
  );
}

export default Systemconfigs;
