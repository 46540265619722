/*
NAME: ChartTotal
DESC: 
props:
- title: String,  Titulo do gráfico a ser renderizado, 
- total: Numeber, Total de itens para montar o grafico
- items: Array Objeto: {
               - text: string, Texto do item, representando uma fatia do grafico
               - value: number, numero que representa o valor dessa fatia no grafico
         }

- - - - Default
- style: Object, style object to overwrite this component style
NOTES:

*/

import { Stack } from "@mui/material";
import values from "../../values/values";
import ProjectChartEquip from "./ProjectChartEquip";


function ChartTotal({ title, total, items, style, width }) {
   return (
      <Stack style={{
         display: 'flex', 
         flexWrap: 'wrap', 
         padding: values.padding[0], 
         ...style
     }}>
        <ProjectChartEquip data={items} title={title} total={total} style={style} width={width} />
      </Stack>
   );
}

export default ChartTotal;
