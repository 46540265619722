import React from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';
import Updates from '../components/pagescomponents/Updates';

function Tools(props) {
    return (
        <>
        <DashboardFrame title={strings.getString('screen_tools_title')}>
            <Updates />
        </DashboardFrame> 
        {/* <Updates /> */}
        </>
    );
}

export default Tools;