import { Paper, Typography } from '@mui/material';
import React from 'react';
import values from '../../values/values';
import colors from '../../values/colors';

function BasicCard(props) {
    return (
        <Paper style={{
                // display: 'flex', 
                flexWrap: 'wrap', 
                flexDirection: 'column',
                padding: values.padding[0], 
                backgroundColor: props.transparent ? 'transparent' : colors.getTheme().palette.background.paper,
                ...props.style
            }}>
            {props.title && <Typography style={{flex: 1}} color={props.color}>{props.title}</Typography>}
            {props.children && 
                <div style={{flex: 1, display: 'flex', flexWrap: 'wrap'}}>
                    {props.children}
                </div>
            }
        </Paper>
    );
}

export default BasicCard;