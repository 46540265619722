import {
  AppBar,
  CssBaseline,
  Drawer,
  Toolbar,
  Typography,
  Box,
  IconButton,
  LinearProgress
} from '@mui/material';
import { MenuRounded, Person } from '@mui/icons-material';
import React, { useEffect, useState, useCallback } from 'react';
import Utilities from '../../util/Utilities';
import strings from '../../values/strings/strings';
import colors from '../../values/colors';
import DashboardDrawerList from './DashboardDrawerList';
import MenuList from './MenuList';
import { useHistory } from 'react-router';
import AuthPerformLogout from '../../usecases/AuthPerformLogout';
import AuthCheckLogin from '../../usecases/AuthCheckLogin';
import Pages from '../../pages/Pages';
import CacheLoadCredentials from '../../usecases/CacheLoadCredentials';
import DashboardDrawerListCLI from './DashboardDrawerListCLI';
import APIConstants from '../../connection/APIConstants';
import { useLocation } from 'react-router-dom';
import CacheClearAll from '../../usecases/CacheClearAll';
import CacheClearCredentials from '../../usecases/CacheClearCredentials';

const drawerWidth = 240;

/*
  DashboardFrame is the main comoponent of dashboard pages, 
  including menu and app bar.
  Its children is the page to be displayed 
  props are
  - onUserData(): 
    the DashboardFrame get the user data and check its authentication, 
    it can be delivered back to the page component
    who use DashboardFrame through this function
          
*/

function DashboardFrame(props) {
  const history = useHistory();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState({});
  const [drawer, setDrawer] = useState(null);
  const location = useLocation();
  const toolRoute = location.pathname === Pages.routes.Tools;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = useCallback(async () => {
    await AuthPerformLogout();
    CacheClearAll();
    CacheClearCredentials();
    history.push(Pages.routes.Login);
  }, [history]);


  useEffect(() => {
    //Start Funcion to be executed when this Component calls the useEffect empty
    const start = async () => {
      //Check if there is a user is authorized, if not logout
      let userData = await AuthCheckLogin().catch(e => { console.log(e); return null })
      console.log("DashboardFrame -> userData", userData)
      setUser(userData)
    }
    //Close function to be executed when this Component finishes
    const close = () => {
      //Run when application closes      
    }

    start()
    return close
  }, [logout])

  useEffect(() => {
    if (props.onUserData) props.onUserData(user)
  }, [props, user])

  useEffect(() => {
    CacheLoadCredentials().then(res => {
      if (res && res.tipo) {
        if (user && user.tipo === APIConstants.user_type_cli) {
          setDrawer((
            <div>
              <Typography variant="h5" color="primary" align="center" style={{ padding: 20 }}>{Utilities.todasMaiusculas(strings.getString('app_name'))}</Typography>
              <DashboardDrawerListCLI />
            </div>
          ));
        } else if (!user && user.tipo === APIConstants.user_type_cli && toolRoute) {

          <div>
            <Typography variant="h5" color="primary" align="center" style={{ padding: 20 }}>{Utilities.todasMaiusculas(strings.getString('app_name'))}</Typography>
          </div>

        } else {
          setDrawer((
            <div>
              <Typography variant="h5" color="primary" align="center" style={{ padding: 20 }}>{Utilities.todasMaiusculas(strings.getString('app_name'))}</Typography>
              <DashboardDrawerList />
            </div>
          ));
        }
      }
    });
  }, [user, toolRoute]);

  const showAppBar = user && Object.keys(user).length > 0 || !toolRoute;

  const container = window !== undefined ? () => window().document.body : undefined;

  return (

    <Box sx={{ display: 'flex' }}> {/*#TODO Alterar a função que esconder e exibe o painel alteral pois está interferindo na responsividade do site */}
      <CssBaseline />
      {showAppBar && (
        <AppBar position="fixed" sx={{ width: { lg: `calc(100% - ${drawerWidth}px)` }, ml: { lg: `${drawerWidth}px` }, }} elevation={0} style={{ backgroundColor: colors.getTheme().palette.background.default, }}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { lg: 'none' } }}>
              <MenuRounded />
            </IconButton>
            <Typography variant="h6" color="primary1" style={{ flex: 1 }}>
              {props.title}
            </Typography>
            <MenuList value={user.nome} endIcon={<Person />} items={
              [
                { value: strings.getString('screen_profile_title'), onClick: () => { history.push(Pages.routes.Profile) } },
                { value: strings.getString('info_logout'), onClick: logout },
              ]
            } />
          </Toolbar>
          {props.loading && <LinearProgress />}
        </AppBar>
      )}
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true, }}
          sx={{ display: { xs: 'block', lg: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, }} >
          {drawer}
        </Drawer>
        <Drawer variant="permanent" sx={{ display: { xs: 'none', lg: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, }} open>
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, lg: showAppBar ? `${drawerWidth}px` : 0 }}>
        <Toolbar />
        {props.children}
      </Box>
    </Box>

  );
}

export default DashboardFrame;
