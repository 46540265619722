import React from 'react';
import { Box, Typography, Button, Card, CardContent } from '@mui/material';
import { useHistory } from 'react-router-dom';

function Notification() {
    const history = useHistory();


    const handleUpgradeClick = () => {
        window.location.href = 'https://www.saframax.com/saframanager-3/';
    };

    return (
        <Card sx={{ maxWidth: 500, margin: '0 auto', textAlign: 'center', padding: 2 }}>
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                    Plano Gratis
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                    O seu plano atual é o plano Gratis. Para ter acesso a esta funcionalidade você deve assinar um outro plano.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleUpgradeClick}>
                    Alterar Plano
                </Button>
            </CardContent>
        </Card>
    );
}

export default Notification;
