/*NAME: Work 
DESC: Componente responsavel por exibir a lista de trabalhos*/
import { useEffect, useState } from "react";
import InfoCard from "../basic/InfoCard";
import RowContainer from "../basic/RowContainer";
import RowContainerItem from "../basic/RowContainerItem";
import WorksList from "../specific/WorksList";
import WorksGetData from "../../usecases/WorksGetData";
import BasicCard from "../basic/BasicCard";
import strings from "../../values/strings/strings";
import ViewMore from "../basic/ViewMore";
import { useHistory, useLocation } from "react-router-dom";
import Workdetails from "../pagescomponents/Workdetails"

function Work() {
    const [usuarioLogado, setusuarioLogado] = useState([]);
    const [trabalhos, settrabalhos] = useState([]);
    const [loading, setloading] = useState([]);
    const [itemSelecionado, setitemSelecionado] = useState(null);
    const [trabalhosPendentes, settrabalhosPendentes] = useState(0);
    const [trabalhosEnviados, settrabalhosEnviados] = useState(0);
    const [trabalhosErros, settrabalhosErros] = useState(0);
    const [areaMapeada, setareaMapeada] = useState(0);
    const [totalTrabalhos, setTotalTrabalhos] = useState(0);
    const history = useHistory();
    const location = useLocation();
    const [currentPage, setcurrentPage] = useState(1);
    const [hasMore, sethasMore] = useState(true);

    useEffect(() => {
        let id = window.location.pathname.split('/')[2]

        if (id)
            setitemSelecionado({ id: id })

        else if (!id || location.pathname === '/works') {
            setitemSelecionado(null)
            getWorks()
        }

    }, [])

    useEffect(() => {
        let enviados = 0;
        let erros = 0;
        let pendentes = 0;
        let area = 0;
        let qtdTrabalhos = trabalhos.length;

        if (trabalhos.sincronismo) {
            trabalhos.forEach(function (item) {
                if (!item.modo_resumo && item.sincronismo.sincronismo_status === 'CONCLUIDO') {
                    enviados++;
                } else if (!item.modo_resumo && item.sincronismo.sincronismo_status !== 'CONCLUIDO') {
                    pendentes++;
                } else if (!item.modo_resumo && item.sincronismo.sincronismo_status === 'ERROR') {
                    erros++;
                }

                // Verifica e soma a área plantada
                if (item.plantio?.plantio_ponto?.areaplantada_ha) {
                    area += item.plantio.plantio_ponto.areaplantada_ha;
                }
                // Verifica e soma a área pulverizada
                if (item.pulv?.pulv_ponto?.area_pulverizada) {
                    area += item.pulv.pulv_ponto.area_pulverizada;
                }

            })
        } else {
            enviados = 0;
            erros = 0;
            pendentes = 0;
            area = 0;
        }
        console.log('Area Mapeada', area);
        setareaMapeada(area.toFixed(2));
        settrabalhosEnviados(enviados);
        settrabalhosErros(erros);
        settrabalhosPendentes(pendentes);
        setTotalTrabalhos(qtdTrabalhos);

    }, [trabalhos]);


    const getWorks = () => {
        if (!hasMore) return;
        setloading(true)
        WorksGetData(null, false, currentPage).then(items => {
            if (items.length < 20) sethasMore(false)
            settrabalhos(prev => [...prev, ...items]);
            setcurrentPage(prev => prev + 1)
            console.log('Dados da lista de trabalhos', items, currentPage)
        }).catch(err => {
            console.log('Erro ' + err)
        }).finally(() => {
            setloading(false)
        })
    }

    const itemSelected = (id) => {
        setitemSelecionado(id)
    }
    const atualizar = () => {
        history.push('/works')
    }

    return (

        <div>
            {!itemSelecionado &&
                <div>
                    <RowContainer>
                        <RowContainerItem>
                            <InfoCard flex title={strings.getString('unity_area_total_map')} value={areaMapeada}></InfoCard>
                        </RowContainerItem>
                        <RowContainerItem>
                            <InfoCard flex title={strings.getString('default_works_send')} value={trabalhosEnviados}></InfoCard>
                        </RowContainerItem>
                        <RowContainerItem>
                            <InfoCard flex title={strings.getString('unity_work_pendent_title')} value={trabalhosPendentes}></InfoCard>
                        </RowContainerItem>
                        <RowContainerItem>
                            <InfoCard flex title={strings.getString('deafult_works_error')} value={trabalhosErros}></InfoCard>
                        </RowContainerItem>
                        <RowContainerItem>
                            <InfoCard flex title={strings.getString('works_total')} value={totalTrabalhos}></InfoCard>
                        </RowContainerItem>
                    </RowContainer>
                    <RowContainer>
                        <RowContainerItem style={{ flex: 2 }}>
                            <BasicCard>
                                <WorksList
                                    items={trabalhos}
                                    usuarioLogado={usuarioLogado}
                                    loading={loading}
                                    refresh={atualizar}
                                    RowsPerPage={25}
                                    onViewMore={ViewMore}
                                    onSelect={itemSelected} />
                            </BasicCard>
                        </RowContainerItem>
                    </RowContainer>
                </div>
            }
            {itemSelecionado &&
                <RowContainer>
                    <Workdetails item={itemSelecionado} />
                </RowContainer>
            }
        </div>
    )
}
export default Work;