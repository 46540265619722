const api_url = "https://saframanager-saframanager-api.o2obo9.easypanel.host";
/*
The APIManager is responsible to fetch with the API
*/

const APIManager = {

    /*
        send function - make a fetch to a route of the api
        params:
            - to: String value of the route (e.g: "/login")            
            - method: String value for the request method (e.g: "GET", "POST", "PUT"...)
            - header: Object value for the headers
            - body: Object value for the body
            - res_expected: Number, integer, for the expected response, if null it will expect 200
        returns: 
            - A promisse that can be resolve if the request is performed with success, 
            or reject if there is an error or the response status is different from the res_expected
    */
    send: (to, method, header, body, res_expected) => {
        return new Promise((resolve, reject) => {
            let send_headers = {
                ...header,
                'Content-Type': 'application/json'
            }
            let send_body = {
                ...body
            }
            console.log('APIManager.send Dados completos: ', api_url + to, method, send_headers, send_body)
            fetch(api_url + to, {
                method: method,
                headers: send_headers,
                body: (method !== "GET") ? JSON.stringify(send_body) : null,
            }).then(res => {
                if (res.status === (res_expected ? res_expected : 200)) {
                    resolve(res)
                } else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

}

export default APIManager;

