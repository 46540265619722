import APIConstants from "../connection/APIConstants";
import CacheLoadCredentials from "./CacheLoadCredentials";

/*
USE CASE: AuthBuildHeaderUserObject
Desc: Monta o objeto do usuario, usado nos headers para autenticação
params: 
   - NONE
returns:
  - A promise que se bem sucedida retorna o objeto do usuário, constuido conforme o padrão da API para montar o header
*/
const AuthBuildHeaderUserObject = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let session = await CacheLoadCredentials()
            if (!session || !session.email || !session.token || !session.tipo) {
                let headers = {}
                headers["auth_word"] = 'SAFRAMAXAUTH2090909'

                resolve(headers)
            } else {

                let headers = {}
                headers[APIConstants.header_auth_user] = session.email
                headers[APIConstants.header_auth_login] = session.email
                headers[APIConstants.header_auth_user_type] = session.tipo
                headers[APIConstants.header_auth_email] = session.email
                headers["email"] = session.email
                headers["login"] = session.email
                headers["token"] = session.token
                headers["tipo"] = session.tipo
                headers["auth_word"] = 'SAFRAMAXAUTH2090909'

                resolve(headers)
            }

        } catch (e) {
            reject()
        }

    })
}
export default AuthBuildHeaderUserObject;