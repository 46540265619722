/*
NAME: ProjectChart
DESC: componente padrão para renderizar graficos
props: 
- title: string, titulo do grafico
- onViewMore: #DEFINIR function, função para chamar a tela respectiva, 
- onRefresh: #DEFINIR function, função para atualizar o grafico
- #DEFINIR DEPOIS OUTRAS PROPRIEDADES
- - - - Default
- style: Object, style object to overwrite this component style
NOTES:
    implementar o grafico posteriormente, por agora apenas estruturar o componente
*/

import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Refresh from "./Refresh";
import ViewMore from "./ViewMore";
import values from '../../values/values';
import { BarChart, Bar, YAxis, XAxis, Tooltip, Legend} from "recharts";


function ProjectChart(props) {
    return (
        <Stack spacing={1} style={{ ...props.style }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {props.onViewMore && <ViewMore onClick={props.onViewMore} />}
                {props.title && <Typography style={{ flex: 1, alignSelf: 'center', marginLeft: values.margin[3], marginRight: values.margin[3] }}>{props.title}</Typography>}
                {props.onRefresh && <Refresh onClick={props.onRefresh} />}

            </div>
            <div>
                <BarChart
                    width={props.width}
                    height={400}
                    data={props.data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <Refresh onClick={props.onRefresh} />
                    <XAxis dataKey="text" />
                    <YAxis />
                    <Legend />
                    <Bar dataKey="value" fill="#FCCB00"/>
                </BarChart>


            </div>
        </Stack>
    );
}

export default ProjectChart;




