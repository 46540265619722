/*
NAME: MonitorChartLimits
DESC:Componente resopnsavel por receber um array de um componente pai, fazer o processamento dos 
dados e enviar para o componente filho realizar a montagem do gráico.
props:
- linhas: array de objetos referente aas linhas de um determinado plantio
- item: Array de objeto de um item especifico
- - - - Default
- style: Object, style object to overwrite this component style

NOTES:

*/
import React,{useEffect, useState} from 'react';
import strings from '../../values/strings/strings';
import ChartBarsLimits from '../basic/ChartBarsLimits';

function MonitorChartLimits({linhas, item, style}) {
    const [chartValues, setChartValues] = useState([]);
    
   useEffect(() => {
      if(linhas && linhas.length > 0){
      bindData(linhas, item)
      }
   }, [item, linhas])

   const bindData = (arr, obj) => {
      let newChartValues = []
      console.log('obj'+JSON.stringify(obj))

      newChartValues = arr.map((item, i) => {
         console.log("Dados de item:" , item)
         return {
            text: item.numero,
            value: item.sementes_metro_parcial,
            max: obj.config_sementesmetro_max,
            min: obj.config_sementesmetro_min,
            target: obj.config_sementesmetro,
         }
      })
      setChartValues(newChartValues)
   }

   
    return (
      <ChartBarsLimits
         title={strings.getString('screen_monitoring_chart_title')} 
         items={chartValues}
         style={style}
         />
   );
}

export default MonitorChartLimits;