import APIManager from '../connection/APIManager'
import AuthBuildHeaderUserObject from './AuthBuildHeaderUserObject'

/*
USE CASE: UserGetData
Desc: Retorna um array de objetos de usuários da API
params: 
   - email: Email do usuário para obter os dados
returns:
  - Uma promise que se bem sucedida retorna um array de objetos (usuarios), ou os dados de um usuário caso email seja válido, caso contrario rejeita
*/
const UserGetData = (email) => {
    return new Promise(async (resolve, reject) => {

        let obj = await AuthBuildHeaderUserObject().catch(e => { return null })
        if (!obj) reject()

        let headers = {
            ...obj
        }

        // if(email){
        //     headers.email = email
        // }else{
        //     delete headers.email
        // }
        console.log('Headers do user:', headers)
        APIManager.send('/usuarios', 'GET', headers).then(res => {
            return res.json()
        }).then(json => {
            resolve(json)
        }).catch(e => {
            reject(e)
        })
    })
}
export default UserGetData;