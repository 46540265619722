import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Map } from '@mui/icons-material';

// Importe dos relátórios de Mapas
import Maphectarehora from '../components/reports/Map_hectarehora';
import Maplitrosha from '../components/reports/Map_litrosha';
import Maplitrosmin from '../components/reports/Map_litrosmin';
import Mappressao from '../components/reports/Map_pressao';
import Maprelevo from '../components/reports/Map_relevo';
import MapSementesha from '../components/reports/Map_sementesha';
import MapSementesmetro from '../components/reports/Map_sementesmetro';
import Maptemperatura from '../components/reports/Map_temperatura';
import Mapvelocidade from '../components/reports/Map_velocidade';


const ReportsMaps = ({ data, style }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  console.log('Valores obtidos de mapas ', data);

  const reportsList = [
    { id: 'map_hectarehora', label: 'Mapa Hectare Hora', type: 'Map' },
    { id: 'map_litrosha', label: 'Mapa Litros Hectare', type: 'Map' },
    { id: 'map_litrosmin', label: 'Mapa Litros Minuto', type: 'Map' },
    { id: 'map_pressao', label: 'Mapa Pressão', type: 'Map' },
    { id: 'map_relevo', label: 'Mapa Relevo', type: 'Map' },
    { id: 'map_sementesha', label: 'Mapa Sementesha', type: 'Map' },
    { id: 'map_sementesmetro', label: 'Mapa Sementes Metro', type: 'Map' },
    { id: 'map_temperatura', label: 'Mapa Temperatura', type: 'Map' },
    { id: 'map_velocidade', label: 'Mapa Velocidade', type: 'Map' },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReportSelect = (report) => {
    setSelectedReport(report);
    handleClose();
  };

  const renderSelectedReport = () => {
    switch (selectedReport?.id) {
      case 'map_hectarehora':
        return <Maphectarehora data={data} style={style} />;
      case 'map_litrosha':
        return <Maplitrosha data={data} style={style} />;
      case 'map_litrosmin':
        return <Maplitrosmin data={data} style={style} />;
      case 'map_pressao':
        return <Mappressao data={data} style={style} />;
      case 'map_sementesha':
        return <MapSementesha data={data} style={style} />;
      case 'map_sementesmetro':
        return <MapSementesmetro data={data} style={style} />;
      case 'map_temperatura':
        return <Maptemperatura data={data} style={style} />;
      case 'map_relevo':
        return <Maprelevo data={data} style={style} />;
      case 'map_velocidade':
        return <Mapvelocidade data={data} style={style} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Button
        aria-controls="report-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
      >
        Selecione um Mapa para Relatório
      </Button>
      <Menu
        id="report-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <h2 style={{ justifyItems: 'center' }}><Map /> Mapas</h2>
          {reportsList.filter(r => r.type === 'Map').map(report => (
            <MenuItem key={report.id} onClick={() => handleReportSelect(report)}>
              {report.label}
            </MenuItem>
          ))}
        </div>
      </Menu>
      <div style={{ marginTop: '10px' }}>
        {renderSelectedReport()}
      </div>
    </div>
  );
};

export default ReportsMaps;
