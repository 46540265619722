import APIManager from '../connection/APIManager'

/*
USE CASE: AuthRecoverPasswordByCode
Desc: Irá checar se é um usuario válido e caso for  enviar um email com um link para redefinir a senha
params: 
   - email: string
returns:
  - A promise that 
  -resolve(): Caso de tudo certo.
  -reject(): Caso tenha algum erro de servidor ou o usuario não for válido.
*/
const AuthRecoverPasswordByCode = (email, code, password) => {
    return new Promise((resolve, reject) => {
        if (!email || email === "") return reject()

        let body = {
            changepass: {
                pass: password,
                token: code
            }
        }

        APIManager.send("/changepass", "PUT", null, body).then(res => {
            console.log(res)
            return resolve()
        }).catch(e => {
            console.log(e)
            return reject()
        })

    })
}
export default AuthRecoverPasswordByCode;