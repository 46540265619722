/* eslint-disable no-unused-vars */
import { Button, Link, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import colors from '../../values/colors';
import values from '../../values/values';
import RowContainerItem from './RowContainerItem';

function InfoCard(props) {
    return (        
        <Paper style={{ 
            widith: props.widith ? props.widith : 100,
            height: props.height ? props.height : 105,    
            flex: 1,       
            padding: values.padding[0], 
            ...props.style
        }}>
            <Stack spacing={values.spacing[0]}>
                <Typography align='center'>{props.title}</Typography>
                <Typography variant="h4" color="primary" align="center">{props.value}</Typography>
            </Stack>
            {props.children}
        </Paper>        
    );
}

export default InfoCard;