const values = {

    countries: [
        'Brasil',
        'Paraguai',
        'Uruguai',
        'Bolivia',
        'Argentina',
    ],

    TIME_FILTERS: [
      {text:"DIA", value: (1000 * 60 * 60 * 24)}, 
      {text:"MÊS", value: (1000 * 60 * 60 * 24 * 30)},
      {text:"ANO", value: (1000 * 60 * 60 * 24 * 30 * 12)}
    ],

    borderRadius: [
        5, 10, 20, 30
    ],  

    padding: [
        10,
        20,
        30,
        50,
    ],

    margin: [
        2,
        5,
        10,
        20,
    ],

    spacing: [
        1,
        2,
        3,
        5
    ],
    graphicColors: [
        { color: "#118e11"},
        { color: "#19c119"},
        { color: "#36e236"},
        { color: "#f4e538"},
        { color: "#f29c24"},
        { color: "#ff5a19"},
        { color: "#d11212"},
        { color: "#125399"},
        { color: "#126ed1"},
        { color: "#429dff"},
    ],

    string_limit: 100,
    phone_limit: 25,
    password_min_length: 8,

    card_default_max_width: 500,
    card_default_width: 500,
    card_default_min_width: 300,

}

export default values