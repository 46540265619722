import React from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';
import Work from '../components/pagescomponents/Work';

function Works(props) {
    return (
        <DashboardFrame title={strings.getString('screen_works_title')}>
            <Work />
        </DashboardFrame>
    );
}

export default Works;