import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Person } from '@mui/icons-material';



export default function MenuList2({endIcon, startIcon, items, onClick, value,   onSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>      
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        size="small"
        endIcon={endIcon} 
        startIcon={startIcon}
        onClick={handleClick}
      >
        {value}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          
        }}

      >
        {items && items.map((item, n) => {
            return (
                <MenuItem onClick={() => onSelect(item)} key={n} >{item.text}</MenuItem>
            
            )
        })}        
      </Menu>
    </div>
  );
}