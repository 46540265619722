import { Button, Card, CardContent, CssBaseline, Divider, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import SaframaxLogo from '../components/basic/SaframaxLogo';
import UserForm from '../components/specific/UserForm';
import strings from '../values/strings/strings';
import values from '../values/values';
import UserTypes from '../models/UserTypes';

import UserCreate from '../usecases/UserCreate'
import MessageShow from '../components/basic/MessageShow';
import Pages from './Pages';

function SignUp(props) {

    const history = useHistory()
    const [errormessage, seterrormessage] = useState(null)
    const [successmessage, setSuccessmessage] = useState(null)

    function enviaUsuario(user) {
        user.tipo = UserTypes.CLI
        user.sendemail = true
        UserCreate(user).then(() => {
            setSuccessmessage(true)
            seterrormessage(null)
        }).catch(e => {
            setSuccessmessage(null)
            seterrormessage(e)
        })
    }

    const reset = () => {
        seterrormessage(null)      
        setSuccessmessage(null)      
    }
    
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <CssBaseline />
            <Stack style={{width: "80%", maxWidth: values.card_default_width}}>
                <SaframaxLogo />                
                <Card style={{padding: values.padding[1], }}>
                    <CardContent>    
                        {!successmessage && !errormessage &&
                            <Stack spacing={4}>
                                <Typography variant="h5" color='primary' >{strings.getString('screen_singup_title')}</Typography>                                  
                                <UserForm usePassword onSalvar={enviaUsuario}/>  
                                {errormessage && errormessage !== "" && <Button color="error">{errormessage}</Button>  }
                            </Stack>
                        }
                        {successmessage && !errormessage &&
                            <MessageShow style={{padding: values.padding[1], width: values.card_default_width}} title={strings.getString('screen_singup_success_title')} message={strings.getString('screen_singup_success_message')}/>
                        } 
                        {!successmessage && errormessage &&
                            <Stack spacing={4}>
                                <MessageShow style={{padding: values.padding[1], width: values.card_default_width}} type="error" title={strings.getString('screen_singup_error_title')} message={errormessage}/>                    
                                <Button onClick={reset} variant="contained">{strings.getString('screen_singup_new_registration')}</Button>
                            </Stack>                            
                        } 
                    </CardContent>
                </Card>        
                
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: values.padding[1]}}>
                    <Typography>{strings.getString('default_ask_account')}</Typography>
                    <Button style={{marginLeft: 10}} onClick={() => {history.push(Pages.routes.Login)}}>{strings.getString('default_suggest_login')}</Button>
                </div>
            </Stack> 
        </div>
    );
}

export default SignUp;