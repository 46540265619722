import React, { useEffect, useState } from "react";
import CacheLoadCredentials from "../../usecases/CacheLoadCredentials";
import UserGetData from "../../usecases/UserGetData";
import RowContainer from "../basic/RowContainer";
import RowContainerItem from "../basic/RowContainerItem";
import UsersList from '../specific/UsersList'
import BasicCard from "../basic/BasicCard";
import Refresh from "../basic/Refresh";

function User() {
    const [usuarioLogado, setUsuarioLogado] = useState([]);
    const [users, setUsers] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        CacheLoadCredentials().then(res => {
            if (res && res.tipo)
                setUsuarioLogado(res.tipo);
        });
        getUsers();
    }, []);

    useEffect(() => {
        const user = listUser.filter(item => item.tipo !== 'CLI');
        setUsers(user);
    }, [listUser]);

    const getUsers = () => {
        setLoading(true);
        UserGetData().then(items => {
            console.log('Lista de usuários: ', items);
            setListUser(items);
        })
            .catch(err => {
                console.log('Erro: ', err);
            })
            .finally(() => {
                console.log('Finally');
                setLoading(false);
            });
    }

    return (
        <div>
            <RowContainer>
                <RowContainerItem style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <BasicCard style={{ flex: 1, textAlign: 'left', fontFamily: 'Times New Roman', color: "#F8C102", fontWeight: 'bold', fontSize: 40 }} title="Grafico de Usuarios geral">
                        {/* Implementação futura */}
                    </BasicCard>
                </RowContainerItem>
                <RowContainerItem style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <BasicCard style={{ flex: 1, textAlign: 'left', fontFamily: 'Times New Roman', color: "#F8C102", fontWeight: 'bold', fontSize: 40 }} title="Grafico de distribuição de Usuarios">
                        {/* Implementação futura */}
                    </BasicCard>
                </RowContainerItem>
                <RowContainerItem style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <BasicCard style={{ flex: 1, textAlign: 'left', fontFamily: 'Times New Roman', color: "#F8C102", fontWeight: 'bold', fontSize: 40 }} title="Grafico de Usuarios por Periodo">
                        {/* Implementação futura */}
                    </BasicCard>
                </RowContainerItem>
            </RowContainer>
            <RowContainer>
                <RowContainerItem minWidth={400}>
                    <BasicCard>
                        <UsersList
                            style={{ flex: 1, whiteSpace: 'nowrap' }}
                            items={users}
                            rowsPerPage={15}
                            usuarioLogado={usuarioLogado}
                            refresh={getUsers}
                            loading={loading}
                        />
                    </BasicCard>
                </RowContainerItem>
            </RowContainer>
        </div>
    );
}

export default User;
