import React from 'react';
import DashboardFrame from '../components/basic/DashboardFrame';
import strings from '../values/strings/strings';

function Settings(props) {
    return (
        <DashboardFrame title={strings.getString('screen_settings_title')}>
        
        </DashboardFrame>
    );
}

export default Settings;