import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { GraphicEqTwoTone } from '@mui/icons-material';

// Importe dos relátórios de Gráficos
import GraphHectareHa from '../components/reports/Graph_hectarehora';
import GraphRelevo from '../components/reports/Graph_relevo';
import GraphSementesha from '../components/reports/Graph_sementesha';
import GraphSementesmetro from '../components/reports/Graph_sementesmetro';
import GraphVelocidade from '../components/reports/Graph_velocidade';
import GraphPressao from '../components/reports/Graph_pressao';
import GraphLitrosha from '../components/reports/Graph_litrosha';
import GraphLitrosMin from '../components/reports/Graph_litrosmin';

const ReportsGraph = ({ data, style }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  console.log('Console de teste para verificar os valores obtidos', data);

  const reportsList = [
    { id: 'graph_sementesha', label: 'Graph Sementesha', type: 'Graph' },
    { id: 'graph_sementesmetro', label: 'Graph Sementes Metro', type: 'Graph' },
    { id: 'graph_velocidade', label: 'Graph Velocidade', type: 'Graph' },
    { id: 'graph_hectarehora', label: 'Graph Hectare Hora', type: 'Graph' },
    { id: 'graph_relevo', label: 'Graph Relevo', type: 'Graph' },
    { id: 'graph_pressao', label: 'Graph Pressão', type: 'Graph' },
    { id: 'graph_litrosha', label: 'Graph Litros/Ha', type: 'Graph ' },
    { id: 'graph_litrosmin', label: 'Graph Litros/Min', type: 'Graph ' },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReportSelect = (report) => {
    setSelectedReport(report);
    handleClose();
  };

  const renderSelectedReport = () => {
    switch (selectedReport?.id) {
      case 'graph_sementesha':
        return <GraphSementesha data={data} />;
      case 'graph_sementesmetro':
        return <GraphSementesmetro data={data} />;
      case 'graph_velocidade':
        return <GraphVelocidade data={data} />;
      case 'graph_hectarehora':
        return <GraphHectareHa data={data} />;
      case 'graph_pressao':
        return <GraphPressao data={data} />;
      case 'graph_litrosha':
        return <GraphLitrosha data={data} />;
      case 'graph_litrosmin':
        return <GraphLitrosMin data={data} />;
      case 'graph_relevo':
        return <GraphRelevo data={data} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ width: '100%', marginTop: '10px' }}>
      <Button
        aria-controls="report-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
      >
        Selecione um Gráfico para Relatório
      </Button>
      <Menu
        id="report-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <h2><GraphicEqTwoTone /> Gráficos</h2>
          {reportsList.filter(r => r.type === 'Graph').map(report => (
            <MenuItem key={report.id} onClick={() => handleReportSelect(report)}>
              {report.label}
            </MenuItem>
          ))}
        </div>
      </Menu>
      <div >
        {renderSelectedReport()}
      </div>
    </div>
  );
};

export default ReportsGraph;
